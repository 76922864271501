import React from "react";
import TypingAnimation from "../TypingAnimation";
import { ConversationStep, Theme } from "../../interfaces";
import { sendNewLead } from "../../util";

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
}

interface IState {
  values: any;
  theme: Theme;
}

class SendNewLead extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: props?.values,
      theme: props?.step?.theme!,
    };
  }

  async componentDidMount() {
    await sendNewLead(this.state.values, this.state.theme);
    this.props.triggerNextStep!({
      trigger: this.props.step!.trigger,
    });
  }

  render() {
    return (
      <>
        {this.props?.step?.id !== "blank_send" && (
          <div className="conversation-message-bubble">
            <TypingAnimation theme={this.state.theme} type="bot" />
            <div className="conversation-message-bubble-text">No Problem.</div>
          </div>
        )}
      </>
    );
  }
}

export default React.memo(SendNewLead);
