import React from "react";
import DatePicker from "react-datepicker";
import { getDay } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import { ConversationStep, Theme } from "../../interfaces";
import { hexToRgb, trackEvent } from "../../util";
import moment from "moment";
import "./AppointmentCalendar.scss";
interface IProps {
  step?: ConversationStep;
  values?: any;
  triggerNextStep?: (data?: {
    value?: string | number;
    step: ConversationStep;
    trigger: string;
  }) => void;
  setDataValues?: (values: any) => void;
}

interface IState {
  theme: Theme;
  date: Date;
  values: any;
  canTalkNow: boolean;
}

class AppointmentCalendar extends React.Component<IProps, IState> {
  datePickerRef = React.createRef<any>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      date: new Date(),
      theme: props?.step?.theme!,
      values: props?.values,
      canTalkNow: false,
    };
    this.confirmDate = this.confirmDate.bind(this);
    this.talkNow = this.talkNow.bind(this);
  }

  isWeekDay(date: Date) {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  }

  isWeekHours(time: Date) {
    const selectedDate = new Date(time);
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    if (
      moment(selectedDate).format("MM/DD/YYYY") ===
      moment(currentDate).format("MM/DD/YYYY")
    ) {
      return (
        selectedDate.getHours() >= 9 &&
        selectedDate.getHours() <= 17 &&
        selectedDate.getMinutes() !== 30 &&
        currentHour < selectedDate.getHours()
      );
    }

    return (
      selectedDate.getHours() >= 9 &&
      selectedDate.getHours() <= 17 &&
      selectedDate.getMinutes() !== 30
    );
  }

  confirmDate(e: any) {
    e.preventDefault();
    const date: Date = this.state.date;

    if (date.getHours() > 0) {
      const formattedDate = `${date.getFullYear()}-${(
        `0` +
        (date.getMonth() + 1)
      ).slice(-2)}-${(`0` + date.getDate()).slice(-2)}T${(
        `0` + date.getHours()
      ).slice(-2)}:00:00Z`;

      this.props?.setDataValues!({
        ...this.state?.values,
        contact_requested: "",
        appointment_date: formattedDate,
      });

      setTimeout(() => {
        this.props?.triggerNextStep!({
          value: formattedDate,
          step: this.props.step!,
          trigger: this.props.step!.trigger,
        });
      }, 500);
    }
  }

  talkNow(e: any) {
    e.preventDefault();
    const date: Date = new Date();

    if (date.getHours() > 0) {
      const formattedDate = `${date.getFullYear()}-${(
        `0` +
        (date.getMonth() + 1)
      ).slice(-2)}-${(`0` + date.getDate()).slice(-2)}T${(
        `0` + date.getHours()
      ).slice(-2)}:00:00Z`;

      this.props?.setDataValues!({
        ...this.state?.values,
        contact_requested: "Talk Now",
        appointment_date: formattedDate,
      });

      setTimeout(() => {
        this.props?.triggerNextStep!({
          step: this.props.step!,
          trigger: this.props.step!.trigger,
        });
      }, 1000);
    }
  }

  applyPrimaryColor(node: HTMLElement, property: string, color: string) {
    if (node) {
      node.style.setProperty(property, color, "important");
    }
  }

  updateStyling() {
    document
      .querySelectorAll<HTMLElement>(
        ".react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected)"
      )
      .forEach((element) => {
        element.style.backgroundColor = "#FFF";
      });

    document
      .querySelectorAll<HTMLElement>(
        ".react-datepicker__time-list-item:not(.react-datepicker__time-list-item--disabled):not(.react-datepicker__time-list-item--selected)"
      )
      .forEach((element) => {
        element.style.backgroundColor = "#FFF";
      });

    const selectedDayNodes = document.querySelectorAll<HTMLElement>(
      ".react-datepicker__day--selected"
    );
    this.applyPrimaryColor(
      selectedDayNodes[selectedDayNodes.length - 1],
      "background-color",
      this.state.theme?.customization?.primaryColor!
    );

    const keyboardSelectedNodes = document.querySelectorAll<HTMLElement>(
      ".react-datepicker__day--keyboard-selected"
    );
    this.applyPrimaryColor(
      keyboardSelectedNodes[keyboardSelectedNodes.length - 1],
      "background-color",
      this.state.theme?.customization?.primaryColor!
    );

    const currentMonthNodes = document.querySelectorAll<HTMLElement>(
      ".react-datepicker__current-month"
    );
    this.applyPrimaryColor(
      currentMonthNodes[currentMonthNodes.length - 1],
      "color",
      this.state.theme?.customization?.primaryColor!
    );

    const timeSelectedNodes = document.querySelectorAll<HTMLElement>(
      ".react-datepicker__time-list-item--selected"
    );
    this.applyPrimaryColor(
      timeSelectedNodes[timeSelectedNodes.length - 1],
      "background-color",
      this.state.theme?.customization?.primaryColor!
    );

    const timeHeaderNodes = document.querySelectorAll<HTMLElement>(
      ".react-datepicker-time__header"
    );
    this.applyPrimaryColor(
      timeHeaderNodes[timeHeaderNodes.length - 1],
      "color",
      this.state.theme?.customization?.primaryColor!
    );
  }

  checkWorkHours() {
    const start = moment(this.state.theme.workHours?.startTime, "HH:mm:ss");
    const end = moment(this.state.theme.workHours?.endTime, "HH:mm:ss");
    const isWorkHours = moment().isBetween(start, end);
    const dayToday = moment().format("dddd").toLowerCase();
    const isWorkDay = this.state.theme.workHours?.days[dayToday];
    if (isWorkHours && isWorkDay) {
      this.setState({
        canTalkNow: true,
      });
      return;
    }
    this.setState({
      canTalkNow: false,
    });
  }

  componentDidMount() {
    setTimeout(() => {
      this.updateStyling();
      this.checkWorkHours();
    }, 500);
    console.log("theme", this.state.theme);
    if (this.state.theme?.googleAnalyticsTrackingId) {
      trackEvent(this.state.theme?.googleAnalyticsTrackingId, {
        category: "MeetParkerChat",
        action: "Book Appointment",
      });
    }
  }

  render() {
    return (
      <div className="conversation-message-bubble react-datepicker-wrapper">
        <div className="react-datepicker-buttons">
          {this.state.theme?.customization?.expertPhoneNumber && (
            //TODO:
            //1. check workHours props, see if the browser time is in between work hours and days
            <button
              type="button"
              className="react-date-picker-talk-button"
              style={{
                backgroundColor:
                  this.state.theme?.customization?.secondaryColor,
                borderColor: this.state.theme?.customization?.secondaryColor,
                boxShadow: `0 1px 12px 0 rgb(${
                  hexToRgb(this.state.theme?.customization?.secondaryColor!)?.r
                } ${
                  hexToRgb(this.state.theme?.customization?.secondaryColor!)?.g
                } ${
                  hexToRgb(this.state.theme?.customization?.secondaryColor!)?.b
                } / 22%)`,
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#FFF";
                e.currentTarget.style.border = `2px solid ${this.state.theme
                  .customization?.secondaryColor!}`;
                e.currentTarget.style.color =
                  this.state.theme.customization?.secondaryColor!;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor =
                  this.state.theme.customization?.secondaryColor!;
                e.currentTarget.style.border = `2px solid ${this.state.theme
                  .customization?.secondaryColor!}`;
                e.currentTarget.style.color = "#FFF";
              }}
              onClick={this.talkNow}
              disabled={!this.state.canTalkNow}
            >
              Let's Talk Now
            </button>
          )}
          <button
            type="button"
            className="react-date-picker-confirm-button"
            style={{
              backgroundColor: this.state.theme?.customization?.primaryColor,
              borderColor: this.state.theme?.customization?.primaryColor,
              boxShadow: `0 1px 12px 0 rgb(${
                hexToRgb(this.state.theme?.customization?.primaryColor!)?.r
              } ${
                hexToRgb(this.state.theme?.customization?.primaryColor!)?.g
              } ${
                hexToRgb(this.state.theme?.customization?.primaryColor!)?.b
              } / 22%)`,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#FFF";
              e.currentTarget.style.border = `2px solid ${this.state.theme
                .customization?.primaryColor!}`;
              e.currentTarget.style.color =
                this.state.theme.customization?.primaryColor!;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                this.state.theme.customization?.primaryColor!;
              e.currentTarget.style.border = `2px solid ${this.state.theme
                .customization?.primaryColor!}`;
              e.currentTarget.style.color = "#FFF";
            }}
            onClick={this.confirmDate}
          >
            Confirm Date
          </button>
        </div>
        <DatePicker
          ref={(ref: any) => (this.datePickerRef = ref)}
          selected={this.state.date}
          open={true}
          minDate={new Date()}
          inline
          filterDate={this.isWeekDay}
          filterTime={this.isWeekHours}
          onMonthChange={() => {
            setTimeout(() => {
              this.updateStyling();
            }, 500);
          }}
          onYearChange={() => {
            setTimeout(() => {
              this.updateStyling();
            }, 500);
          }}
          onChange={(date) => {
            this.setState({ date: date! });
            setTimeout(() => {
              this.updateStyling();
            }, 500);
          }}
          showTimeSelect={
            !this.state.theme?.appointmentCustomization?.disableAppointmentTime
          }
          timeFormat="HH:mm"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </div>
    );
  }
}

export default React.memo(AppointmentCalendar);
