const keywords = [
  "Free Legal Incentive",
  "free legals",
  "FTB or Home Mover",
  "Gambling",
  "Green Deal Scheme",
  "Ground Rent Increase based on Property Value",
  "Ground Rent Linked to RPI",
  "Guarantor",
  "Habitable",
  "Hard / Soft Footprint",
  "Heavy Refurbishment",
  "Help To Buy Equity Loan Scheme (HTB)",
  "Higher Lending Charge (HLC)",
  "Higher Rate Tax Payer",
  "HMO",
  "Holiday Home",
  "Holiday Lets",
  "House - Maximum LTV",
  "ICR Calculation",
  "Income - Bank Staff",
  "Income - Guaranteed Income Payment for Forces",
  "Income - Guardian Allowance",
  "Income - Health Insurance",
  "Income - Housing Benefit",
  "Income - Industrial Injuries Disability Benefit",
  "Income - Investment",
  "Income - Job seekers Allowance",
  "Income - Large Town Allowance",
  "Income - Lodger",
  "Income - Mortgage Subsidy",
  "Income Multiples",
  "Income - Non Regular Overtime",
  "Income - Pension",
  "Income - Personal Independence Payment",
  "Income - Reduced Earnings Allowance",
  "Income - Regular / Guaranteed Overtime",
  "Income - Shift Allowance",
  "Income - Special Duties",
  "Income - Spousal Maintenance",
  "Income - State Pension",
  "Income Support",
  "Income - Travel Allowance",
  "Income - Trust Funds",
  "Individual Voluntary Arrangement - Current",
  "Individual Voluntary Arrangement - Discharged",
  "Interest Only",
  "Interest Only - Convert to Repayment",
  "Interest Only - Down Sizing",
  "Interest Only - Endowment",
  "Interest Only - Foreign Property Sale",
  "Interest Only - Inheritance",
  "Interest Only - Investment Bonds",
  "Interest Only - Investment Property",
  "Interest Only - ISA",
  "Interest Only - Max LTV",
  "Interest Only - Mortgaged Property",
  "Interest Only - Other Assets",
  "Interest Only - Pension",
  "Interest Only - Repayment Vehicles",
  "Interest Only - Sale of Residential Property",
  "Interest Only - Savings",
  "Interest Only - Stocks and Shared",
  "Interest Only - Unit Trusts",
  "Intermediary Product Transfer",
  "Intermediary's Own Application",
  "Investment / Property Clubs",
  "IT Contractors",
  "Japanese Knotweed",
  "Joint Borrower Sole Proprietor",
  "Key Worker Scheme",
  "Land / Acreage",
  "Landfill",
  "Landlocked",
  "Large Loan",
  "Large Loans - Maximum LTV",
  "Latest Acceptable Accounts",
  "Lease Term",
  "Lend Against Land",
  "Lending into Retirement",
  "Less than 12 months remaining on a credit commitment",
  "Lifetime Mortgage",
  "Light Refurbishment",
  "Like for Like Remortgage",
  "Limited Liability Partnership",
  "Live / Work Units",
  "Loan Amount - Minimum",
  "Loan To Income Cap",
  "Local Authority Grant Scheme",
  "LTD Company",
  "LTD Company to a Sole Trader",
  "LTD / SPV ICR",
  "Maintenance - Not Court Ordered",
  "Manually Underwritten",
  "Married Couple - Sole Application",
  "Maternity / Paternity Leave",
  "Maximum Loan",
  "Max LTV with Fees",
  "Minimum Income",
  "Minimum Income - Interest Only",
  "Minimum Income - Part and Part",
  "Minimum Income - Repayment",
  "Minimum Term",
  "Minimum Time UK Resident",
  "Mixed Use",
  "Mortgage in joint names - Deeds in single name",
  "Mortgage Offer Extension",
  "Mortgage Redemption",
  "Mortgage Term - Months",
  "Multi Tenancy Agreement",
  "Multi Unit Block (MUB)",
  "Multi Unit Freehold Block (MUFB)",
  "Mundic Block",
  "Net Profit - LTD Company",
  "New Build - Flat",
  "New Build Flat LTV",
  "New Build House LTV",
  "New Build - Houses",
  "New Build Incentives",
  "New Build - Maximum LTV",
  "New Build Off Plan",
  "New Build Warranties",
  "No Letting Agent",
  "Non EU Foreign Nationals",
  "Non Secured Arrears",
  "Non Simultation and Completion",
  "Non Standard Construction",
  "Non UK Tax Payer",
  "Number of Applicants",
  "Number of Bedrooms",
  "Number of Floors",
  "Number of Kitchens",
  "Number of Tenants",
  "Occupiers - Non Borrowing",
  "Offer - New Build Property",
  "Offer - Validity",
  "Offset",
  "Outbuildings",
  "Overage Clause",
  "Overdraft",
  "Overhead Cables",
  "overpayment",
  "Overpayment Facility",
  "Paid in Cash",
  "Part and Part",
  "Pay Bridging Loan",
  "Payday Loans",
  "Payment Holidays",
  "Payslips",
  "Pension Contributions",
  "Period Properties",
  "Personal Finance - Business Purposes",
  "Piece Work",
  "Planning Permission",
  "Planning Required",
  "Portfolio Landlord",
  "Portfolio Landlord - Maximum Properties",
  "Portfolio Landlord - Personal Income",
  "Portfolio Landlord - Rental Assessment",
  "Porting",
  "Postcode / Region",
  "Power Of Attorney",
  "PRC",
  "Price Work",
  "Private Sate",
  "Probationary Period",
  "Product Split",
  "Professional Landlord",
  "Professionals",
  "Professional Sports",
  "Proof of Address",
  "Proof Of Deposit",
  "Proof of ID",
  "Property Developers",
  "Property Next Door",
  "Property Value",
  "Purchase At Auction",
  "Purchase Below Market Value",
  "Purchased at Auction",
  "Purchase For a Family Member",
  "Purchase from a Ltd Company",
  "Re Bridge",
  "Reduced Income",
  "Region - England",
  "Region - Northern Ireland",
  "Region - Scotland",
  "Region - Scottish Isles",
  "Region - Wales",
  "Regulated BTL",
  "Re Inspection - New Build",
  "Remortgage - Business Purposes",
  "Remortgage - Debt Consolidation",
  "Remortgage - Help To Buy Equity Share (HTB)",
  "Remortgage - Home Improvements",
  "Remortgage - Purchase Buy To Let",
  "Remortgage - Purchase Equity Share",
  "Remortgage - Purchase Land",
  "Remortgage - Right To Buy Pre Emption (RTB)",
  "Remortgage - Shared Equity",
  "Remortgage - Shared Ownership",
  "Remortgage - Staircasing",
  "Rental Income",
  "Rent Arrears",
  "Rent Room / Lodger",
  "Rent to Buy",
  "Repayment - Term",
  "Repossession",
  "Residential Mortgage to Remain",
  "Restrictive Covenants",
  "Retained Interest",
  "Retained Profit",
  "Right To Buy",
  "Right to By (RTB) Purchase",
  "RIO - Acceptable Repayment Vehicle",
  "RIO - Benefit Income",
  "RIO - Capital Raising Remortgage",
  "RIO - Family Assistance",
  "RIO - Future Income",
  "RIO - Investment Income",
  "RIO - Life Cover",
  "RIO - Like for Like Remortgage",
  "RIO - Max Age",
  "RIO - Maximum Term",
  "RIO - Max Loan",
  "RIO - Max LTV",
  "RIO - Minimum Age",
  "RIO - Minimum Equity",
  "RIO - Minimum Income",
  "RIO - Minimum Loan",
  "RIO - Minimum Property Value",
  "RIO - Minimum Term",
  "RIO - Negative Equity",
  "RIO - Pension",
  "RIO - Power Of Attorney",
  "RIO - Products",
  "RIO - Rental Income",
  "Rolled Up Interest",
  "Salary Sacrifice",
  "Sale and Rent Back",
  "Seasonal",
  "Second Charge",
  "Second Charge Consent",
  "Second Home",
  "Second Job",
  "Self Build",
  "Self Build - Advance",
  "Self Build - Arrears",
  "Self Build - Flexible Stage Payment",
  "Self Build - Planning Permission",
  "Self Build - Stage Certification",
  "Self Build - Stage Payments",
  "Service Charges",
  "Serviced Interest",
  "Shared Equity",
  "Shared Ownership",
  "Shared Ownership Housing Association",
  "Shared Ownership Min Share",
  "Shared Ownership - Staircasing",
  "Sharia Compliant Mortgage",
  "Short Term Contract",
  "Short Term Loan",
  "Sitting Tenant",
  "Solar Panels",
  "Sole Trader",
  "Sole Trader to Ltd Company",
  "Split the Title Deeds",
  "SPV Limited Company",
  "Steel Framed",
  "Student Let",
  "Student Loan",
  "Studio Flat - Max LTV",
  "Studio Flat - Minimum Size",
  "Subcontractors",
  "Sub-Sales / Reassignment of Sales Contract",
  "Subsidence",
  "Teachers",
  "Temporary Work",
  "Tied Accommodation",
  "Timber Framed",
  "Time Trading",
  "Top Slicing",
  "Total Aggregate Borrowing",
  "Transfer of Equity",
  "Travel Costs",
  "Trustee",
  "Tyneside Flat",
  "UK Resident",
  "Umbrella",
  "Underpayments",
  "Unencumbered Loan Purpose",
  "Unencumbered Properties",
  "Unencumbered Property",
  "Universal Credit",
  "Unsecured Credit",
  "Unsecured Loan",
  "Utility Bills",
  "Vacant Possession",
  "Valuation Report",
  "Vendor Gifted Deposit",
  "Vested Share Income",
  "Visa",
  "War Widow Pension",
  "When can a discharged bankrupt applicant be considered",
  "Widowed Parent Allowance",
  "Widow Pension",
  "Wimpey No Fines / Laing Easi Form",
  "Working Tax Credits",
  "Years Accounts",
  "Zero Hours",
  "Custom Build - Site Insurance",
  "Custom Build - Valuation",
  "Custom Build - Warranties",
  "Day 1 Remortgage",
  "debt consolidation",
  "Debt Management Plan - Current / Ongoing",
  "Debt Management Plan - Discharged",
  "Debt Relief Order - Current / Ongoing",
  "Debt Relief Order - Discharged",
  "Debt to Income Ratio (DTI)",
  "Declining Profits",
  "Defaults - Satisfied",
  "Defaults - Unsatisfied",
  "Dependant Relative",
  "Dependants",
  "Accountant's Certificate",
  "Deposit - Acceptable",
  "Deposit - Family Gifted",
  "Deposit - Gifted",
  "Deposit - Limited Company",
  "Deposit - Unacceptable",
  "Development - Exit Products",
  "Development - Land Purchase",
  "Development - Outline Planning Permission",
  "Development - Regulated",
  "Diplomatic Immunity",
  "Direct Debit",
  "Director Dividends",
  "Director Loans",
  "Disability Living Allowance",
  "Discounted Market Value Sale",
  "Distressed Sale",
  "Dividend - Retirement",
  "Doubling Ground Rent",
  "Drawdown Facility",
  "DSS Tenants",
  "Electronic ID",
  "Employed - Continuous Time",
  "Employed - Current Employment",
  "Employed - Director",
  "Employed - Future Pay Rise",
  "Employed - Proof",
  "Employed - Reference",
  "Employed - Support Allowance",
  "Employee Benefit Trust",
  "EPC",
  "Equity Release - Buy Back",
  "Equity Release - Buy to Let (BTL)",
  "Equity Release - Cash Reserve",
  "Equity Release - Early Vacancy Guarantee",
  "Equity Release - Escalating Rent Products",
  "Equity Release - Fixed Rent Products",
  "Equity Release - Home Reversion Plan",
  "Equity Release - Inheritance protection Guarantee",
  "Equity Release - Medically Enhanced",
  "Equity Release - No Negative Equity",
  "Equity Release - Partial Payments",
  "Equity Release - Percentage (%) Release",
  "Equity Release - Rent Cease",
  "Equity Release - Rent Reductions",
  "Equity Release - Rent Requirements",
  "Equity Release - Vendor Obligations",
  "Escalating Ground Rent",
  "E-Signature",
  "Ex Council",
  "Existing Borrower - Further Advance",
  "Existing Borrowers",
  "Existing Mortgages / Property",
  "Exit Strategy",
  "Exit Strategy - Sale Of Property",
  "Ex Pat - Maximum LTV",
  "Ex Pats - Acceptable",
  "Ex Pats - Adverse Credit",
  "Ex Pats - Age",
  "Ex Pats - British Colony with LTD Company",
  "Ex Pats - Consumer BTL",
  "Ex Pats - Country",
  "Ex Pats - Ex Council",
  "Ex Pats - First Time Buyer (FTB)",
  "Ex Pats First Time Landlord",
  "Ex Pats HMO",
  "Ex Pats Holiday Let",
  "Ex Pats - Imminent Return",
  "Ex Pats - Income",
  "Ex Pats - Loan Amount",
  "Ex Pats - Maximum Term",
  "Ex Pats - Minimum Term",
  "Ex Pats - Multi National Company",
  "Ex Pats - New Build Flat",
  "Ex Pats - New Build House",
  "Ex Pats - PO Box",
  "Ex Pats Professional Portfolio Landlords",
  "Ex Pats - Property Value",
  "Ex Pats - Remortgage Debt Consolidation",
  "Ex Pats - Retired",
  "Ex Pats - Self Employed",
  "Ex Pats - Time Out of The UK",
  "Expenses",
  "Experienced Landlords",
  "Exposure to Development or Postcode Area",
  "Family Assisted",
  "Family Business",
  "Farm / Small Holding",
  "Fees Added to The Loan",
  "Fees That Must Be Added to The Loan",
  "First Time Buyer - Max LTV",
  "First Time Buyers (FTB)",
  "First Time Developer",
  "First Time Landlord",
  "Flat - Above Commercial Premises",
  "Flat - Freehold",
  "Flat - Maximum LTV",
  "Flat Roof",
  "Flat With Deck Access",
  "floors",
  "Flying Freehold",
  "Forces Help To Buy",
  "Forces Personnel",
  "Foreign Currency",
  "Former House of Multiple Occupation",
  "Foster Care's Allowance",
  "Consent To Let",
  "Constant Attendance Allowance",
  "Consumer BTL",
  "Consumer Buy to Let",
  "Contract Workers",
  "Contract Workers - Fixed Term",
  "Converted Flat",
  "Convictions",
  "Corporate Let",
  "County Court Judgement (CCJ) - Satisfied",
  "County Court Judgement (CCJ) - Unsatisfied",
  "Credit Card Balance",
  "Credit Commitments to be repaid",
  "Credit Reference Agency",
  "Credit Repair product",
  "Credit Score",
  "Criminal Record",
  "Custom Build",
  "Custom Build - Acting Solicitors",
  "Custom Build - Flexible Stage Payments",
  "Custom Build - Guarantor",
  "Custom Build - Interest Only",
  "Custom Build - Land Purchase",
  "Custom Build - Max Borrowing",
  "Custom Build - Max Loan",
  "Custom Build - Offer Extension",
  "Custom Build - Stage Payments",
  "Accountant's Projected Income",
  "Accountant's Qualifications",
  "Accountant's Reference",
  "Additional Borrowing",
  "Adoption Allowance",
  "Affordability Calculator",
  "Affordable Housing Schemes",
  "Age at Application",
  "Age at End of Term",
  "Age Gap at the end of the mortgage term",
  "Agency Workers",
  "Agricultural Ties",
  "Air BnB",
  "Annex",
  "Applicant 2 Not Employed",
  "Architect's Certificate",
  "Arrangement Fee Added to The Loan",
  "Arrears - Secured Loans",
  "Assignable Contracts",
  "Assured Shorthold Tenancy Agreement",
  "Attendance Allowance",
  "Back to Back Purchase",
  "Bank Statements",
  "Barn Conversion",
  "Basement Flat",
  "Bedsits",
  "Benefits Income",
  "Bereavement Allowance",
  "BISF Properties",
  "Bonus - Guaranteed",
  "Bonus - Regular",
  "Borrow Back Facility",
  "Bridging Development - Commercial Property",
  "Bridging Development - Conversion",
  "Bridging Development - Cost to Margin",
  "Bridging Development - Exit Fee Based On Loan",
  "Bridging Development - First Time Developer",
  "Bridging Development - GDV LTV against End Value",
  "Bridging Development - Ground Up",
  "Bridging Development - Joint Venture",
  "Bridging Development - Loan to Cost",
  "Bridging Development - LTV on Acquisition",
  "Bridging Development - Part Completed",
  "Bridging Development - Second Charge",
  "Bridging Development - Term",
  "British National Working Abroad",
  "Broker Fee Added to The Loan",
  "Build Cost - Project Costing",
  "Builder Cash Incentive",
  "Builder Gifted Deposit",
  "Builder Part Exchange",
  "Building Class",
  "Building Regulations",
  "Bursaries",
  "Cabin Crew",
  "Capital & Interest Repayment",
  "Capital Raising",
  "Capital Raising for Business Purposes",
  "Capital Raising for Debt Consolidation",
  "Capital Raising for Land Purchase",
  "Capital Raising To Purchase a Buy To Let",
  "Car Allowance",
  "Carer's Allowance",
  "CCJ",
  "Certified Documents",
  "Change of Usage",
  "Child Benefit",
  "Childcare Costs",
  "Childcare Vouchers",
  "Child Maintenance (Court Ordered)",
  "Child Maintenance - Outgoing",
  "Child Maintenance - Received (Court Ordered)",
  "Child Tax Credits",
  "CIS Contractors",
  "Coal Mining Report",
  "Commercial Property",
  "Commission Only Income",
  "Commission / Profit Related Income",
  "Commonhold",
  "Communications",
  "Company Directors - Overseas",
  "Company / Occupational Pension",
  "Concessionary Purchase",
];

export default keywords;
