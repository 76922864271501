import React from "react";
import qs from "query-string";
import { ConversationStep, Theme } from "../../interfaces";
import { trackEvent } from "../../util";

const { REACT_APP_PROTECTION_URL } = process.env;

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
}

interface IState {
  form: any;
  theme: Theme;
}

class InsuranceQuote extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      form: {
        ...props?.values,
        occupation: "",
        partner_occupation: "",
        occupation_code: props?.values.occupation?.code,
        occupation_description: props?.values.occupation?.description,
        partner_occupation_code: props?.values.partner_occupation?.code,
        partner_occupation_description:
          props?.values.partner_occupation?.description,
      },
      theme: props?.step?.theme!,
    };
  }

  componentDidMount() {
    if (this.state.theme?.googleAnalyticsTrackingId) {
      trackEvent(this.state.theme?.googleAnalyticsTrackingId, {
        category: "MeetParkerChat",
        action: "Insurance Quote Loaded",
      });
    }
    this.props.triggerNextStep!({
      trigger: this.props.step!.trigger,
    });
  }

  render() {
    return (
      <div
        className="conversation-message-bubble"
        style={{
          maxWidth: "100%",
          width: "100%",
          height: 800,
          padding: 0,
        }}
      >
        <iframe
          title="Insurance Quote"
          style={{
            borderWidth: 0,
            borderRadius: 17,
          }}
          src={`${REACT_APP_PROTECTION_URL}/client?apiKey=${
            this.state?.theme?.apiKey
          }&requestType=mortgage_chat&hideAppointmentButton=yes&hideAppBar=yes&hideProgressBar=yes&hideFooter=yes&${qs.stringify(
            this.state.form
          )}`}
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default React.memo(InsuranceQuote);
