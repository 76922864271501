import React, { useEffect } from "react";
import ChatMessage from "./ChatMessage";
import { ChatMessageVideoInterface } from "../interfaces/ChatMessageVideo";

const ChatMessageVideo: React.FC<ChatMessageVideoInterface> = ({
  hideAvatar,
  triggerNextStep,
  step,
  currentStep,
  scrollToBottom,
  disableScrollToBottom,
  videoLink,
  disableTypingAnimation = false,
}) => {
  const { input, end, delay } = step!;

  useEffect(() => {
    if (!disableScrollToBottom && step === currentStep) {
      scrollToBottom!();
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (!input && !end) {
        triggerNextStep!({ trigger: step?.trigger! });
      }
    }, delay || 1000);
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  return (
    <ChatMessage
      step={step}
      hideAvatar={hideAvatar}
      scrollToBottom={scrollToBottom}
      disableTypingAnimation={disableTypingAnimation}
      bubbleStyle={{
        padding: 0,
      }}
      render={() => (
        <div className="conversation-message-bubble-video">
          <video id={step?.id} width="100%" height="100%" controls playsInline>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    />
  );
};

export default React.memo(ChatMessageVideo);
