import { useCallback, useEffect, useState } from "react";
// import ReactFlagsSelect from "react-flags-select";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { GrChat } from "react-icons/gr";

import Conversation from "./containers/Conversation";

import "./assets/scss/styles.scss";

// import SimplifiedSteps from "./config/simplified";
import FullSteps from "./config/full";
import ProtectionSteps from "./config/protection";
import PropertySteps from "./config/property";
import CriteriaSteps from "./config/criteria";
import WealthSteps from "./config/wealth";

import { ConversationStep, Theme } from "./interfaces";
import RightmoveAutoResponder from "./config/rightmove";
// import { initializeGoogleAnalytics } from "./util";
// import languages from "./assets/data/languages";

const { REACT_APP_SHOP_URL } = process.env;

export interface MainAppProps {
  apiKey: string;
  config?: MainAppConfig;
}

export interface MainAppConfig {
  hideAppBar?: boolean;
}

const App: React.FC<MainAppProps> = ({ apiKey, config }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [languageSelected, setLanguageSelected] = useState("GB");
  const [theme, setTheme] = useState<Theme>({
    apiKey: "",
    id: "ruby",
    themeId: "red",
    version: "",
    email: "",
    logo: "",
    customization: {},
    flowCustomization: "",
    appointmentCustomization: {},
    customFlowData: {},
    stripe: {},
    links: {},
    files: [],
    googleAnalyticsTrackingId: "",
  });
  const [stepList, setStepList] = useState<ConversationStep[]>([]);

  const switchTheme = (theme: Theme) => {
    console.log({ theme });
    setTheme(theme);
  };

  const switchFlow = (
    flow: string,
    themeData: Theme = theme
  ): ConversationStep[] => {
    switchTheme(themeData);

    switch (flow) {
      case "protection":
        setStepList(ProtectionSteps(themeData));
        return ProtectionSteps(themeData);
      case "property":
        setStepList(PropertySteps(themeData));
        return PropertySteps(themeData);
      case "criteria":
        setStepList(CriteriaSteps(themeData));
        return CriteriaSteps(themeData);
      case "wealth":
        setStepList(WealthSteps(themeData));
        return WealthSteps(themeData);
      case "rightmove":
        setStepList(RightmoveAutoResponder(themeData));
        return RightmoveAutoResponder(themeData);
      default:
        setStepList(FullSteps(themeData));
        return FullSteps(themeData);
    }
  };

  const getProductData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${REACT_APP_SHOP_URL}/api/v1/shop/client/get?apiKey=${apiKey}`
      );

      const {
        email,
        logo,
        name,
        attributes: { theme: themeId, version },
      } = data;

      let theme = "",
        primaryColor = "",
        secondaryColor = "";

      let themeProps: Theme = {
        apiKey,
        themeId,
        email,
        logo,
        name,
        version,
        customization: {
          botName: data?.customization?.botName || "Parker",
          botAvatar: data?.customization?.botAvatar || logo,
          triggers: data?.customization?.triggers || [],
          expertPhoneNumber: data?.customization?.expertPhoneNumber || "",
          switchToMortgageText: data?.customization?.switchToMortgageText || "",
          criteria: data?.customization?.criteria || {},
          templates: data?.customization?.templates || {},
        },
        files: [],
      };

      switch (themeId) {
        case "blue":
          theme = "royal";
          primaryColor = "#002554";
          secondaryColor = "#fdf8f3";
          break;
        case "brown":
          theme = "hazelnut";
          primaryColor = "#b1a296";
          secondaryColor = "#dff1ff";
          break;
        case "red":
          theme = "ruby";
          primaryColor = "#ff385c";
          secondaryColor = "#464646";
          break;
        case "green":
          theme = "jade";
          primaryColor = "#41b3a3";
          secondaryColor = "#fff0e6";
          break;
        default:
          throw new Error("Invalid product");
      }
      themeProps = {
        ...themeProps,
        id: theme,
        customization: {
          ...themeProps.customization,
          primaryColor: data?.customization?.primaryColor || primaryColor,
          secondaryColor: data?.customization?.secondaryColor || secondaryColor,
        },
      };
      setTheme(themeProps);
      switchFlow(version, themeProps);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, [apiKey]);

  const renderPage = () => (
    <section
      className={`page product-${theme?.id} ${isOpen ? "open" : "close"}`}
    >
      {config?.hideAppBar ? null : (
        <div className="page-toolbar">
          <img
            src={theme?.logo}
            alt={`product-${theme?.id}-logo`}
            height={30}
          />
          <div
            className="close-icon"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <span>Close</span>
          </div>
        </div>
      )}
      <div
        className={`page-content conversation-body ${config?.hideAppBar ? "max-height" : ""
          }`}
      >
        <Conversation
          theme={theme}
          config={config}
          switchTheme={switchTheme}
          switchFlow={switchFlow}
          steps={stepList}
        />
      </div>
      <div className="page-background">
        <div className="page-cloud-1" />
        <div className="page-airplane" />
        <div className="page-cloud-2" />
      </div>
    </section>
  );

  const renderLauncher = () => {
    return (
      <div
        className={`chat-widget-icon ${isOpen ? "open" : "close"}`}
        style={{
          background: theme?.customization?.primaryColor,
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="open-icon">
          <IoMdClose />
        </div>

        <div className="close-icon">
          <GrChat />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getProductData();
  }, [getProductData]);

  return isLoading ? null : (
    <>
      {renderPage()}
      {renderLauncher()}
    </>
  );
};

export default App;
