import React from "react";
import axios from "axios";
import * as _ from "lodash";
import dompurify from "dompurify";
import ReactToPrint from "react-to-print";

import CriteriaTable from "./CriteriaTable";

import keywords from "../../assets/data/keywords";

import CallIcon from "../../assets/images/call.png";
import BrowseIcon from "../../assets/images/browse.png";
import ChatNowIcon from "../../assets/images/chat_now.png";
import ReferIcon from "../../assets/images/refer.png";
import FailIcon from "../../assets/images/fail.png";
import AcceptIcon from "../../assets/images/accept.png";

import "./CriteriaSnapshot.scss";
import { ConversationStep, CriteriaTemplate, Theme } from "../../interfaces";
import TypingAnimation from "../TypingAnimation";
import ChatMessageButton from "../ChatMessageButton";
import ChatMessageVideo from "../ChatMessageVideo";

import widgets from "../../assets/data/widgets";
import ChatMessageText from "../ChatMessageText";

const { REACT_APP_COMMS_URL, REACT_APP_WIDGET_URL } = process.env;

interface IState {
  values: any;
  theme: Theme;
  criterias: any;
  keyword: string[];
  isLoading: boolean;
  apiKey: string;
  acceptCount: number;
  referCount: number;
  selectedCriteria: CriteriaTemplate[];
  showCriteriaVideo: boolean;
  showCriteriaTable: boolean;
}

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
  setDataValues?: (values: any) => void;
  currentStep?: ConversationStep;
  scrollToBottom?: () => void;
}
class CriteriaSnapshotCall extends React.PureComponent<IProps, IState> {
  componentRef = React.createRef<any>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      values: props?.values,
      theme: props?.step?.theme!,
      criterias: [],
      keyword: [],
      isLoading: true,
      apiKey: "",
      acceptCount: 0,
      referCount: 0,
      selectedCriteria: [],
      showCriteriaVideo: false,
      showCriteriaTable: false,
    };
  }

  getLenderImage({ SmallImageSecureUrl }: any) {
    return <img src={SmallImageSecureUrl} alt="lender" />;
  }

  getLabel(criteria: any) {
    const status = criteria.Acceptable;
    const text = criteria.Description || status;

    if (status === "Refer")
      return (
        <div className="criteria-label">
          <img src={ReferIcon} alt="fail" className="criteria-marker" />
          <span
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(text),
            }}
          />
        </div>
      );
    if (status === "Yes" || status === "YesWithConditions")
      return (
        <div className="criteria-label">
          <img src={AcceptIcon} alt="fail" className="criteria-marker" />
          <span
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(
                status === "YesWithConditions" ? `${status}, ${text}` : text
              ),
            }}
          />
        </div>
      );
    return (
      <div className="criteria-label">
        <img src={FailIcon} alt="fail" className="criteria-marker" />
        <span
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(text),
          }}
        />
      </div>
    );
  }

  getContact({ PhoneNumber, WebSite, LenderCode }: any) {
    return (
      <div className="get-in-touch">
        <a href={`tel:${PhoneNumber}`} target="_blank" rel="noreferrer">
          <img src={CallIcon} alt="call" />
        </a>
        <a href={WebSite} target="_blank" rel="noreferrer">
          <img src={BrowseIcon} alt="browse" />
        </a>
        {(widgets as any)[LenderCode] && (
          <img
            src={ChatNowIcon}
            alt="chat-now"
            style={{ cursor: "pointer" }}
            onClick={() => {
              // this.setState({
              //   apiKey: (widgets as any)[LenderCode],
              // });
              const apiKey = (widgets as any)[LenderCode];

              if (apiKey) {
                window.open(`${REACT_APP_WIDGET_URL}/chat?apiKey=${apiKey}`);
              }
            }}
          />
        )}
      </div>
    );
  }

  renderPage() {
    const columns = [
      {
        id: "lender",
        label: "LENDER",
        minWidth: 150,
      },
      {
        id: "status",
        label: "STATUS",
        minWidth: 700,
        render: (criteria: any) =>
          React.Children.toArray((criteria || []).map(this.getLabel)),
      },
      {
        id: "contact",
        label: "GET IN TOUCH",
        minWidth: 300,
      },
    ];

    return (
      <div className="criteria-result-container">
        <CriteriaTable
          ref={(ref: any) => (this.componentRef = ref)}
          rows={this.state.criterias || {}}
          columns={columns}
          theme={this.props.step?.theme!}
        />
      </div>
    );
  }

  async getLenders() {
    const { customization } = this.props?.step?.theme!;

    const {
      data: { items: lenderList },
    } = await axios.post(`${REACT_APP_COMMS_URL}/api/27tec/lender`, {
      searchText: customization?.criteria?.isVendorCriteriaOnly
        ? customization?.criteria?.lenderName!.toLowerCase()
        : "",
    });

    return lenderList;
  }

  async getCriterias(lenders: any[], keyword: string = "") {
    const {
      data: { items: criterias },
    } = await axios.post(`${REACT_APP_COMMS_URL}/api/27tec/criteria`, {
      lenderList: lenders,
      mortgageType: this.state.values?.mortgage_type,
      searchText: keyword.toLowerCase(),
    });

    return criterias?.Accept;
  }

  filterCriteriaBasedOnAcceptance(criteriaList: any, acceptance: string) {
    return criteriaList.filter(
      (criteria: any) => criteria.Acceptable === acceptance
    );
  }

  formatCriteriaObject(criteriaList: any, criteriaObject: any = {}) {
    if (!criteriaList.length) return criteriaObject;

    if (criteriaList.length === 1)
      return {
        [criteriaList[0].LenderCode]: {
          lender: this.getLenderImage(criteriaList[0]),
          status: [criteriaList[0]],
          contact: this.getContact(criteriaList[0]),
        },
      };

    return (
      criteriaList.reduce((acc: any, curr: any) => {
        const { LenderCode } = curr;
        return {
          ...acc,
          [LenderCode]: {
            lender: this.getLenderImage(curr),
            status: [...(acc[LenderCode]?.status || []), curr],
            contact: this.getContact(curr),
          },
        };
      }, criteriaObject) || {}
    );
  }

  getCriteriaCount(criteriaList: any, status: string) {
    return this.filterCriteriaBasedOnAcceptance(criteriaList, status).length;
  }

  sortCriterias(criteriaList: any) {
    const acceptableCriteria = this.filterCriteriaBasedOnAcceptance(
      criteriaList,
      "Yes"
    ).concat(
      this.filterCriteriaBasedOnAcceptance(criteriaList, "YesWithConditions")
    );
    const referrableCriteria = this.filterCriteriaBasedOnAcceptance(
      criteriaList,
      "Refer"
    );

    const declineCriteria = this.filterCriteriaBasedOnAcceptance(
      criteriaList,
      "No"
    );

    return _.uniqBy(
      [...acceptableCriteria, ...referrableCriteria, ...declineCriteria],
      "LenderCode"
    );
  }

  async componentDidMount() {
    const criteriaInput = [...(this.state.values?.criteria_input || [])].filter(
      Boolean
    );
    const criteriaSearch = (
      this.state.theme?.customization?.templates?.criterias || []
    )
      .filter((criteria) => {
        const isInCriteria = criteriaInput.find((input) =>
          criteria.question!.includes(input)
        );

        if (isInCriteria) return criteria;
        return null;
      })
      .filter(Boolean);

    if (
      criteriaSearch.length &&
      this.state.theme.customization?.criteria?.isVendorCriteriaOnly &&
      criteriaInput.length === 1
    ) {
      this.setState({
        showCriteriaVideo: true,
        showCriteriaTable: false,
        selectedCriteria: [criteriaSearch[0]],
        isLoading: false,
      });
      this.props.setDataValues!({
        criteria_list: [criteriaSearch[0]],
      });
    } else {
      let criteriaList = {},
        acceptCount = 0,
        referCount = 0;
      const lenders = await this.getLenders();

      for await (const input of criteriaInput) {
        const keyword = keywords
          .find((keyword) =>
            input.toLowerCase().includes(keyword.toLowerCase())
          )
          ?.toLowerCase();

        const criterias = this.sortCriterias(
          await this.getCriterias(lenders, keyword! || input)
        );
        const uniqCriterias = _.uniqWith(criterias, _.isEqual);

        acceptCount +=
          this.filterCriteriaBasedOnAcceptance(uniqCriterias, "Yes").length +
          this.filterCriteriaBasedOnAcceptance(
            uniqCriterias,
            "YesWithConditions"
          ).length;
        referCount += this.filterCriteriaBasedOnAcceptance(
          uniqCriterias,
          "Refer"
        ).length;

        criteriaList = this.formatCriteriaObject(uniqCriterias, criteriaList);
      }

      this.setState({
        criterias: criteriaList,
        isLoading: false,
        keyword: criteriaInput,
        acceptCount,
        referCount,
        showCriteriaTable: true,
        showCriteriaVideo: criteriaSearch.length > 0,
        selectedCriteria: criteriaSearch.length ? criteriaSearch : [],
      });
      this.props.setDataValues!({
        criteria_list: criteriaList,
      });
    }
    this.props.triggerNextStep!({
      trigger: this.props.step!.trigger,
    });
  }

  renderCriteriaTable() {
    return (
      <div className="criteria-snapshot-container">
        <div className="criteria-action-container">
          <p className="criteria-snapshot-keyword">
            You searched for <strong>"{this.state.keyword.join(", ")}"</strong>
          </p>
          <ReactToPrint
            documentTitle="Your Criterias"
            pageStyle={`
                @media print {
                  html, body {
                    padding: 20px;
                    height: initial !important;
                    overflow: initial !important;
                    -webkit-print-color-adjust: exact;
                  }
                }

                @media print {
                  .criteria-result-table thead {
                    position: relative !important;
                  }

                  .criteria-result-table tr {
                    display: table-row !important;
                  }

                  .criteria-result-table td {
                    display: table-cell !important;
                  }
                }
                `}
            trigger={() => {
              return <></>;
              // return (
              //   <button
              //     type="button"
              //     className="print-to-pdf"
              //     style={{
              //       backgroundColor:
              //         this.props.step?.theme?.customization?.primaryColor,
              //       borderColor:
              //         this.props.step?.theme?.customization?.primaryColor,
              //     }}
              //     onMouseEnter={(e) => {
              //       e.currentTarget.style.backgroundColor = "#FFF";
              //       e.currentTarget.style.borderColor =
              //         this.props.step?.theme?.customization?.primaryColor!;
              //       e.currentTarget.style.color =
              //         this.props.step?.theme?.customization?.primaryColor!;
              //     }}
              //     onMouseLeave={(e) => {
              //       e.currentTarget.style.backgroundColor =
              //         this.props.step?.theme?.customization?.primaryColor!;
              //       e.currentTarget.style.borderColor =
              //         this.props.step?.theme?.customization?.primaryColor!;
              //       e.currentTarget.style.color = "#FFF";
              //     }}
              //   >
              //     Export to PDF
              //   </button>
              // );
            }}
            content={() => this.componentRef as any}
          />
        </div>
        {this.renderPage()}
      </div>
    );
  }

  renderCriteriaVideo() {
    return this.state.selectedCriteria.map((selectedCriteria) => (
      <div key={selectedCriteria.question}>
        <ChatMessageVideo
          hideAvatar={true}
          disableTypingAnimation={true}
          triggerNextStep={this.props.triggerNextStep}
          step={this.props.step}
          currentStep={this.props.currentStep}
          scrollToBottom={this.props.scrollToBottom}
          disableScrollToBottom={true}
          videoLink={selectedCriteria.video!}
        />
        {selectedCriteria.answer && (
          <ChatMessageText
            hideAvatar={false}
            disableTypingAnimation={true}
            triggerNextStep={this.props.triggerNextStep}
            step={{
              ...this.props.step,
              message: selectedCriteria.answer,
            }}
            currentStep={this.props.currentStep}
            scrollToBottom={this.props.scrollToBottom}
            disableScrollToBottom={true}
          />
        )}
      </div>
    ));
  }

  render() {
    const { customization } = this.props?.step?.theme!;
    let resultMessage = "Here's what I found";

    if (!customization?.criteria?.isVendorCriteriaOnly) {
      resultMessage = `I've found ${this.state.acceptCount} who could accept this. I've found ${this.state.referCount} lenders who have marked this as refer.`;
    }

    return (
      <div
        // className="conversation-message"
        style={{ flexDirection: "column", width: "100%" }}
      >
        {this.state.isLoading && (
          <div
            className="conversation-message-bubble"
            style={{ maxWidth: "fit-content" }}
          >
            <TypingAnimation
              infinite={true}
              theme={this.props.step?.theme!}
              type={this.props.step?.type!}
            />
          </div>
        )}

        {!this.state.isLoading &&
          this.state.showCriteriaVideo &&
          this.renderCriteriaVideo()}

        {!this.state.isLoading && (
          <ChatMessageButton
            disableScrollToBottom={true}
            disableTypingAnimation={true}
            scrollToBottom={this.props.scrollToBottom}
            hideAvatar={false}
            triggerNextStep={async (data) => {
              if (data?.value === "New Search") {
                this.props.setDataValues!({
                  criteria_input: [],
                });
                await new Promise((resolve) => setTimeout(resolve, 500));
              }

              this.props.triggerNextStep!(data);
            }}
            values={this.state.values}
            step={{
              id: "anything_else",
              type: "bot",
              options: [
                {
                  label: "Let's narrow this down",
                  value: "Let's narrow this down",
                  trigger: "criteria_input",
                },
                {
                  label: "New Search",
                  value: "New Search",
                  trigger: "mortgage_type",
                },
              ],
              message: resultMessage,
              theme: this.state.theme,
            }}
            setDataValues={this.props.setDataValues}
            currentStep={this.props.currentStep}
          />
        )}
        {!this.state.isLoading &&
          this.state.showCriteriaTable &&
          this.renderCriteriaTable()}
      </div>
    );
  }
}

export default React.memo(CriteriaSnapshotCall);
