import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const renderApp = (apiKey: string) => {
  return (
    <React.StrictMode>
      <App apiKey={apiKey} />
    </React.StrictMode>
  );
};

export const render = (apiKey: string, element: HTMLElement) => {
  ReactDOM.render(renderApp(apiKey), element);
};

// render(
//   "d9a8c177-64e8-4d8a-92e9-f2078952eaf2",
//   document.getElementById("meetparker-chat-widget")!
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
