import { ConversationStep, Theme } from "../interfaces";

const SwitchSteps = (theme: Theme) => {
  return [
    {
      id: "switch_to_mortgage",
      type: "bot",
      message: theme?.customization?.switchToMortgageText
        ? theme?.customization?.switchToMortgageText
        : `No problem. I'm just connecting you through ${theme?.name}`,
      trigger: "mortgage_type",
    },
  ] as ConversationStep[];
};

export default SwitchSteps;
