import React from "react";
import cn from "classnames";
import TypingAnimation from "./TypingAnimation";
import { hexToRgb } from "../util";
import { ChatInterface } from "../interfaces";

const ChatMessage: React.FC<ChatInterface> = ({
  step,
  render = () => null,
  hideAvatar,
  bubbleStyle = {},
  disableTypingAnimation,
}) => (
  <div className={`conversation-message conversation-message-${step?.type}`}>
    <div
      className={cn("conversation-message-avatar", {
        visible: !hideAvatar,
      })}
      style={{
        backgroundImage: `url("${step?.theme?.customization?.botAvatar}")`,
      }}
    />
    <div
      className="conversation-message-bubble"
      style={{
        ...bubbleStyle,
        ...(step?.type === "user" && {
          backgroundColor: step?.theme?.customization?.primaryColor,
          boxShadow: `0 1px 28px 0 rgb(${
            hexToRgb(step?.theme?.customization?.primaryColor!)?.r
          } ${hexToRgb(step?.theme?.customization?.primaryColor!)?.g} ${
            hexToRgb(step?.theme?.customization?.primaryColor!)?.b
          } / 40%)`,
        }),
      }}
    >
      {!disableTypingAnimation && (
        <TypingAnimation theme={step?.theme!} type={step?.type!} />
      )}
      {render()}
    </div>
  </div>
);

export default React.memo(ChatMessage);
