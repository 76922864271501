import { TypingAnimationInterface } from "../interfaces";

const TypingAnimation: React.FC<TypingAnimationInterface> = ({
  type,
  theme,
  infinite = false,
}) => (
  <div className={`typing-animation-wrapper ${infinite ? "infinite" : ""}`}>
    <div className="typing-spinner">
      <div
        className="ball"
        style={{
          ...(type === "bot" && {
            backgroundColor: theme?.customization?.primaryColor,
          }),
        }}
      />
      <div
        className="ball"
        style={{
          ...(type === "bot" && {
            backgroundColor: theme?.customization?.primaryColor,
          }),
        }}
      />
      <div
        className="ball"
        style={{
          ...(type === "bot" && {
            backgroundColor: theme?.customization?.primaryColor,
          }),
        }}
      />
    </div>
  </div>
);

export default TypingAnimation;
