import React from "react";
import { Theme } from "../../interfaces";

interface IState {}

interface IProps {
  columns: any[];
  rows: any;
  ref: any;
  theme: Theme;
}
class CriteriaTable extends React.Component<IProps, IState> {
  render() {
    if (!Object.keys(this.props.rows).length) {
      return "No criterias to display.";
    }

    return (
      <table className="criteria-result-table">
        <thead>
          <tr>
            {this.props.columns.map((column) => (
              <td
                className="criteria-result-table-header"
                key={column.id}
                style={{
                  color: this.props.theme.customization?.primaryColor,
                }}
              >
                {column.label}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(this.props.rows).map((key) => (
            <tr key={key}>
              {this.props.columns.map((column) => (
                <td
                  data-label={column.id}
                  key={`${key}-${column.id}`}
                  style={{
                    borderBottom: "1px solid #E0E0E0",
                    padding: "10px",
                  }}
                >
                  {column.render
                    ? column.render(this.props.rows[key][column.id])
                    : this.props.rows[key][column.id]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default CriteriaTable;
