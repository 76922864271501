import React, { useEffect } from "react";
import DomPurify from "dompurify";
import ChatMessageInput from "./ChatMessageInput";
import { ChatMessageTextInterface } from "../interfaces";
import ChatMessage from "./ChatMessage";

const ChatMessageText: React.FC<ChatMessageTextInterface> = ({
  hideAvatar,
  values,
  triggerNextStep,
  handleInputSubmit,
  step,
  setDataValues,
  currentStep,
  scrollToBottom,
  disableScrollToBottom,
  disableTypingAnimation = false,
}) => {
  const { input, end, delay, message } = step!;

  useEffect(() => {
    if (!disableScrollToBottom && step === currentStep) {
      scrollToBottom!();
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (!input && !end) {
        triggerNextStep!({ trigger: step?.trigger! });
      }
    }, delay || 1000);
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  return (
    <React.Fragment>
      <ChatMessage
        step={step}
        disableTypingAnimation={disableTypingAnimation}
        hideAvatar={hideAvatar}
        scrollToBottom={scrollToBottom}
        render={() => (
          <div
            className="conversation-message-bubble-text"
            dangerouslySetInnerHTML={{ __html: DomPurify.sanitize(message!) }}
          />
        )}
      />

      {input && (
        <ChatMessageInput
          triggerNextStep={triggerNextStep}
          step={step}
          currentStep={currentStep}
          disableTypingAnimation={disableTypingAnimation}
          values={values}
          handleInputSubmit={handleInputSubmit}
          setDataValues={setDataValues}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(ChatMessageText);
