import { ConversationStep, Theme } from "../../interfaces";
import { getApplicantSteps } from "../ApplicantSteps";
import AppointmentSteps from "../AppointmentSteps";

const wealth = (theme: Theme) => {
  return [
    {
      id: "wealth_greetings",
      type: "bot",
      message: `Hey - I'm ${theme?.customization?.botName}, the Wealth Management Assistant.`,
      trigger: "wealth_greet_help",
    },
    {
      id: "wealth_greet_help",
      type: "bot",
      message: "How can I help?",
      options: [
        {
          label: "Pension Advice",
          value: "Pension Advice",
          trigger: "no_prob",
        },
        {
          label: "ISA Advice",
          value: "ISA Advice",
          trigger: "no_prob",
        },
        {
          label: "Savings Advice",
          value: "Savings Advice",
          trigger: "no_prob",
        },
        {
          label: "Current Account Advice",
          value: "Current Account Advice",
          trigger: "no_prob",
        },
        {
          label: "SIPP Advice",
          value: "SIPP Advice",
          trigger: "no_prob",
        },
      ],
    },
    {
      id: "no_prob",
      type: "bot",
      message: "No problem",
      trigger: "applicant1_title",
    },
    ...getApplicantSteps(1),
    {
      id: "policy_inquiry",
      type: "bot",
      message: "Do you have any policies in place at the moment?",
      input: {
        type: "text",
      },
      trigger: "schedule_appointment",
    },
    {
      id: "schedule_appointment",
      type: "bot",
      message: "When would you like to speak to the team",
      trigger: "select_date",
    },
    ...AppointmentSteps(theme),
    {
      id: "flow_end",
      message:
        "Great, you're all booked in. I'll send you a confirmation email shortly.",
      playAnimation: true,
      type: "bot",
      trigger: "end_greeting",
    },
    {
      id: "end_greeting",
      message: "Have a great day.",
      end: true,
      type: "bot",
    },
  ] as ConversationStep[];
};

export default wealth;
