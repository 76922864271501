import React from "react";
import "./StripePayment.css";
import { ConversationStep, Theme } from "../../interfaces";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import axios from "axios";
const { REACT_APP_SHOP_URL } = process.env;

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
  stripe?: any;
  elements?: any;
  props?: IProps;
  setDataValues: any;
}

interface IState {
  values: any;
  theme: Theme;
  paymentIntent: any;
  productSelected: any;
  loading: boolean;
  paymentError: {
    code: string | number;
    message: string | number;
    decline_code: string | number;
  };
  hideBackToSelection: boolean;
}

class CheckoutForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: props?.props?.values,
      theme: props?.props?.step?.theme!,
      paymentIntent: { status: null },
      productSelected: null,
      loading: false,
      paymentError: {
        message: "",
        code: "",
        decline_code: "",
      },
      hideBackToSelection: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.props.props?.props?.setDataValues(() => {
      return { ...this.props.props?.props?.values };
    });
    this.props.props?.props?.triggerNextStep!({
      trigger: this.props.props?.props?.step!.trigger,
    });

    const productSelected = this.props.props?.props?.values.theme.products.find(
      (product: any) =>
        product.id === this.props.props?.props?.values.product_item
    );

    this.setState({ productSelected });
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    if (elements == null) {
      return;
    }

    this.setState({ loading: true });
    const response: {
      data: { client_secret: string; id: string; amount: number };
    } = await axios.post(
      `${REACT_APP_SHOP_URL}/api/v1/shop/client/payment/secretnosess`,
      {
        //@ts-ignore
        apiKey: this.props.props?.props?.values.theme.apiKey,
        product_id: this.state.productSelected.id,
        paymentMethod: "card",
        cardholder: event.target[0].value || "",
      }
    );

    const { paymentIntent, error } = await stripe.confirmCardPayment(
      response.data.client_secret,
      {
        payment_method: {
          type: "card",
          card: elements.getElement(CardElement),
        },
      }
    );
    if (error) {
      console.log({ error });
      this.setState({
        paymentError: { ...error },
        loading: false,
        hideBackToSelection: true,
      });
    } else {
      this.setState(
        {
          paymentIntent: paymentIntent,
          paymentError: {
            message: 0,
            code: 0,
            decline_code: 0,
          },
          loading: false,
          hideBackToSelection: true,
        },
        () => {
          if (
            this.state.paymentIntent &&
            this.state.paymentIntent.status === "succeeded"
          ) {
            this.props.props?.props?.triggerNextStep!({
              trigger: "select_date",
            });
          }
        }
      );
    }
  };

  ShowPriceToPay = () => {
    return (
      <div className={"productDetails"}>
        <h4>Description</h4>
        <p>{this.state.productSelected.description}</p>
        <h4>Price</h4>
        <p>
          {this.state.productSelected.symbol}
          {this.state.productSelected.amount}{" "}
          <span>{this.state.productSelected.currency.toUpperCase()}</span>
        </p>
        <h4>Payment Terms</h4>
        <p>Please note this is not refundable once you book</p>
      </div>
    );
  };

  ShowPaymentResult = () => {
    return (
      <>
        {this.state.paymentError.code ? (
          <p className={"paymentError"}>
            {this.state.paymentError.message}, Please try again.
          </p>
        ) : (
          <p className={"paymentSuccessful"}>Payment successful</p>
        )}
      </>
    );
  };

  backToSelection = () => {
    this.props.props?.props?.triggerNextStep!({ trigger: "product_item" });
  };

  render() {
    const { stripe } = this.props;
    return (
      <div className={"paymentWrapper conversation-message-bubble"}>
        <form className={"stripeForm"} onSubmit={this.handleSubmit}>
          {this.state.productSelected && <this.ShowPriceToPay />}
          <h4>Name on card</h4>
          <input type="text" id={"cardholder"} name={"cardholder"} />
          <CardElement />
          <div className="section">
            <div className="sub1">
              <p className={"poweredbystrip"}>
                Your payments are securely processed by Stripe
              </p>
              <img
                className={"poweredbystrip"}
                src="https://d365pq86x330zn.cloudfront.net/71647687-1895-4866-6116-15s1758s6675.png"
                alt="powered by stripe"
              />
            </div>
            {!this.state.loading ? (
              <div className="sub2">
                <button
                  type="submit"
                  disabled={
                    !stripe ||
                    (this.state.paymentIntent &&
                      this.state.paymentIntent.status === "succeeded")
                  }
                >
                  {this.state.paymentIntent &&
                  this.state.paymentIntent.status === "succeeded"
                    ? "Paid"
                    : "Pay"}{" "}
                  {this.state.productSelected && (
                    <span>
                      {this.state.productSelected.symbol}
                      {this.state.productSelected.amount}
                    </span>
                  )}
                </button>
              </div>
            ) : null}
            {this.state.loading && <div className={"loader"}></div>}
          </div>
        </form>
        <div className="backtoselection">
          {this.state.hideBackToSelection && <this.ShowPaymentResult />}
          {!this.state.hideBackToSelection && (
            <button className={"link"} onClick={this.backToSelection}>
              Back to selection
            </button>
          )}
        </div>
      </div>
    );
  }
}

const InjectedCheckoutForm = (props: any) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      //@ts-ignore
      <CheckoutForm stripe={stripe} elements={elements} props={props} />
    )}
  </ElementsConsumer>
);

const StripePayment = (props: any) => {
  const stripePromise = loadStripe(props.values.theme.stripe.publicKey, {
    stripeAccount: props.values.theme.stripe.stripeAccount,
  });

  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm props={props} />
    </Elements>
  );
};
export default React.memo(StripePayment);
