import React from "react";
import axios from "axios";
import * as _ from "lodash";
import { getReadableDate, pDFFields } from "../../util";
import TypingAnimation from "../TypingAnimation";
import { ConversationStep, Theme } from "../../interfaces";

const { REACT_APP_MORTGAGE_URL } = process.env;

interface IProps {
  step?: ConversationStep;
  values?: any;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
}

interface IState {
  values: any;
  theme: Theme;
}

class AppointmentAPICall extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: props?.values,
      theme: props?.step?.theme!,
    };
  }

  //get email from keys
  getEmailValue(values: any): string {
    const arrayOfValues = Object.keys(values);
    const emailKey = arrayOfValues.find(
      (data: any) => data.toLowerCase().indexOf("email") > -1
    );
    return emailKey ? values[emailKey] : null;
  }

  getNames(values: any): string {
    const arrayOfValues = Object.keys(values);
    const _firstName: string | any = arrayOfValues.find(
      (data: any) => data.toLowerCase().indexOf("first_name") > -1
    );
    const _lastName: string | any = arrayOfValues.find(
      (data: any) => data.toLowerCase().indexOf("last_name") > -1
    );
    return _firstName || _lastName
      ? `${values[_firstName]} ${values[_lastName]}`
      : "";
  }

  async sendAppointmentEmail() {
    const values = this.state.values;
    const theme = this.state.theme;
    const name = values?.applicant1_firstName
      ? `${values?.applicant1_firstName}`
      : this.getNames(values);
    const email = values?.applicant1_email
      ? values?.applicant1_email
      : this.getEmailValue(values);
    const companyLogo = theme?.logo;
    const date = values?.appointment_date?.split("T")[0];
    const time = values?.appointment_date?.split("T")[1]?.split(":");
    const startTime = `${date}T${time[0]}:00:00+0100`;
    const endTime = `${date}T${time[0]}:30:00+0100`;
    const _portalName = theme?.emailCustomization?.portalName || "";
    const formData = Object.keys(values)
      .map((key) => {
        if (
          key === "theme" ||
          key === "simplified" ||
          key === "templates" ||
          key === "property_filtered"
        ) {
          return null;
        }

        if (
          key === "mortgage_type" &&
          (theme.version === "customer-service-questionnaire" ||
            theme.version === "rightmove")
        ) {
          return null;
        }

        if (
          _portalName === "Fair Investment App" &&
          (key === "payment_method" ||
            key === "reasonForRemortgage" ||
            key === "product_type" ||
            key === "product_term" ||
            key === "mortgage_class" ||
            key === "monthly_rent")
        ) {
          return null;
        }

        let refinedKey = key;
        if (key.indexOf("]_") > -1) {
          refinedKey = key.substring(key.indexOf("]_") + 2);
        }
        return {
          label: _.startCase(refinedKey),
          value: JSON.stringify(values[key]),
        };
      })
      .filter(Boolean);

    if (
      values?.contact_requested === "Video Call" ||
      theme.version === "rightmove"
    ) {
      //trigger video call appointment
      await axios.post(
        `${REACT_APP_MORTGAGE_URL}/api/teams/scheduleAppointment`,
        {
          apiKey: theme?.apiKey,
          name,
          email,
          startTime,
          endTime,
          props: {
            startTime: getReadableDate(startTime),
            endTime: getReadableDate(endTime),
            email,
            name,
            formData,
            companyLogo,
            theme: theme?.customization?.primaryColor,
            botName: theme?.customization?.botName,
          },
          templateName:
            theme.version === "rightmove"
              ? "call-appointment"
              : theme?.emailCustomization?.appointmentTemplate
              ? theme?.emailCustomization?.appointmentTemplate
              : "video-appointment",
          emailCustomization: {
            ...theme?.emailCustomization,
            subject:
              theme?.emailCustomization?.subject || "Mortgage Appointment",
          },
          appointmentCustomization: theme?.appointmentCustomization,
          admin: theme?.email,
          links: theme?.links,
          ...(theme?.files?.length && {
            fileMap: pDFFields(values, theme),
          }),
        }
      );
    } else {
      const defaultTemplate =
        values?.contact_requested === "Telephone Call"
          ? "call-appointment"
          : "face-to-face-appointment";
      await axios.post(
        `${REACT_APP_MORTGAGE_URL}/api/teams/requestCallOrFaceToFaceAppointment`,
        {
          apiKey: theme?.apiKey,
          name,
          email,
          props: {
            startTime: getReadableDate(startTime),
            endTime: getReadableDate(endTime),
            formData,
            name,
            email,
            companyLogo,
            theme: theme?.customization?.primaryColor,
            botName: theme?.customization?.botName,
          },
          templateName: theme?.emailCustomization?.appointmentTemplate
            ? theme?.emailCustomization?.appointmentTemplate
            : defaultTemplate,
          emailCustomization: {
            ...theme?.emailCustomization,
            subject:
              theme?.emailCustomization?.subject || "Mortgage Appointment",
          },
          appointmentCustomization: theme?.appointmentCustomization,
          admin: theme?.email,
          links: theme?.links,
          ...(theme?.files?.length && {
            fileMap: pDFFields(values, theme),
          }),
        }
      );
    }
  }

  async sendNoAppointmentEmail() {
    await axios.post(`${REACT_APP_MORTGAGE_URL}/api/teams/noAppointment`, {
      apiKey: this.state.theme?.apiKey,
      appointmentCustomization: this.state.theme?.appointmentCustomization,
      emailCustomization: this.state.theme?.emailCustomization,
      email: this.state.values?.applicant1_email
        ? this.state.values?.applicant1_email
        : this.getEmailValue(this.state.values),
      props: {
        name: this.state.values?.applicant1_firstName
          ? `${this.state.values?.applicant1_firstName}`
          : this.getNames(this.state.values),
      },
      links: this.state.theme?.links,
    });
  }

  async componentDidMount() {
    if (this.state.values?.talk_with_advisor === "Yes") {
      await this.sendAppointmentEmail();
    } else {
      await this.sendNoAppointmentEmail();
    }

    this.props.triggerNextStep!({
      trigger: this.props.step!.trigger,
    });
  }

  render() {
    if (this.state.values?.talk_with_advisor === "No") return null;
    if (this.state.values?.contact_requested === "Talk Now") return null;

    return (
      <div className="conversation-message-bubble">
        <TypingAnimation theme={this.state.theme} type="bot" />
        <div className="conversation-message-bubble-text">
          I'm just booking this in for you
        </div>
      </div>
    );
  }
}

export default React.memo(AppointmentAPICall);
