import { ConversationStep, Theme } from "../../interfaces";
import CriteriaCheck from "./CriteriaCheck";

import DefaultSteps from "../DefaultSteps";

const criteria = (theme: Theme) => {
  return [
    {
      id: "video_greeting",
      type: "bot",
      video:
        "https://mp-video-files.s3.eu-west-2.amazonaws.com/501P4533-7616-4574-35P5-5r1ar9716570.mp4",
      trigger: "help_query",
    },
    {
      id: "help_query",
      type: "bot",
      message:
        "Hey – I’m Parker, the Criteria & Help Assistant. How can I help?",
      options: [
        {
          label: "System Help",
          value: "System Help",
          trigger: "flow_not_supported",
        },
        {
          label: "Criteria Check",
          value: "Criteria Check",
          trigger: "criteria_check_greeting",
        },
        {
          label: "Contact Helpdesk",
          value: "Contact Helpdesk",
          trigger: "flow_not_supported",
        },
      ],
    },
    {
      id: "flow_not_supported",
      type: "bot",
      message: "This flow is not supported yet",
      end: true,
    },
    ...CriteriaCheck(theme),
    ...DefaultSteps(theme),
  ] as ConversationStep[];
};

export default criteria;
