/* eslint no-eval: 0 */
import React, { useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import * as _ from "lodash";
// import Lottie from "react-lottie";

import ChatMessageButton from "../components/ChatMessageButton";
import ChatMessageCustom from "../components/ChatMessageCustom";
import ChatMessageText from "../components/ChatMessageText";

// import * as animationData from "../assets/lottie/confetti.json";
import { ConversationInterface, ConversationStep } from "../interfaces";
import ChatMesageInputTypeahead from "../components/ChatMesageInputTypeahead";
import ChatMessageVideo from "../components/ChatMessageVideo";
import ChatMessageInput from "../components/ChatMessageInput";

const Conversation: React.FC<ConversationInterface> = ({
  steps,
  theme,
  switchFlow,
  config,
}) => {
  const bodyRef = useRef(null);
  const defaultValues = {
    // mortgage_type:
    //   theme?.version === "protection" ? "Protection Review" : "First Home",
    theme,
  };
  // const [playAnimation, setPlayAnimation] = useState(true);
  const [renderedSteps, setRenderedSteps] = useState<ConversationStep[]>([
    {
      ...steps[0],
      theme,
    },
  ]);
  const [currentStep, setCurrentStep] = useState<ConversationStep>({
    ...steps[0],
    theme,
  });
  const [currentCustomTrigger, setCurrentCustomTrigger] = useState(null);
  const [values, setValues] = useState(defaultValues);

  const scrollToBottom = () => {
    if (bodyRef.current) {
      const element = bodyRef.current! as HTMLDivElement;
      element.scrollTop = element.scrollHeight;
    }
  };

  const handleCustomTrigger = async (value: string | number) => {
    if (
      currentStep?.id === "connect_mortgage" &&
      value === "Yes" &&
      currentCustomTrigger
    ) {
      const {
        theme: {
          name,
          email,
          botAvatar,
          primaryColor,
          secondaryColor,
          logo,
          expertPhoneNumber,
          switchToMortgageText,
          criteria,
        },
      } = currentCustomTrigger!;
      //switch to mortgage_type
      const newFlowSteps = switchFlow("mortgage", {
        ...theme,
        name,
        email,
        logo,
        customization: {
          botAvatar,
          primaryColor,
          secondaryColor,
          expertPhoneNumber,
          switchToMortgageText,
          criteria,
        },
      });
      setCurrentCustomTrigger(null);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      return new Promise((resolve) => {
        const step = newFlowSteps.find(
          (step: ConversationStep) => step.id === "mortgage_type"
        );
        if (step) {
          step.message =
            "First, let me check the mortgage market for you. What type of mortgage do you need?";
        }
        resolve(step);
      });
    }
  };

  const handleSwitchFlow = () => {
    const customTrigger = theme?.customization?.triggers?.find(
      (trigger) => trigger?.triggerOn === currentStep?.id
    );
    //trigger here
    switch (customTrigger?.type) {
      case "switch-flow":
        const { triggerOn, triggerToCheckId, triggerToCheckValue, switchTo } =
          customTrigger;

        if (
          currentStep?.id === triggerOn &&
          ((values as any)[triggerToCheckId] || "") === triggerToCheckValue
        ) {
          switch (switchTo) {
            case "property-to-mortgage":
              setCurrentCustomTrigger(customTrigger);
              return steps.find((step) => step.id === "connect_mortgage");
            default:
              break;
          }
        }
        break;
      default:
        break;
    }
  };

  const setDataValues = (data: any) => {
    console.log("setting==>", data);
    setValues({
      ...values,
      ...data,
    });
  };

  const getValidatorErrorMessage = async (currentStep: any, value: any) => {
    for (const validator of currentStep?.validator) {
      const condition = validator.condition;
      const error = validator.error;
      const formedCondition = condition.replace("{values.amount}", value);
      const evalCondition = eval(formedCondition);
      if (!evalCondition) {
        return error;
      }
    }
  };

  const checkInvalidInput = async (value: any) => {
    if (currentStep?.validator) {
      if (Array.isArray(currentStep?.validator)) {
        return getValidatorErrorMessage(currentStep, value);
      } else {
        const result = await currentStep?.validator!(value, values);

        if (typeof result === "string") {
          return true;
        }
      }
    }
    return false;
  };

  const handleInputSubmit = async (userText: any, trigger: string) => {
    if (userText) {
      if (currentStep?.validator && (await checkInvalidInput(userText))) {
        //invalid should output error message and trigger question again
        const validatorValue = await checkInvalidInput(userText);
        const errorId = uuid();
        let userMessage = `${
          currentStep?.formatter ? currentStep?.formatter(userText) : userText
        }` as string;

        if (Array.isArray(userMessage)) {
          userMessage = userText[userText.length - 1];
        }
        const userStep: ConversationStep = {
          id: uuid(),
          type: "user",
          message: userMessage,
          trigger: errorId,
          theme,
        };
        const validatorMsg = Array.isArray(currentStep.validator)
          ? validatorValue
          : await currentStep?.validator!(userText, values);
        const errorStep: ConversationStep = {
          id: errorId,
          theme,
          type: "bot",
          message: validatorMsg,
          trigger: currentStep?.id,
        };

        steps.push(errorStep);

        setCurrentStep(userStep);
        setRenderedSteps([...renderedSteps, userStep]);
      } else {
        let local_tempValue;
        if (currentStep?.input?.currency) {
          const _tempValue = parseInt(userText) + 0.01;
          local_tempValue = _tempValue
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        triggerNextStep({
          value: userText,
          localeText: local_tempValue,
          currency: currentStep?.input?.currency,
          trigger,
        });
      }
    }
  };

  const handleFlowDisabled = async (
    currentStep: ConversationStep,
    nextStep: ConversationStep,
    data: any
  ) => {
    if (nextStep?.disable && nextStep?.disable(values)) {
      if (data?.value) {
        await handleNextStep(currentStep, {
          key: currentStep?.id,
          value: data?.value,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      while (nextStep?.disable && nextStep?.disable(values)) {
        nextStep = await handleStepTrigger(nextStep.trigger, data)!;
      }

      setCurrentStep({
        ...nextStep,
        theme,
      });
      setRenderedSteps([
        ...renderedSteps,
        {
          ...nextStep,
          theme,
        },
      ]);

      return true;
    }

    return false;
  };

  const handleStepTrigger = async (trigger: any, data: any) => {
    const switchFlowTrigger = handleSwitchFlow();
    const customTrigger = await handleCustomTrigger(data?.value!);

    if (switchFlowTrigger) {
      return switchFlowTrigger as ConversationStep;
    } else if (customTrigger) {
      return customTrigger as ConversationStep;
    }
    return getTriggeredStep(trigger, data?.value)!;
  };

  const getTriggeredStep = (trigger: any, value: any) => {
    return steps.find(
      (s) =>
        s.id ===
        (typeof trigger === "function" ? trigger(values, value) : trigger)
    );
  };

  const getStepMessage = (
    step: ConversationStep,
    nextMessage: string,
    prevMessage: string
  ) => {
    if (nextMessage?.match(/{previousValue}/g)) {
      return nextMessage.replace(
        /{previousValue}/g,
        step?.formatter ? step.formatter(prevMessage) : prevMessage
      );
    }
    const matches = nextMessage?.match(/{(.*?)}/g) || [];
    let newValue = nextMessage;
    console.log("matches", matches);
    console.log({
      newValue,
    });
    for (const match of matches) {
      const _property: any = match.slice(1).replace("}", "");
      console.log({ _property });
      console.log({
        values,
      });
      newValue = newValue.replace(match, dot.pick(_property, values));
    }
    return newValue;
  };

  const handleComponentNextStep = async (value: any) => {
    if (value?.value) {
      setValues({
        ...values,
        [value.key]: value.value,
      });
    }

    setCurrentStep({
      theme,
      ...currentStep?.trigger,
    });
    setRenderedSteps([
      ...renderedSteps,
      {
        theme,
        ...currentStep?.trigger,
      },
    ]);
  };

  const handleOptionNextStep = async (value: any) => {
    const options =
      typeof currentStep.options === "function"
        ? await currentStep.options(values)
        : currentStep.options;
    const option = options!.find((o) => o.value === value?.value);

    if (option) {
      const step = {
        id: uuid(),
        type: "user",
        end: currentStep.end,
        message: option.label,
        trigger: getTriggeredStep(option?.trigger, option?.value)?.id,
        theme,
      };

      if (currentStep?.custom_value) {
        //const customValue = await currentStep?.custom_value!(data?.value, values);
        value = await currentStep?.custom_value!(value?.value, values);
      }

      setValues({
        ...values,
        [value?.key]: option?.value,
      });
      setCurrentStep(step);
      setRenderedSteps([...renderedSteps, step]);
    } else {
      await handleTextNextStep(value);
    }
  };

  const handleTextNextStep = async (value: any) => {
    const step = value?.value
      ? {
          id: uuid(),
          type: "user",
          end: currentStep?.end,
          message: currentStep?.formatter
            ? currentStep?.formatter(value?.value! as string)
            : value?.value,
          trigger: currentStep?.trigger?.id,
          theme,
        }
      : {
          theme,
          ...currentStep?.trigger,
        };

    if (value?.value) {
      const custom = currentStep?.custom_value
        ? await currentStep?.custom_value(value?.value, values)
        : value;

      setValues({
        ...values,
        [custom.key]: custom.value,
      });
    }
    setCurrentStep(step);
    setRenderedSteps([...renderedSteps, step]);
  };

  const handleNextStep = async (step: ConversationStep, value: any) => {
    if (step?.component) {
      await handleComponentNextStep(value);
    } else if (step?.options) {
      await handleOptionNextStep(value);
    } else {
      await handleTextNextStep(value);
    }
  };

  const triggerNextStep = async (
    data: {
      value?: string | number;
      trigger?: string;
      condition?: string;
      localeText?: string;
      currency?: string;
    } = {}
  ) => {
    if (await checkInvalidInput(data?.value)) {
      console.log("handleInputSubmit :", data);
      handleInputSubmit(data?.value!.toString(), data?.trigger!);
    } else {
      const slicedLocaleText =
        data.localeText &&
        data.localeText.substring(0, data.localeText.indexOf(".01"));

      let value = {
        key: currentStep?.id!,
        value: data.localeText
          ? `${data.currency} ${slicedLocaleText}`
          : data?.value,
      };

      currentStep!.trigger = await handleStepTrigger(data?.trigger, data);

      // if (currentStep?.custom_value) {
      //   //const customValue = await currentStep?.custom_value!(data?.value, values);
      //   value = await currentStep?.custom_value!(data?.value, values);
      // }
      let prevMessage: any = data.currency ? slicedLocaleText : data.value;
      if (currentStep?.trigger?.id) {
        currentStep.trigger.message = getStepMessage(
          currentStep?.trigger,
          currentStep?.trigger?.message,
          prevMessage
        );
      }

      if (await handleFlowDisabled(currentStep!, currentStep?.trigger, data)) {
      } else {
        console.log("pre handle next step :", value);
        await handleNextStep(currentStep!, value);
      }
    }
  };

  const hideAvatar = (step: ConversationStep) => {
    const stepIndex = _.lastIndexOf(renderedSteps, step);
    const nextStepIndex = Math.min(stepIndex + 1, renderedSteps.length - 1);
    const isLastElement = stepIndex === renderedSteps.length - 1;

    //show avatar if last element && !user
    if (isLastElement) {
      return false;
    }

    //if not last elemenet, but next rendered step is `user`
    if (!isLastElement && renderedSteps[nextStepIndex]?.type === "user") {
      return false;
    }

    //custom component rendered
    if (
      !isLastElement &&
      renderedSteps[nextStepIndex]?.component &&
      nextStepIndex === renderedSteps.length - 1
    ) {
      return false;
    }

    return true;
  };

  const renderStep = (step: ConversationStep) => {
    if (step?.video) {
      return (
        <ChatMessageVideo
          currentStep={currentStep}
          step={step}
          values={values}
          disableTypingAnimation={true}
          disableScrollToBottom={step?.disableScrollToBottom}
          triggerNextStep={triggerNextStep}
          scrollToBottom={scrollToBottom}
          videoLink={step?.video}
        />
      );
    }

    if (step?.input && step?.input?.type === "typeahead") {
      return (
        <ChatMesageInputTypeahead
          currentStep={currentStep}
          step={step}
          values={values}
          disableScrollToBottom={step?.disableScrollToBottom}
          triggerNextStep={triggerNextStep}
          scrollToBottom={scrollToBottom}
        />
      );
    }

    if (step?.component) {
      return (
        <ChatMessageCustom
          currentStep={currentStep}
          setDataValues={setDataValues}
          disableScrollToBottom={step?.disableScrollToBottom}
          hideAvatar={hideAvatar(step) || step?.hideAvatar}
          triggerNextStep={triggerNextStep}
          values={values}
          step={step}
          scrollToBottom={scrollToBottom}
        />
      );
    }

    if (step?.options?.length) {
      return (
        <ChatMessageButton
          currentStep={currentStep}
          hideAvatar={hideAvatar(step) || step?.hideAvatar}
          triggerNextStep={triggerNextStep}
          values={values}
          step={step}
          disableScrollToBottom={step?.disableScrollToBottom}
          handleInputSubmit={handleInputSubmit}
          setDataValues={setDataValues}
          scrollToBottom={scrollToBottom}
        />
      );
    }

    if (step?.input && step?.input?.type === "textarea-solo") {
      return (
        <ChatMessageInput
          currentStep={currentStep}
          hideAvatar={hideAvatar(step) || step?.hideAvatar}
          handleInputSubmit={handleInputSubmit}
          triggerNextStep={triggerNextStep}
          values={values}
          disableScrollToBottom={step?.disableScrollToBottom}
          scrollToBottom={scrollToBottom}
          step={step}
          setDataValues={setDataValues}
        />
      );
    }

    if (step?.message)
      return (
        <ChatMessageText
          currentStep={currentStep}
          hideAvatar={hideAvatar(step) || step?.hideAvatar}
          handleInputSubmit={handleInputSubmit}
          triggerNextStep={triggerNextStep}
          values={values}
          disableScrollToBottom={step?.disableScrollToBottom}
          scrollToBottom={scrollToBottom}
          step={step}
          setDataValues={setDataValues}
        />
      );

    return null;
  };

  return <>{React.Children.toArray(renderedSteps.map(renderStep))}</>;

  // return (
  //   <>
  //     <div className="conversation-body">
  //       {React.Children.toArray(renderedSteps.map(renderStep))}
  //     </div>
  //     {playAnimation && currentStep?.playAnimation && (
  //       <div className="animation-wrapper">
  //         <Lottie
  //           options={{
  //             loop: false,
  //             autoplay: true,
  //             animationData: animationData,
  //           }}
  //           height="100%"
  //           width="100%"
  //           eventListeners={[
  //             {
  //               eventName: "complete",
  //               callback: () => setPlayAnimation(false),
  //             },
  //           ]}
  //         />
  //       </div>
  //     )}
  //   </>
  // );
};

export default React.memo(Conversation);
