import { PropertyTemplate, PropertyType } from "../../interfaces";
import { numberWithCommas } from "../../util";

interface PropertyItemInterface {
  property: PropertyTemplate;
}

const PropertyItem: React.FC<PropertyItemInterface> = ({ property }) => {
  return (
    <a
      href={property.propertyLink}
      target="_blank"
      className="property-item-container"
      rel="noreferrer"
    >
      <img
        className="property-item-image"
        src={property.imageLink}
        alt={property.developmentName}
        width="100%"
        height="200"
      />
      <div className="property-item-body">
        <p>{property.developmentName}</p>
        {property.propertyType === PropertyType.Sale && (
          <p>
            Starts at{" "}
            <strong>
              {numberWithCommas(property.startingPrice?.toString()!)}
            </strong>
          </p>
        )}
        {property.propertyType === PropertyType.Rental && (
          <p>
            Monthly Rent{" "}
            <strong>
              {numberWithCommas(property.rentalPerMonth?.toString()!)}
            </strong>
          </p>
        )}
        <p>{property.location}</p>
      </div>
    </a>
  );
};

export default PropertyItem;
