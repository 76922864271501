import { ConversationStep, Theme } from "../interfaces";

const DefaultSteps = (theme: Theme) => {
  return [
    {
      id: "brief_situation",
      message:
        "Would you like to provide a brief outline of your situation at this stage?",
      type: "bot",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "share_situation",
        },
        {
          label: "No",
          value: "No",
          trigger: "bye_promise",
        },
      ],
    },
    {
      id: "share_situation",
      type: "bot",
      message: "",
      input: {
        placeholder: "Type here...",
        type: "textarea-solo",
      },
      trigger: "bye_promise",
    },
    {
      id: "bye_promise",
      message:
        "Thank you! We will be in touch shortly to discuss your requirements further",
      type: "bot",
      trigger: "blank_send",
    },
    {
      id: "not_supported",
      message: "This flow is not supported yet",
      end: true,
      type: "bot",
    },
    {
      id: "end_greeting",
      message: "Have a great day.",
      end: true,
      type: "bot",
    },
    {
      id: "flow_end",
      message:
        //TODO: FOR CORECO
        theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341"
          ? "A member of the team will email you shortly to confirm your 30-minute appointment."
          : "Great, you're all booked in.",
      end: true,
      playAnimation: true,
      type: "bot",
    },
  ] as ConversationStep[];
};

export default DefaultSteps;
