import { ConversationStep, Theme } from "../../interfaces";
import DefaultSteps from "../DefaultSteps";
import AppointmentSteps from "../AppointmentSteps";
import { getApplicantSteps } from "../ApplicantSteps";
// import {getApplicantSteps} from "../ApplicantSteps";

const RightmoveAutoResponder = (theme: Theme) => {
  return [
    {
      id: "greetings",
      type: "bot",
      message: `Hey - I'm ${theme?.customization?.botName}`,
      trigger: "grateful",
    },
    {
      id: "grateful",
      type: "bot",
      message: "Thanks for your Rightmove enquiry",
      trigger: "Sorted mortgage",
    },
    {
      id: "Sorted mortgage",
      type: "bot",
      message: "Have you got a mortgage sorted",
      options: [
        {
          label: "Yes",
          value: "yes",
          trigger: "In a chain",
        },
        {
          label: "No",
          value: "no",
          trigger: "In a chain",
        },
        {
          label: "Please refer me to someone",
          value: "Please refer me to someone",
          trigger: "In a chain",
        },
      ],
    },
    {
      id: "In a chain",
      type: "bot",
      message: "Are you in a chain?",
      options: [
        {
          label: "I'm just looking",
          value: "I'm just looking",
          trigger: "sort-purchase",
        },
        {
          label: "I'm in a chain",
          value: "I'm in a chain",
          trigger: "sort-purchase",
        },
      ],
    },
    {
      id: "sort-purchase",
      type: "bot",
      message: "What sort of purchase is this?",
      options: [
        {
          label: "Rental",
          value: "Rental",
          trigger: "applicant1_title",
        },
        {
          label: "First Home",
          value: "First Home",
          trigger: "applicant1_title",
        },
        {
          label: "Moving Home",
          value: "Moving Home",
          trigger: "applicant1_title",
        },
      ],
    },
    ...getApplicantSteps(1),
    {
      id: "opt-in",
      type: "bot",
      message:
        "We'd like you to keep you in the loop about new properties that hit the market, is that ok?",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "speak-with-team",
        },
        {
          label: "No, thanks",
          value: "No, thanks",
          trigger: "speak-with-team",
        },
      ],
    },
    {
      id: "speak-with-team",
      type: "bot",
      message: "When would you like to speak to the team?",
      trigger: "select_date",
    },
    ...AppointmentSteps(theme),
    ...DefaultSteps(theme),
  ] as ConversationStep[];
};

export default RightmoveAutoResponder;
