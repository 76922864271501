import { ConversationStep, Theme } from "../../interfaces";
import { getApplicantSteps } from "../ApplicantSteps";
import AppointmentSteps from "../AppointmentSteps";
import ProtectionSteps from "../ProtectionSteps";
import DefaultSteps from "../DefaultSteps";

const Protection = (theme: Theme) => {
  return [
    {
      id: "1",
      type: "bot",
      message: "Hey!",
      trigger: "2",
    },
    {
      id: "2",
      type: "bot",
      message: `My name is <strong>${theme?.customization?.botName}</strong>, and I help people with their insurance needs.`,
      trigger: "household",
    },
    ...ProtectionSteps,
    ...AppointmentSteps(theme),
    ...DefaultSteps(theme),
    ...getApplicantSteps(1),
    ...getApplicantSteps(2),
  ] as ConversationStep[];
};

export default Protection;
