import { useEffect, useState } from "react";
import { ChatMessageButtonOptionsInterface } from "../interfaces";
import { hexToRgb } from "../util";

import DOMPurify from "dompurify";

const ChatMessageButtonOptions: React.FC<ChatMessageButtonOptionsInterface> = ({
  triggerNextStep,
  values = {},
  options,
  theme,
}) => {
  const [formattedOptions, setFormattedOptions] = useState<any[]>([]);

  useEffect(() => {
    const initializeOptions = async () => {
      let optionsList =
        typeof options === "function" ? await options(values) : options!;
      setFormattedOptions(
        optionsList.map((option) => ({
          label: option.label!,
          value: option.value!,
          trigger: option.trigger!,
          enable: option.enable!,
        }))
      );
    };

    initializeOptions();
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  return (
    <div className="conversation-message-button">
      {formattedOptions
        .filter((o) => (o?.enable ? o.enable(values) : true))
        .map(({ label, value, trigger }, index) => (
          <div
            key={`${value}-${index}`}
            className="conversation-message-option"
            data-press={value}
            style={{
              backgroundColor: theme?.customization?.primaryColor,
              borderColor: theme?.customization?.primaryColor,
              boxShadow: `0px 12px 18px -6px rgb(${
                hexToRgb(theme?.customization?.primaryColor!)?.r
              } ${hexToRgb(theme?.customization?.primaryColor!)?.g} ${
                hexToRgb(theme?.customization?.primaryColor!)?.b
              } / 53%)`,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#FFF";
              e.currentTarget.style.borderColor =
                theme?.customization?.primaryColor!;
              e.currentTarget.style.color = theme?.customization?.primaryColor!;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                theme?.customization?.primaryColor!;
              e.currentTarget.style.borderColor =
                theme?.customization?.primaryColor!;
              e.currentTarget.style.color = "#FFF";
            }}
            onClick={() => triggerNextStep({ value, trigger })}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
          />
        ))}
    </div>
  );
};

export default ChatMessageButtonOptions;
