import React from "react";
import axios from "axios";
import TypingAnimation from "../TypingAnimation";
import { ConversationStep, Theme } from "../../interfaces";

const { REACT_APP_SHOP_URL } = process.env;

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
  setDataValues?: (values: any) => void;
}

interface IState {
  loading: boolean;
  textToDisplay: string;
  values: any;
  theme: Theme;
}

class PropertyAPICall extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: props?.values,
      loading: true,
      textToDisplay:
        props.values?.mortgage_sorted === "Refer"
          ? "Let me see what I can find..."
          : "Let me just scan the market",
      theme: props?.step?.theme!,
    };
  }

  async componentDidMount() {
    const {
      property_town,
      property_bedrooms,
      property_rent,
      property_feature,
      property_value,
      help_to_buy,
    } = this.state.values;

    const {
      data: { resultType, properties },
    } = await axios.post(
      `${REACT_APP_SHOP_URL}/api/v1/shop/client/property/lookup?apiKey=${this.state.theme?.apiKey}`,
      {
        property_town,
        property_bedrooms,
        property_rent,
        property_feature,
        property_budget: property_value,
        help_to_buy,
      }
    );

    if (resultType === "exact") {
      this.setState({
        loading: false,
        textToDisplay:
          "Based on what you've told me, I've found a couple properties that might suit you. Take a look below",
      });
    } else {
      this.setState({
        loading: false,
        textToDisplay: "I've found the following properties",
      });
    }

    this.props.setDataValues!({ property_filtered: properties });

    setTimeout(() => {
      this.props.triggerNextStep!({
        trigger: this.props.step!.trigger,
      });
    }, 1000);
  }

  render() {
    return (
      <div className="conversation-message-bubble">
        <TypingAnimation theme={this.state.theme} type="bot" />
        <div className="conversation-message-bubble-text">
          {this.state.textToDisplay}
        </div>
      </div>
    );
  }
}

export default React.memo(PropertyAPICall);
