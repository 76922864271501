import data from "../../assets/data/criterias";

import CriteriaSnapshotCall from "../../components/custom/CriteriaSnapshotCall";
import { ConversationStep, Theme } from "../../interfaces";

const criteriaCheck = (theme: Theme) => {
  return [
    {
      id: "criteria_check_greeting",
      type: "bot",
      message: "Great!",
      trigger: "mortgage_type",
    },
    {
      id: "mortgage_type",
      type: "bot",
      message: "What type of mortgage are you looking at?",
      options: [
        {
          label: "Residential",
          value: "Standard",
          trigger: "criteria_input",
        },
        {
          label: "Buy to Let",
          value: "Buy_To_Let",
          trigger: "criteria_input",
        },
        {
          label: "Bridging",
          value: "Bridging_Loan",
          trigger: "criteria_input",
        },
        {
          label: "Not Sure",
          value: "Standard",
          trigger: "no_problem",
        },
      ],
    },
    {
      id: "no_problem",
      type: "bot",
      message: "No problem.",
      trigger: "criteria_input",
    },
    {
      id: "criteria_input",
      type: "bot",
      message: "Start typing and let me see if i can assist.",
      input: {
        type: "typeahead",
        placeholder: "Start typing...",
        data,
        array: true,
      },
      options: [
        {
          label: "Add another criteria",
          value: "add",
        },
        {
          label: "Search",
          value: "search",
          trigger: "criteria_snapshot_text",
        },
      ],
      // trigger: "criteria_snapshot_text",
    },
    {
      id: "criteria_snapshot_text",
      type: "bot",
      message: "Let me have a look",
      trigger: "criteria_snapshot",
    },
    {
      id: "criteria_snapshot",
      type: "bot",
      component: <CriteriaSnapshotCall />,
      hideAvatar: true,
      disableScrollToBottom: true,
      end: true,
    },
  ] as ConversationStep[];
};

export default criteriaCheck;
