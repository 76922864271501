import React from "react";
import {
  ConversationStep,
  PropertyTemplate,
  PropertyType,
} from "../../interfaces";

import PropertyItem from "./PropertyItem";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import "./PropertyList.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
}

interface IState {
  values: any;
  propertyType: PropertyType;
  filteredProperties: PropertyTemplate[];
}

class PropertyList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: props?.values,
      propertyType: props?.values?.property_type,
      filteredProperties: props?.values?.property_filtered,
    };
  }

  async componentDidMount() {
    this.props.triggerNextStep!({
      trigger: this.props.step!.trigger,
    });
  }

  render() {
    return (
      <div
        className="conversation-message-bubble"
        style={{
          maxWidth: "100%",
          width: "100%",
          background: "transparent",
          boxShadow: "none",
          padding: "0px",
          // height: 800,
          // padding: 0,
        }}
      >
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={10000}
          infinite={false}
          containerClass="carousel-container"
          itemClass="carousel-item-margin-30"
        // removeArrowOnDeviceType={['tablet', 'mobile']}
        >
          {this.state.filteredProperties.map((property, index) => (
            <PropertyItem property={property} key={index} />
          ))}
        </Carousel>
      </div>
    );
  }
}

export default React.memo(PropertyList);
