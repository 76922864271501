import React from "react";
import axios from "axios";
import TypingAnimation from "../TypingAnimation";
import { ConversationStep, Theme } from "../../interfaces";
import { sendNewLead } from "../../util";

const { REACT_APP_WEBSOCKET_URL, REACT_APP_SHOP_URL } = process.env;

interface IProps {
  step?: ConversationStep;
  values?: any;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
}

interface IState {
  values: any;
  theme: Theme;
  connectionId: string;
  isCompleted: boolean;
  displayText: string;
}

class AppointmentTelephoneCall extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: props?.values,
      theme: props?.step?.theme!,
      connectionId: "",
      isCompleted: false,
      displayText: "Perfect. I'm giving you a call now.",
    };
    this.listenToSocket = this.listenToSocket.bind(this);
  }

  async listenToSocket(event: any) {
    const body = JSON.parse(event.data);

    if (body?.type === "connectionId") {
      this.setState({
        connectionId: body?.connectionId,
      });
      await this.callClient();
      await sendNewLead(this.state.values, this.state.theme);
      this.props.triggerNextStep!({
        trigger: this.props.step!.trigger,
      });
    } else {
      // if (!this.state.isCompleted) {
      //   this.setState({
      //     isCompleted: true,
      //   });
      //   switch (body?.status) {
      //     case "canceled":
      //       this.setState({
      //         displayText: "Your call was cancelled. Please try again later.",
      //       });
      //       break;
      //     case "failed":
      //       this.setState({
      //         displayText:
      //           "There's something wrong with the call. Please try again later.",
      //       });
      //       break;
      //     case "no-answer":
      //     case "busy":
      //       this.setState({
      //         displayText:
      //           "Our experts are currently busy right now. Please try again later.",
      //       });
      //       break;
      //     default:
      //       this.setState({
      //         displayText: "Thank you for speaking with our experts.",
      //       });
      //       break;
      //   }
      //   await sendNewLead(this.state.values, this.state.theme);
      //   this.props.triggerNextStep!({
      //     trigger: this.props.step!.trigger,
      //   });
      // }
    }
  }

  async callClient() {
    try {
      await axios.post(`${REACT_APP_SHOP_URL}/api/v1/shop/client/call`, {
        phoneNumber: this.state.values?.applicant1_contactNumber,
        apiKey: this.state.theme.apiKey,
        connectionId: this.state.connectionId,
      });
    } catch (err) {
      console.log("err");
    }
  }

  componentDidMount() {
    const ws = new WebSocket(REACT_APP_WEBSOCKET_URL!);
    ws.onmessage = this.listenToSocket;
  }

  render() {
    return (
      <div className="conversation-message-bubble">
        <TypingAnimation theme={this.state.theme} type="bot" />
        <div className="conversation-message-bubble-text">
          {this.state.displayText}
        </div>
      </div>
    );
  }
}

export default React.memo(AppointmentTelephoneCall);
