import { ConversationStep } from "../../interfaces";

export default [
  {
    id: "property_rent",
    type: "bot",
    message: "What can you put towards rent each month?",
    options: [
      {
        label: "£ 1,250",
        value: "1250",
        trigger: "property_feature",
      },
      {
        label: "£ 1,750",
        value: "1750",
        trigger: "property_feature",
      },
      {
        label: "£ 2,000",
        value: "2000",
        trigger: "property_feature",
      },
      {
        label: "£ 2,500",
        value: "2500",
        trigger: "property_feature",
      },
    ],
  },
] as ConversationStep[];
