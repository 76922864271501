import { ConversationStep } from "../../interfaces";

import { numberWithCommas } from "../../util";

export default [
  {
    id: "mortgage_type",
    type: "bot",
    message: "How can I help?",
    options: [
      {
        label: "First Time Buyer",
        value: "First Home",
        trigger: "help_to_buy",
      },
      {
        label: "I'm Moving Home",
        value: "I'm Moving Home",
        trigger: "help_to_buy",
      },
      {
        label: "Buy to Let Investor",
        value: "Buy to Let Investor",
        trigger: "property_town",
      },
      {
        label: "Second Home",
        value: "Second Home",
        trigger: "help_to_buy",
      },
      {
        label: "Holiday Home",
        value: "Holiday Home",
        trigger: "property_town",
      },
    ],
  },
  {
    id: "help_to_buy",
    message: "Will you be buying through Help to Buy?",
    type: "bot",

    options: [
      {
        label: "Yes",
        value: "Yes",
        trigger: "property_town",
      },
      {
        label: "No",
        value: "No",
        trigger: "property_town",
      },
      {
        label: "Not Sure",
        value: "Not Sure",
        trigger: "property_town",
      },
    ],
  },
  {
    id: "property_value",
    type: "bot",
    message: "What's your budget?",
    options: [
      {
        label: "£ 300,000",
        value: "300000",
        trigger: "property_bedrooms",
      },
      {
        label: "£ 400,000",
        value: "400000",
        trigger: "property_bedrooms",
      },
      {
        label: "£ 500,000",
        value: "500000",
        trigger: "property_bedrooms",
      },
      {
        label: "£ 600,000",
        value: "600000",
        trigger: "property_bedrooms",
      },
      {
        label: "Not Sure",
        value: "Not Sure",
        trigger: "property_bedrooms",
      },
    ],
    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    trigger: "property_bedrooms",
  },
  {
    id: "mortgage_sorted",
    type: "bot",
    message: "Have you got a mortgage sorted?",
    options: [
      {
        label: "Yes",
        value: "Yes",
        trigger: "property_feature",
      },
      {
        label: "No",
        value: "No",
        trigger: "property_feature",
      },
      {
        label: "Please refer me to someone 🙂",
        value: "Refer",
        trigger: "property_feature",
      },
    ],
  },
] as ConversationStep[];
