import React, { useEffect } from "react";
import DomPurify from "dompurify";
import ChatMessage from "./ChatMessage";
import ChatMessageInput from "./ChatMessageInput";
import { ChatMessageButtonInterface } from "../interfaces";
import ChatMessageButtonOptions from "./ChatMessageButtonOptions";

const ChatMessageButton: React.FC<ChatMessageButtonInterface> = ({
  hideAvatar,
  handleInputSubmit,
  triggerNextStep,
  values = {},
  step,
  setDataValues,
  currentStep,
  scrollToBottom,
  disableScrollToBottom,
  disableTypingAnimation,
}) => {
  const { options, message, theme, type, input } = step!;

  useEffect(() => {
    if (!disableScrollToBottom && step === currentStep) {
      scrollToBottom!();
    }
  });

  return (
    <div
      className={`conversation-message-button-wrapper conversation-message-button-wrapper-${type}`}
    >
      {message && (
        <ChatMessage
          step={step}
          hideAvatar={hideAvatar}
          disableTypingAnimation={disableTypingAnimation}
          scrollToBottom={scrollToBottom}
          render={() => (
            <div
              className="conversation-message-bubble-text"
              dangerouslySetInnerHTML={{
                __html: DomPurify.sanitize(message),
              }}
            />
          )}
        />
      )}

      {options?.length && (
        <ChatMessageButtonOptions
          triggerNextStep={triggerNextStep!}
          values={values}
          options={options}
          theme={theme!}
        />
      )}

      {input && input.type !== "typeahead" && step === currentStep && (
        <ChatMessageInput
          currentStep={currentStep}
          triggerNextStep={triggerNextStep}
          step={step}
          values={values}
          disableTypingAnimation={disableTypingAnimation}
          handleInputSubmit={handleInputSubmit}
          setDataValues={setDataValues}
        />
      )}
    </div>
  );
};

export default React.memo(ChatMessageButton);
