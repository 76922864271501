import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import ChatMessage from "./ChatMessage";
import ChatMessageButtonOptions from "./ChatMessageButtonOptions";
import ChatMessageAutocomplete from "./ChatMessageAutocomplete";
import { ChatMesageInputTypeaheadInterface } from "../interfaces/ChatMesageInputTypeahead";
// import ChatMessageButton from "./ChatMessageButton";

const ChatMesageInputTypeahead: React.FC<ChatMesageInputTypeaheadInterface> = ({
  triggerNextStep,
  values,
  step,
  currentStep,
  scrollToBottom,
  disableScrollToBottom,
}) => {
  const { theme, type, options, message } = step!;
  const [inputs, setInputs] = useState([...(values[step?.id!] || []), ""]);

  useEffect(() => {
    if (!disableScrollToBottom && step === currentStep) {
      scrollToBottom!();
    }
  });

  return (
    <>
      {message && (
        <ChatMessage
          step={step}
          hideAvatar={false}
          render={() => (
            <div
              className="conversation-message-bubble-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(step?.message!),
              }}
            />
          )}
        />
      )}
      <div
        className="conversation-typeahead"
        style={{
          display:
            step === currentStep && currentStep?.input?.type === "typeahead"
              ? "block"
              : "none",
        }}
      >
        {inputs.map((input, index: number) => (
          <ChatMessageAutocomplete
            key={index}
            values={values}
            value={input}
            handleInputSubmit={(userText) => {
              const newInputs = [...inputs];
              newInputs[index] = userText;
              setInputs(newInputs);
            }}
            hideSendButton={true}
            step={step}
          />
        ))}
      </div>
      {options?.length && (
        <div
          className={`conversation-message-button-wrapper conversation-message-button-wrapper-${type}`}
        >
          <ChatMessageButtonOptions
            triggerNextStep={(data) => {
              if (data?.value === "add") {
                const newInputs = [...inputs, ""];
                setInputs(newInputs);
              } else {
                triggerNextStep!({
                  value: inputs,
                  trigger: data?.trigger!,
                });
              }
            }}
            values={values}
            options={options!}
            theme={theme!}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(ChatMesageInputTypeahead);
