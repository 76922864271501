export enum PropertyType {
  Sale = "sale",
  Rental = "rental",
}

export interface PropertyTemplate {
  developmentName?: string;
  location?: string;
  imageLink?: string;
  startingPrice?: number;
  topPrice?: number;
  noOfBedroomsMin?: number;
  noOfBedroomsMax?: number;
  isHelpToBuy?: string;
  propertyLink?: string;
  rentalPerMonth?: number;
  propertyType?: PropertyType;
  features?: string[];
  rentalYield?: string;
}

export interface CriteriaTemplate {
  question?: string;
  answer?: string;
  video?: string;
}

export interface Templates {
  properties?: PropertyTemplate[];
  criterias?: CriteriaTemplate[];
}

export interface Customization {
  primaryColor?: string;
  companyName?: string;
  secondaryColor?: string;
  botAvatar?: string;
  botName?: string;
  triggers?: any[];
  expertPhoneNumber?: string;
  switchToMortgageText?: string;
  criteria?: {
    isVendorCriteriaOnly?: boolean;
    lenderName?: string;
  };
  templates?: Templates;
}

class EmailCustomization {
  subject?: string;
  portalName?: string;
  spiel_1?: string;
  bannerImage?: string;
  appointmentTemplate?: string;
  templateName?: string;
}

export interface OptionsCls {
  label?: string;
  value?: string;
  trigger?: string;
}
export interface FlowSteps {
  id?: string;
  message?: string;
  value?: string;
  trigger?: string;
  type?: string;
  options?: OptionsCls[];
}
export interface FlowDB {
  id?: string;
  flow?: FlowSteps[];
  importedFlows?: any;
}

interface Stripe {
  publicKey?: string;
}

interface Product {
  id: string;
  title: string;
  amount: number;
  currency: string;
  symbol: string;
}
interface DaysOfTheWeek {
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

interface WorkHours {
  startTime?: string;
  endTime?: string;
  days?: DaysOfTheWeek | any;
}

interface AppointmentCustomization {
  disableAppointmentTime?: boolean;
  disableFaceToFaceMeeting?: boolean;
  disableVideoCall?: boolean;
  disableTelephoneCall?: boolean;
}

export interface Theme {
  id?: string;
  version?: string;
  email?: string;
  logo?: string;
  themeId?: string;
  apiKey?: string;
  customization?: Customization;
  name?: string;
  emailCustomization?: EmailCustomization;
  appointmentCustomization?: AppointmentCustomization;
  flowCustomization?: string;
  customFlowData?: any;
  stripe?: Stripe;
  products?: Product[];
  files: any[];
  links?: any;
  workHours?: WorkHours;
  googleAnalyticsTrackingId?: string;
}

export * from "./ChatMessage";
export * from "./Conversation";
export * from "./ChatMessageButton";
export * from "./ChatMessageInput";
export * from "./TypingAnimation";
export * from "./ChatMessageText";
export * from "./ChatMessageCustom";
