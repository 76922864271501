import { ConversationStep, PropertyType, Theme } from "../../interfaces";

import BuyingPropertySteps from "./BuyingProperty";
import RentingPropertySteps from "./RentingProperty";
import DefaultSteps from "../DefaultSteps";
import AppointmentSteps from "../AppointmentSteps";
import SwitchSteps from "../SwitchSteps";
import { getApplicantSteps } from "../ApplicantSteps";

import PropertyList from "../../components/custom/PropertyList";
import PropertyListAPICall from "../../components/custom/PropertyListAPICall";

const PropertyRentalTrigger = (
  values: any,
  ifTrigger: string,
  elseTrigger: string
) => {
  if (values?.property_type === PropertyType.Rental) {
    return ifTrigger;
  }

  return elseTrigger;
};

const PropertyBedroomsTrigger = (values: any) =>
  PropertyRentalTrigger(values, "property_rent", "mortgage_sorted");

const PropertyTownTrigger = (values: any) =>
  PropertyRentalTrigger(values, "property_bedrooms", "property_value");

const PropertySteps = (theme: Theme) => {
  return [
    {
      id: "video_greeting",
      type: "bot",
      video:
        "https://mp-video-files.s3.eu-west-2.amazonaws.com/85o99r56-95r5-4865-8868-or01o520P961.mp4",
      trigger: "1",
    },
    {
      id: "1",
      type: "bot",
      message: `Hey 👋 - I'm ${theme?.customization?.botName}`,
      trigger: "2",
    },
    {
      id: "2",
      type: "bot",
      message: "I'm a Digital Property Assistant",
      trigger: "3",
    },
    {
      id: "3",
      type: "bot",
      message:
        "My job is to help you find the perfect property and connect you with one of the team.",
      trigger: "property_type",
    },
    {
      id: "property_type",
      type: "bot",
      message: "How can I help?",
      options: [
        {
          label: "I'm looking at buying a property",
          value: PropertyType.Sale,
          trigger: "mortgage_type",
        },
        {
          label: "I'm looking at renting a property",
          value: PropertyType.Rental,
          trigger: "property_town",
        },
      ],
    },
    {
      id: "property_town",
      message: "Where are you looking?",
      type: "bot",
      input: {
        type: "text",
        placeholder: "Enter town here...",
      },
      trigger: PropertyTownTrigger,
    },
    {
      id: "property_bedrooms",
      type: "bot",
      message: "How many bedrooms are you after?",
      options: [
        {
          label: "1",
          value: "1",
          trigger: PropertyBedroomsTrigger,
        },
        {
          label: "2",
          value: "2",
          trigger: PropertyBedroomsTrigger,
        },
        {
          label: "3+",
          value: "3+",
          trigger: PropertyBedroomsTrigger,
        },
      ],
    },
    {
      id: "property_feature",
      type: "bot",
      message: "Are you looking for anything in particular?",
      options: [
        {
          label: "Quick Commute",
          value: "Quick Commute",
          trigger: "applicant1_firstName",
        },
        {
          label: "Garden",
          value: "Garden",
          trigger: "applicant1_firstName",
        },
        {
          label: "Family Friendly",
          value: "Family Friendly",
          trigger: "applicant1_firstName",
        },
        {
          label: "Lively Nightlife",
          value: "Lively Nightlife",
          trigger: "applicant1_firstName",
        },
      ],
    },
    {
      id: "connect_mortgage",
      type: "bot",
      message:
        "I can help you with getting a mortgage, if you'd like. Would you like to see what you might be able to afford?",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "switch_to_mortgage",
        },
        {
          label: "No",
          value: "No",
          trigger: "property_great_day",
        },
      ],
    },
    {
      id: "property_recommendations",
      type: "bot",
      component: <PropertyList />,
      trigger: "talk_with_property_experts",
    },
    {
      id: "property_booking_confirmation",
      type: "bot",
      message: `Looking forward to finding you your dream home. You should receive an email confirmation shortly.`,
      trigger: "property_great_day",
    },
    {
      id: "property_great_day",
      message: "Have a great day.",
      type: "bot",
      end: true,
      playAnimation: true,
    },
    {
      id: "property_video_result",
      video:
        "https://mp-video-files.s3.eu-west-2.amazonaws.com/46P93o45-P461-456o-5535-P4519669o055.mp4",
      type: "bot",
      trigger: "property_api_call",
    },
    {
      id: "property_api_call",
      type: "bot",
      component: <PropertyListAPICall />,
      trigger: "property_recommendations",
    },
    ...BuyingPropertySteps,
    ...RentingPropertySteps,
    ...AppointmentSteps(theme),
    ...DefaultSteps(theme),
    ...SwitchSteps(theme),
    ...getApplicantSteps(1),
  ] as ConversationStep[];
};

export default PropertySteps;
