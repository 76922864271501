import moment from "moment";
import axios from "axios";
import { Theme } from "./interfaces";
import * as _ from "lodash";
import ReactGA4 from "react-ga4";
import ReactGA from "react-ga";

const { REACT_APP_MORTGAGE_URL } = process.env;

interface Event {
  category: string;
  action: string;
}

export const numberWithCommas = (number: string) => {
  if (number) {
    return `£ ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  return number;
};

const getTimeOfDay = (startTime: string) => {
  const time = parseInt(startTime);
  if (time >= 12) {
    return "PM";
  }
  return "AM";
};

export const getReadableDate = (date: string) => {
  const time = date.split("T")[1].split(":");
  return `${moment(date).format("MMMM Do YYYY")} at ${time[0]}:${
    time[1]
  } ${getTimeOfDay(time[0])}`;
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const pDFFields = (formValues: any, theme: Theme) => {
  console.log({ formValues });
  const files = theme?.files;
  const businessType = formValues["how_can_i_help"];
  const firstName =
    formValues["first_name"] || formValues["applicant1_firstName"];
  const lastName = formValues["last_name"] || formValues["applicant1_lastName"];
  const loanAmount = formValues["amount_to_borrow"];
  const lookingFor =
    formValues["looking_for"] || formValues["share_situation_mortgage"];
  const propertyValue = formValues["property_value"];
  const mortgageBalance =
    formValues["mortgage_balance"] || formValues["current_mortgage_balance"];
  const mortgageTerm = formValues["mortgage_term"];
  const loanPurpose = formValues["loan_purpose"];
  const rent = formValues["monthly_rent"];
  const netIncome = formValues["applicant1_annualSalary"];
  return {
    key: files?.find((file) => file?.filename === businessType)?.key,
    fields: {
      "Borrower Type": "",
      "Required Loan Amount": loanAmount,
      "Term Years": "2",
      "Term Months": mortgageTerm,
      "Estimated Completion Date_af_date": "",
      "Repayment type": "",
      "Company Name": "",
      Directors: "",
      "UK Incorporated": "",
      "Company Number": "",
      "Corporate Entity": "",
      "Industry Sector": "",
      "App 1 title": "",
      "App 1 First name": firstName,
      "App 1 Surname": lastName,
      "App 1 DOB_af_date": "",
      "App 1 Nationality": "",
      "App 1 Address": "",
      "App 1 Date Moved In_af_date": "",
      "App 1 Adverse": "",
      "App 1 Hass CBILs BBLs": "",
      "App 1 Income": "",
      "App 1 Expenses": "",
      "App 1 Net Worth": netIncome,
      "App 1 Properties": "",
      "App 1 Years Experience": "",
      "App 2 title": "",
      "App 2 First name": "",
      "App 2 Surname": "",
      "App 2 DOB_af_date": "",
      "App 2 Nationality": "",
      "App 2 Address": "",
      "App 2 Date Moved In_af_date": "",
      "App 2 Adverse": "",
      "App 2 Income": "",
      "App 2 Expenses": "",
      "App 2 Net Worth": "",
      "App 2 Properties": "",
      "App 2 Years Experience": "",
      "Purpose of Loan": loanPurpose,
      "App 1 Residency Status": "",
      "App 2 Residency Status": "",
      "App 1 Employment": "",
      "App 2 Employment": "",
      "Security address": "",
      "Property Type": "",
      "Property Sub Category": "",
      "Construction Type": "",
      "Estimated property value": propertyValue,
      "Estimated purchase price": "",
      Ownership: "",
      "Existing Lender": "",
      "Existing balance": mortgageBalance,
      "Charge Offered": "",
      Tenure: "",
      "Leasehold unexpired term": "",
      "Owner Occupied Type": "",
      "Investment Net Income": "",
      "Family Residence": "",
      Rent: rent,
      Period: rent ? "Monthly" : "",
      "Let to": "",
      "Has Additional Security": "",
      Notes: lookingFor,
      "Source of Deposit": "",
      "Property Inherited": "",
      "Currently Vacant": "",
      "Property larger than 30msq": "",
      "App 2 Hass CBILs BBLs": "",
    },
  };
};

export const sendNewLead = async (values: any, theme: Theme) => {
  const name = values?.applicant1_firstName
    ? `${values?.applicant1_firstName} ${values?.applicant1_lastName}`
    : "Lead";
  console.log("before array", { values });
  const companyLogo = theme?.logo;
  const formData = Object.keys(values)
    .map((key) => {
      if (
        key === "theme" ||
        key === "simplified" ||
        key === "templates" ||
        key === "property_filtered"
      ) {
        return null;
      }

      if (
        key === "mortgage_type" &&
        (theme.version === "customer-service-questionnaire" ||
          theme.version === "rightmove")
      ) {
        return null;
      }

      let refinedKey = key;
      if (key.indexOf("]_") > -1) {
        refinedKey = key.substring(key.indexOf("]_") + 2);
      }
      return {
        label: _.startCase(refinedKey),
        value: JSON.stringify(values[key]),
      };
    })
    .filter(Boolean);
  //
  await axios.post(`${REACT_APP_MORTGAGE_URL}/api/teams/newLead`, {
    apiKey: theme?.apiKey,
    props: {
      name,
      formData,
      companyLogo,
      companyName: theme?.customization?.companyName,
      botName: theme?.customization?.botName,
    },
    emailCustomization: theme?.emailCustomization,
    links: theme.links,
    admin: theme?.email,
    ...(theme?.files?.length && {
      fileMap: pDFFields(values, theme),
    }),
  });
};

export const isUniversalAnalytics = (googleAnalyticsTrackingId: string) => {
  return googleAnalyticsTrackingId.startsWith("UA-");
};

export const isGA4Analytics = (googleAnalyticsTrackingId: string) => {
  return googleAnalyticsTrackingId.startsWith("G-");
};

export const initializeUniversalAnalytics = (
  googleAnalyticsTrackingId: string
) => {
  ReactGA.initialize(googleAnalyticsTrackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const initializeGA4Analytics = (googleAnalyticsTrackingId: string) => {
  ReactGA4.initialize(googleAnalyticsTrackingId);
};

export const initializeGoogleAnalytics = (
  googleAnalyticsTrackingId: string[]
) => {
  const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
    isUniversalAnalytics(id)
  );
  const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
    isGA4Analytics(id)
  );
  if (uaIndex !== -1) {
    initializeUniversalAnalytics(googleAnalyticsTrackingId[uaIndex]);
  }

  if (ga4Index !== -1) {
    initializeGA4Analytics(googleAnalyticsTrackingId[ga4Index]);
  }
};

export const trackEvent = (googleAnalyticsTrackingId: string, event: Event) => {
  if (Array.isArray(googleAnalyticsTrackingId)) {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id)
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id)
    );

    if (uaIndex !== -1) {
      ReactGA.event(event);
    }

    if (ga4Index !== -1) {
      ReactGA4.event(event);
    }
  }
};
