import React, { useEffect, useState } from "react";
import { ChatMessageInputInterface } from "../interfaces";
import { hexToRgb } from "../util";

// import ChatMessageInputTypeahead from "./ChatMesageInputTypeahead";

const ChatMessageInput: React.FC<ChatMessageInputInterface> = ({
  handleInputSubmit,
  step,
  values,
  containerClassName,
  onTextChange = null,
  children,
  hideSendButton,
  text = "",
}) => {
  const { input, theme, type, id } = step!;
  const [userText, setUserText] = useState(text || "");
  useEffect(() => {
    setUserText(text);
  }, [text]);

  return (
    <div
      className={`conversation-footer conversation-footer-${type} ${containerClassName}`}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleInputSubmit!(userText, step?.trigger);
        }}
        style={{ width: "100%", textAlign: "center", height: "100%" }}
      >
        <div
          className="conversation-input-wrapper"
          style={{
            borderColor: theme?.customization?.primaryColor,
          }}
        >
          {input?.startAdornment && (
            <span className="conversation-input-startAdornment">
              {typeof input.startAdornment === "function"
                ? (input?.startAdornment as Function)(userText, values)
                : input.startAdornment}
            </span>
          )}
          <input
            className="conversation-input"
            type={input?.type}
            placeholder={input?.placeholder}
            required
            value={userText}
            autoFocus={true}
            data-test={id}
            onChange={(e) => {
              if (onTextChange) {
                onTextChange(e);
              }

              setUserText(e.target.value);
            }}
          />
          {input?.endAdornment && (
            <span className="conversation-input-endAdornment">
              {typeof input.endAdornment === "function"
                ? (input?.endAdornment as Function)(userText, values)
                : input.endAdornment}
            </span>
          )}
          {!hideSendButton && (
            <button
              type="submit"
              className="conversation-input-btn"
              style={{
                backgroundColor: theme?.customization?.primaryColor,
                boxShadow: `0px 12px 18px -6px rgb(${
                  hexToRgb(theme?.customization?.primaryColor!)?.r
                } ${hexToRgb(theme?.customization?.primaryColor!)?.g} ${
                  hexToRgb(theme?.customization?.primaryColor!)?.b
                } / 53%)`,
              }}
            >
              Send
            </button>
          )}
        </div>
      </form>
      {children}
    </div>
  );
};

export default React.memo(ChatMessageInput);
