import React, { useState } from "react";
import Fuse from "fuse.js";
import { ChatMessageAutocompleteInterface } from "../interfaces/ChatMessageAutocomplete";
import ChatMessageInput from "./ChatMessageInput";
import * as _ from "lodash";
// import ChatMessageButton from "./ChatMessageButton";

const ChatMessageAutocomplete: React.FC<ChatMessageAutocompleteInterface> = ({
  handleInputSubmit,
  values,
  step,
  hideSendButton,
  value = "",
}) => {
  const { input, theme } = step!;

  const [userText, setUserText] = useState(value);
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserText(value);

    if (value.length) {
      const fuse = new Fuse(input?.data, {
        includeScore: true,
        includeMatches: true,
        threshold: 1,
      });
      const result = (fuse.search(value).slice(0, 5) as any[]).map(
        (fuse) => fuse.item
      );
      setSuggestions(result);

      if (input?.array) {
        handleInputSubmit!(value, step?.trigger);
      }
    } else {
      setSuggestions([]);
      if (input?.array) {
        handleInputSubmit!([], step?.trigger);
      }
    }
  };

  const renderSuggestions = () => {
    if (suggestions.length === 0) return null;

    return (
      <ul className="conversation-typeahead-suggestions-list">
        {_.uniqWith(suggestions, _.isEqual).map((item) => (
          <li
            key={item}
            onClick={() => {
              setUserText(item);
              handleInputSubmit!(item, step?.trigger);
              setSuggestions([]);
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                theme?.customization?.primaryColor!;
              e.currentTarget.style.color = "#FFF";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#FFF";
              e.currentTarget.style.color = "#000";
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ChatMessageInput
      containerClassName="typeahead"
      step={step}
      hideSendButton={true}
      values={values}
      text={userText}
      onTextChange={onTextChange}
      handleInputSubmit={(userText, trigger) => {
        if (!hideSendButton) {
          handleInputSubmit!(userText, trigger);
        }
      }}
    >
      {renderSuggestions()}
    </ChatMessageInput>
  );
};

export default React.memo(ChatMessageAutocomplete);
