import { render } from "./index";

const createChatDiv = () => {
  const element = document.createElement("div");
  element.id = "meetparker-chat";

  document.body.appendChild(element);

  return element;
};

const MeetParkerChat = {
  init: (apiKey: string) => render(apiKey, createChatDiv()),
};

export default MeetParkerChat;
