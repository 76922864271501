import occupations from "../assets/data/occupations";
import { ConversationStep } from "../interfaces";
import { numberWithCommas } from "../util";

import InsuranceQuote from "../components/custom/InsuranceQuote";

const AgeValidator = (value: any) => {
  if (isNaN(value)) {
    return "Please enter a valid input";
  }

  if (parseInt(value) < 20 || parseInt(value) > 80) {
    return "You must be between 20 - 80 years old.";
  }

  return true;
};

const NumberValidator = (value: any) => {
  if (isNaN(value)) {
    return "Please enter a valid input";
  }

  return true;
};

const PartnerCoveredTrigger = (
  values: any,
  ifTrigger: string,
  elseTrigger: any
) => {
  if (values?.partner_covered === "Yes") {
    return ifTrigger;
  }

  return elseTrigger;
};

const NoEmploymentStatusTrigger = (
  values: any,
  ifTrigger: string,
  elseTrigger: string
) => {
  if (
    values?.employment === "Unemployed" ||
    values?.employment === "Retired" ||
    values?.employment === "HousePerson"
  ) {
    return ifTrigger;
  }
  return elseTrigger;
};

const WithPartnerEmploymentStatusTrigger = (
  values: any,
  ifTrigger: string,
  elseTrigger: string
) => {
  if (
    values?.partner_covered === "Yes" &&
    (values?.partner_employment === "Employed" ||
      values?.partner_employment === "SelfEmployed")
  ) {
    return ifTrigger;
  }

  return elseTrigger;
};

const AgeTrigger = (values: any) =>
  PartnerCoveredTrigger(values, "partner_age", "isSmoker");

const SmokerTrigger = (values: any) =>
  PartnerCoveredTrigger(values, "partner_smoker", "smoker_description");

const EmploymentTrigger = (values: any) =>
  PartnerCoveredTrigger(values, "partner_employment", "income");

const NoEmploymentTrigger = (values: any) =>
  PartnerCoveredTrigger(values, "partner_employment", "applicant1_firstName");

const PartnerEmploymentTrigger = (values: any) =>
  NoEmploymentStatusTrigger(values, "partner_income", "income");

const PartnerIncomeTrigger = (values: any) =>
  NoEmploymentStatusTrigger(values, "partner_occupation", "occupation");

const IncomeTrigger = (values: any) =>
  WithPartnerEmploymentStatusTrigger(values, "partner_income", "occupation");

const OccupationTrigger = (values: any) =>
  WithPartnerEmploymentStatusTrigger(
    values,
    "partner_occupation",
    "livingStatus"
  );

const filterOccupation = (value: any, trigger: string) => {
  const filteredOccupations = occupations.filter((occupation) =>
    occupation.description.toLowerCase().includes(value.toLowerCase())
  );

  if (filteredOccupations.length) {
    return {
      key: trigger,
      value: filteredOccupations[0],
    };
  }

  return {
    key: trigger,
    value: { code: "DAB00651", description: "Doctor - Health" },
  };
};

export default [
  {
    id: "household",
    type: "bot",
    message: "Now, let's work out your cover needs. Who's in your household?",
    options: [
      {
        label: "Just me",
        value: "Just me",
        trigger: "age",
      },
      {
        label: "Me and my children",
        value: "Me and my children",
        trigger: "age",
      },
      {
        label: "Me and my partner",
        value: "Me and my partner",
        trigger: "partner_covered",
      },
      {
        label: "Me, my partner and my children",
        value: "Me, my partner and my children",
        trigger: "partner_covered",
      },
    ],
  },
  {
    id: "partner_covered",
    type: "bot",
    message: "Do you want to look at cover for you and your partner?",
    options: [
      {
        label: "Yes",
        value: "Yes",
        trigger: "age",
      },
      {
        label: "No",
        value: "No",
        trigger: "age",
      },
    ],
  },
  {
    id: "age",
    type: "bot",

    message: "How old are you?",
    input: {
      type: "number",
      placeholder: "Type here...",
    },
    trigger: AgeTrigger,
    validator: AgeValidator,
  },
  {
    id: "partner_age",
    type: "bot",
    message: "How old is your partner?",
    input: {
      type: "number",
      placeholder: "Type here...",
    },
    trigger: "isSmoker",
    validator: AgeValidator,
  },
  {
    id: "isSmoker",
    type: "bot",

    message:
      "Have you smoked in the last year? Please select ‘yes’ even if it‘s just occasionally. That includes e-cigarettes and other nicotine-based products.",
    options: [
      {
        label: "Yes",
        value: "Yes",
        trigger: SmokerTrigger,
      },
      {
        label: "No",
        value: "No",
        trigger: SmokerTrigger,
      },
    ],
  },
  {
    id: "partner_smoker",
    type: "bot",

    message:
      "Has your partner smoked in the last year? Please select ‘yes’ even if it‘s just occasionally. That includes e-cigarettes and other nicotine-based products.",
    options: [
      {
        label: "Yes",
        value: "Yes",
        trigger: "smoker_description",
      },
      {
        label: "No",
        value: "No",
        trigger: "smoker_description",
      },
    ],
  },
  {
    id: "smoker_description",
    type: "bot",
    trigger: "postcode",

    message:
      "Smokers have a higher chance of claiming on their insurance – which is why all insurers make this question mandatory. It’s really important to be honest, as we don’t want you to be in a situation where the insurer is refusing to pay your claim.",
  },
  {
    id: "postcode",
    type: "bot",

    message:
      "What's your postcode? We estimate your living expenses based on averages in the area",
    input: {
      type: "text",
      placeholder: "Type here...",
    },
    trigger: "employment",
  },
  {
    id: "employment",
    type: "bot",

    message: "What do you do for work?",
    options: [
      {
        label: "Employed",
        value: "Employed",
        trigger: EmploymentTrigger,
      },
      {
        label: "Self Employed",
        value: "SelfEmployed",
        trigger: EmploymentTrigger,
      },
      {
        label: "House Person",
        value: "HousePerson",
        trigger: EmploymentTrigger,
      },
      {
        label: "Retired",
        value: "Retired",
        trigger: NoEmploymentTrigger,
      },
      {
        label: "Not Working",
        value: "Unemployed",
        trigger: NoEmploymentTrigger,
      },
    ],
  },
  {
    id: "partner_employment",
    type: "bot",

    message: "What does your partner do for work?",
    options: [
      {
        label: "Employed",
        value: "Employed",
        trigger: PartnerEmploymentTrigger,
      },
      {
        label: "Self Employed",
        value: "SelfEmployed",
        trigger: PartnerEmploymentTrigger,
      },
      {
        label: "House Person",
        value: "HousePerson",
        trigger: PartnerEmploymentTrigger,
      },
      {
        label: "Retired",
        value: "Retired",
        trigger: PartnerEmploymentTrigger,
      },
      {
        label: "Not Working",
        value: "Unemployed",
        trigger: PartnerEmploymentTrigger,
      },
    ],
  },
  {
    id: "income",
    type: "bot",

    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    trigger: IncomeTrigger,
    message: "What’s your yearly income before tax?",
    validator: NumberValidator,
  },
  {
    id: "partner_income",
    type: "bot",

    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    trigger: PartnerIncomeTrigger,
    message: "What’s your partner's yearly income before tax?",
    validator: NumberValidator,
  },
  {
    id: "occupation",
    type: "bot",

    input: {
      type: "text",
      placeholder: "Type here...",
    },
    message:
      "What’s your main occupation? Please type a keyword, and we'll work it out for you.",
    custom_value: (value) => filterOccupation(value, "occupation"),
    trigger: OccupationTrigger,
  },
  {
    id: "partner_occupation",
    type: "bot",
    input: {
      type: "text",
      placeholder: "Type here...",
    },
    message:
      "What’s your partner's main occupation? Please type a keyword, and we'll work it out for you.",
    custom_value: (value) => filterOccupation(value, "partner_occupation"),
    trigger: "livingStatus",
  },
  {
    id: "livingStatus",
    type: "bot",
    message: "Do you own or rent your home?",

    options: [
      {
        label: "Own with no mortgage",
        value: "Own with no mortgage",
        trigger: "loans",
      },
      {
        label: "Own with mortgage",
        value: "Own with mortgage",
        trigger: "loans",
      },
      {
        label: "Rent",
        value: "Rent",
        trigger: "loans",
      },
      {
        label: "Living with parents",
        value: "Living with parents",
        trigger: "loans",
      },
    ],
  },
  {
    id: "loans",
    type: "bot",
    message:
      "Do you have any outstanding loans or credit agreements? We don't need to know about borrowing less than £900, credit cards, store cards, or overdrafts.",

    options: [
      {
        label: "Yes",
        value: "Yes",
        trigger: "other_monthly_expenses_description",
      },
      {
        label: "No",
        value: "No",
        trigger: "other_monthly_expenses_description",
      },
    ],
  },
  {
    id: "other_monthly_expenses_description",
    type: "bot",
    message: "What are your other monthly expenses?",

    trigger: "otherMonthlyExpenses",
  },
  {
    id: "otherMonthlyExpenses",
    type: "bot",
    message:
      "Think about anything else your household spends money on every month, like travel, food, utility bills and leisure – but don’t include your rent or mortgage payments.",

    trigger: "emergency_funds_description",
    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    validator: NumberValidator,
  },
  {
    id: "emergency_funds_description",
    type: "bot",
    message:
      "Do you have any emergency funds you could use if you were unable to work?",

    trigger: "emergencyFunds",
  },
  {
    id: "emergencyFunds",
    type: "bot",
    message:
      "We're only looking for the kind of savings you can access easily and would be willing to use to support yourself if you were unable to work - not money that's set aside for anything else.",
    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    trigger: "sickPayWeeks",
    validator: NumberValidator,
  },
  {
    id: "sickPayWeeks",
    type: "bot",
    message:
      "How many weeks' full sick pay do you get from your employer? If you're not sure how much you get, stick with the default of 13 weeks.  If you don't get any sick pay, enter 0 weeks.",
    input: {
      type: "number",
      placeholder: "Type here...",
    },

    trigger: "employeeBenefits",
    validator: (value) => NumberValidator,
  },
  {
    id: "employeeBenefits",
    type: "bot",
    message: "Do you get any of these benefits from your employer?",

    options: [
      {
        label: "Critical Illness",
        value: "Critical Illness",
        trigger: "policies",
      },
      {
        label: "Death in Service",
        value: "Death in Service",
        trigger: "policies",
      },
      {
        label: "Income Protection",
        value: "Income Protection",
        trigger: "policies",
      },
      {
        label: "None",
        value: "None",
        trigger: "policies",
      },
      {
        label: "Not Sure",
        value: "Not Sure",
        trigger: "policies",
      },
    ],
  },
  {
    id: "policies",
    type: "bot",
    message:
      "Outside of work, do you currently have any of these policies in place?",

    options: [
      {
        label: "Critical Illness",
        value: "Critical Illness",
        trigger: "applicant1_firstName",
      },
      {
        label: "Life Insurance",
        value: "Life Insurance",
        trigger: "applicant1_firstName",
      },
      {
        label: "Income Protection",
        value: "Income Protection",
        trigger: "applicant1_firstName",
      },
      {
        label: "Family Income Benefit",
        value: "Family Income Benefit",
        trigger: "applicant1_firstName",
      },
      {
        label: "None",
        value: "None",
        trigger: "applicant1_firstName",
      },
      {
        label: "Not Sure",
        value: "Not Sure",
        trigger: "applicant1_firstName",
      },
    ],
  },
  {
    id: "loading_insurance",
    type: "bot",
    message: "Please wait while we are getting your quote...",
    trigger: "insurance_quote",
  },
  {
    id: "insurance_quote",
    type: "bot",
    trigger: "talk_with_advisor",
    component: <InsuranceQuote />,
  },
] as ConversationStep[];
