// import { getApplicantSteps } from "../ApplicantSteps";
import BTLSteps from "./BTLSteps";
// import ExpenditureSteps from "./ExpenditureSteps";
// import MortgageSteps from "./MortgageSteps";
import ProtectionSteps from "../ProtectionSteps";
import HolidayLetSteps from "./HolidayLetSteps";
import HolidayHomeSteps from "./HolidayHomeSteps";
import ModifiedSimpleSteps from "./ModifiedSimpleSteps";
import AppointmentSteps from "../AppointmentSteps";
import DefaultSteps from "../DefaultSteps";
import SwitchSteps from "../SwitchSteps";
import { ConversationStep, Theme } from "../../interfaces";

import IllustrationPage from "../../components/custom/IllustrationPage";
import SendNewLead from "../../components/custom/SendNewLead";
import StripePayment from "../../components/custom/StripePayment";

const FullSteps = (theme: Theme) => {
  if (theme.customFlowData) {
    let mainFlow = theme.customFlowData.flow;
    if (theme.customFlowData.importedFlows) {
      const importedFlows = theme.customFlowData?.importedFlows.map(
        (data: any) => data.flow
      );
      let combinedImportedFlows: any = [];
      importedFlows.forEach((data: any) =>
        data.forEach((_data: any) => combinedImportedFlows.push(_data))
      );
      mainFlow = [...mainFlow, ...combinedImportedFlows];
    }
    return [
      ...mainFlow,
      ...ModifiedSimpleSteps(theme),
      ...mainFlow,
      ...ProtectionSteps,
      ...BTLSteps,
      ...HolidayLetSteps,
      ...HolidayHomeSteps,
      ...AppointmentSteps(theme),
      ...DefaultSteps(theme),
      ...SwitchSteps(theme),

      {
        id: "how_can_we_help",
        type: "bot",
        custom_value: (value: any) => {
          if (value === "Protection Review") {
            return {
              key: "mortgage_type",
              value,
            };
          }

          return {
            key: "how_can_we_help",
            value,
          };
        },
        message: "How can I help?",
        options: [
          {
            label: "Mortgage",
            value: "Mortgage",
            trigger: "mortgage_type",
          },
          {
            label: "Life Insurance",
            value: "Protection Review",
            trigger: "household",
          },
        ],
      },
      {
        id: "pay",
        type: "bot",
        component: <StripePayment />,
      },
      {
        id: "loading_illustration",
        type: "bot",
        message: "Please wait while we scan the market...",
        trigger: "illustration_page",
      },
      {
        id: "illustration_page",
        type: "bot",
        message: "Please wait while we scan the market...",
        trigger: () =>
          theme.apiKey === "d8eccbf3-bfa9-435b-9735-c91641c5446e"
            ? "brief_situation_mortgage"
            : "talk_with_advisor",
        component: <IllustrationPage />,
      },
      {
        id: "brief_situation_mortgage",
        message:
          "Would you like to provide a brief outline of your situation at this stage?",
        type: "bot",
        options: [
          {
            label: "Yes",
            value: "Yes",
            trigger: "share_situation_mortgage",
          },
          {
            label: "No",
            value: "No",
            trigger: "talk_with_advisor",
          },
        ],
      },
      {
        id: "share_situation_mortgage",
        type: "bot",
        message: "",
        input: {
          placeholder: "Type here...",
          type: "textarea-solo",
        },
        trigger: "talk_with_advisor",
      },
      {
        id: "blank_send",
        message: "",
        type: "bot",
        component: <SendNewLead />,
        end: true,
        playAnimation: true,
      },
    ] as ConversationStep[];
  }
  return [
    {
      id: "1",
      type: "bot",
      message: "Hey!",
      trigger: (values: any) => {
        //TODO: This is a coreco specific update. To be remove once custom flows are implemented.

        if (values?.theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341") {
          return "coreco-greeting";
        }

        //TODO: END OF LINE

        if (values?.theme?.version === "short") {
          return "3";
        }

        return "2";
      },
    },
    {
      id: "2",
      type: "bot",
      message: `My name is <strong>${theme?.customization?.botName}</strong> - I'm an AI Assistant.`,
      trigger: "how_can_we_help",
      //trigger: "talk_with_advisor",
    },
    //TODO: This is a coreco specific update. To be remove once custom flows are implemented.
    {
      id: "coreco-greeting",
      type: "bot",
      message: `My name is <strong>${theme?.customization?.botName}</strong> - I'm the Coreco AI Assistant.`,
      trigger: "coreco-extra-greeting",
    },
    {
      id: "coreco-extra-greeting",
      type: "bot",
      message:
        "My job is to ask you a few questions and connect you with a member of the team.",
      trigger: "how_can_we_help",
    },
    //TODO: END OF LINE
    {
      id: "3",
      type: "bot",
      message:
        "My name is <strong>Parker</strong> - I'm an AI Mortgage Assistant.",
      trigger: "mortgage_type",
    },
    {
      id: "how_can_we_help",
      type: "bot",
      custom_value: (value: any) => {
        if (value === "Protection Review") {
          return {
            key: "mortgage_type",
            value,
          };
        }

        return {
          key: "how_can_we_help",
          value,
        };
      },
      message: "How can I help?",
      options: [
        {
          label: "Mortgage",
          value: "Mortgage",
          trigger: "mortgage_type",
        },
        {
          label: "Life Insurance",
          value: "Protection Review",
          trigger: "household",
        },
      ],
    },
    {
      id: "loading_illustration",
      type: "bot",
      message: "Please wait while we scan the market...",
      trigger: "illustration_page",
    },
    {
      id: "illustration_page",
      type: "bot",
      message: "Please wait while we scan the market...",
      trigger: "talk_with_advisor",
      component: <IllustrationPage />,
    },
    ...ProtectionSteps,
    ...BTLSteps,
    ...HolidayLetSteps,
    ...HolidayHomeSteps,
    ...ModifiedSimpleSteps(theme),
    ...AppointmentSteps(theme),
    ...DefaultSteps(theme),
    ...SwitchSteps(theme),
  ] as ConversationStep[];
};

export default FullSteps;
