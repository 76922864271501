import React, { useEffect } from "react";
import cn from "classnames";
import { ChatMessageCustomInterface } from "../interfaces";

const ChatMessageCustom: React.FC<ChatMessageCustomInterface> = ({
  step,
  values,
  hideAvatar,
  triggerNextStep,
  setDataValues,
  scrollToBottom,
  disableScrollToBottom,
  currentStep,
}) => {
  const { theme, component, type } = step!;

  useEffect(() => {
    if (!disableScrollToBottom && step === currentStep) {
      scrollToBottom!();
    }
  });

  const renderComponent = () => {
    return React.cloneElement(component!, {
      values,
      triggerNextStep,
      step,
      setDataValues,
      scrollToBottom,
    });
  };

  return (
    <div className={`conversation-message conversation-message-${type}`}>
      {!hideAvatar && (
        <div
          className={cn("conversation-message-avatar")}
          style={{
            backgroundImage: `url("${theme?.customization?.botAvatar}")`,
          }}
        />
      )}
      {renderComponent()}
    </div>
  );
};

export default React.memo(ChatMessageCustom);
