import { ConversationStep } from "../interfaces";
import { numberWithCommas } from "../util";

import * as _ from "lodash";

export const getApplicantSteps = (applicantNumber: number) => {
  return [
    {
      id: `applicant${applicantNumber}_info`,
      type: "bot",
      message: `Let me just ask some personal info of Applicant ${applicantNumber}`,

      trigger: `applicant${applicantNumber}_title`,
    },
    {
      id: `applicant${applicantNumber}_title`,
      type: "bot",
      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } title?`,
      options: [
        {
          label: "Mr",
          value: "Mr",
          trigger: `applicant${applicantNumber}_firstName`,
        },
        {
          label: "Mrs",
          value: "Mrs",
          trigger: `applicant${applicantNumber}_firstName`,
        },
        {
          label: "Miss",
          value: "Miss",
          trigger: `applicant${applicantNumber}_firstName`,
        },
        {
          label: "Ms",
          value: "Ms",
          trigger: `applicant${applicantNumber}_firstName`,
        },
        {
          label: "Dr",
          value: "Dr",
          trigger: `applicant${applicantNumber}_firstName`,
        },
        {
          label: "Other",
          value: "Other",
          trigger: `applicant${applicantNumber}_firstName`,
        },
      ],
    },
    {
      id: `applicant${applicantNumber}_firstName`,
      disable: (values) =>
        !_.isEmpty(values[`applicant${applicantNumber}_firstName`]),
      type: "bot",
      input: {
        type: "text",
        placeholder: "Type here...",
      },
      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } first name?`,
      trigger: `applicant${applicantNumber}_lastName`,
    },
    {
      id: `applicant${applicantNumber}_lastName`,
      type: "bot",
      disable: (values) =>
        !_.isEmpty(values[`applicant${applicantNumber}_lastName`]),
      input: {
        type: "text",
        placeholder: "Type here...",
      },
      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } last name?`,

      trigger: (values: any) => {
        if (
          values?.mortgage_type === "Buy to Let" ||
          values?.mortgage_type === "Protection Review" ||
          values?.mortgage_type === "Holiday Let" ||
          values?.mortgage_type === "Holiday Home" ||
          values?.theme?.version === "property" ||
          values?.theme?.version === "wealth" ||
          values?.theme?.version === "rightmove"
        ) {
          return `applicant${applicantNumber}_email`;
        }

        return `applicant${applicantNumber}_dependents`;
      },
    },
    {
      id: `applicant${applicantNumber}_dependents`,
      type: "bot",
      message: `How many dependents does ${
        applicantNumber === 1 ? "you" : "your partner"
      } have?`,

      options: [
        {
          label: "0",
          value: "0",
          trigger: `applicant${applicantNumber}_age`,
        },
        {
          label: "1",
          value: "1",
          trigger: `applicant${applicantNumber}_age`,
        },
        {
          label: "2",
          value: "2",
          trigger: `applicant${applicantNumber}_age`,
        },
        {
          label: "3",
          value: "3",
          trigger: `applicant${applicantNumber}_age`,
        },
        {
          label: "4",
          value: "4",
          trigger: `applicant${applicantNumber}_age`,
        },
        {
          label: "5+",
          value: "5+",
          trigger: `applicant${applicantNumber}_age`,
        },
      ],
    },
    {
      id: `applicant${applicantNumber}_age`,
      type: "bot",
      input: {
        type: "number",
        placeholder: "Type here...",
      },
      message: `How old are ${applicantNumber === 1 ? "you" : "your partner"}?`,
      validator: (value: any) => {
        if (isNaN(value)) {
          return "Please enter a valid input";
        }

        const age = parseInt(value);
        if (age < 18 || age > 70) {
          return "To apply for a mortgage, you must be between 18 years and 70 years old";
        }

        return true;
      },
      trigger: (values: any) => {
        if (
          values?.mortgage_applicant === "Joint" ||
          values?.mortgage_applicant === "Company" ||
          values?.theme?.version === "wealth" ||
          values?.theme?.version === "rightmove"
        ) {
          //already filled applicant2
          if (!values?.applicant2_title) {
            return "applicant2_info";
          }
        }
        return "applicant1_employmentStatus";
      },
    },
    {
      id: `applicant${applicantNumber}_employmentStatus`,
      type: "bot",

      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } employment status?`,
      options: [
        {
          label: "Employed",
          value: "Employed",
          trigger: `applicant${applicantNumber}_annualSalary`,
        },
        {
          label: "Self Employed",
          value: "Self_Employed",
          trigger: `applicant${applicantNumber}_annualSalary`,
        },
      ],
    },
    {
      id: `applicant${applicantNumber}_annualSalary`,
      type: "bot",

      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } earnings before tax? (Include the annual salary and any extras; like bonuses, overtime or commission)`,
      input: {
        type: "number",
        placeholder: "Type here...",
        startAdornment: "£",
      },
      formatter: numberWithCommas,
      trigger: (values: any) => {
        if (values?.mortgage_type === "Buy to Let") {
          return "btl_mortgage";
        }

        if (
          values?.mortgage_type === "Holiday Let" ||
          values?.mortgage_type === "Holiday Home" ||
          values?.theme?.version === "wealth" ||
          values?.theme?.version === "rightmove"
        ) {
          return `applicant${applicantNumber}_firstName`;
        }

        return `applicant${applicantNumber}_address`;
      },
      validator: (value: any) => {
        if (isNaN(value)) {
          return "Please enter a valid input";
        }

        return true;
      },
    },
    {
      id: `applicant${applicantNumber}_address`,
      type: "bot",

      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } address?`,
      input: {
        type: "text",
        placeholder: "Type here...",
      },
      trigger: `applicant${applicantNumber}_email`,
    },
    {
      id: `applicant${applicantNumber}_email`,
      type: "bot",
      disable: (values) =>
        !_.isEmpty(values[`applicant${applicantNumber}_email`]),
      input: {
        type: "email",
        placeholder: "Type here...",
      },

      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } email?`,
      trigger: (values: any) => {
        if (
          values?.mortgage_type === "Protection Review" &&
          values?.partner_covered === "Yes" &&
          !values?.applicant2_firstName
        ) {
          return "applicant2_firstName";
        }

        if (
          values?.mortgage_type === "Protection Review" ||
          values?.theme?.version === "protection"
        ) {
          return "loading_insurance";
        }

        if (
          values?.mortgage_type === "Buy to Let" ||
          values?.mortgage_type === "Holiday Let" ||
          values?.mortgage_type === "Holiday Home"
        ) {
          return `loading_illustration`;
        }

        return `applicant${applicantNumber}_contactNumber`;
      },
    },
    {
      id: `applicant${applicantNumber}_contactNumber`,
      disable: (values) =>
        !_.isEmpty(values[`applicant${applicantNumber}_contactNumber`]),
      type: "bot",
      input: {
        type: "text",
        placeholder: "Type here...",
      },

      message: `What is your ${
        applicantNumber === 1 ? "" : "partner's"
      } contact number?`,
      trigger: (values: any) => {
        if (values?.theme?.version === "property") {
          return "property_video_result";
        }

        if (
          values?.mortgage_applicant === "Joint" ||
          values?.mortgage_applicant === "Company"
        ) {
          //already filled applicant2
          if (!values?.applicant2_employmentStatus) {
            return `applicant2_employmentStatus`;
          }
        }
        if (values?.theme?.version === "wealth") {
          return "policy_inquiry";
        }
        if (values?.theme.version === "rightmove") {
          return "opt-in";
        }

        return "loading_illustration";
      },
    },
  ] as ConversationStep[];
};
