const criterias = [
  "It is acceptable for applicants be Electronically Identified by the Intermediary, instead of providing Certified documents",
  "Which accountant's qualifications are acceptable",
  "Will an accountant's Certificate be required for Self Employed applicants",
  "Can an accountant's projection be used for income",
  "Will an Accountant's reference be acceptable instead of SA302s or an Accountant's certificate",
  "Can the broker's advice Fee be added to the loan amount",
  "Can the product arrangement fee be added to the",
  "Which fees can be added to the loan amount",
  "Which fees must be added to the Loan amount",
  "Can additional borrowing to the main loan be considered",
  "On completion of a new mortgage will an existing mortgage remaining with the lender be acceptable",
  "Is Adoption Allowance an acceptable source of income",
  "Is an Intermediary's own application acceptable",
  "Is an Affordability Calculator used for to assess the maximum loan",
  "Which Affordable Housing schemes are acceptable",
  "How will applicants be assessed where one retires before the end of the mortgage term",
  "Is income from Agency Work acceptable",
  "Are properties with agricultural ties suitable security to lend against",
  "Is it acceptable for the property to be used for Air BnB",
  "Are applicants who's sole income is from Benefits be considered",
  "Are applicants who will continue to live in tied accommodation on completion of the mortgage be considered",
  "Can a person with Power of Attorney act on the applicant's behalf",
  "Can an applicant with Diplomatic Immunity be considered",
  "Is income paid in a Foreign Currency acceptable",
  "Can applicants who's main source or income is from Property Development be considered",
  "Can an Architect's Certificate be used instead of a Warranty for the property",
  "Is a Purchasing via an Assignable Contract acceptable",
  "Is Attendance Allowance an acceptable source of income",
  "Would a purchase where the vendor has owned the property for less than 6 months be considered",
  "Is using an Overdraft Facility acceptable",
  "Are Bank Statement required for assessing an application",
  "When can a discharged bankrupt applicant be considered",
  "Is a Barn Conversion property suitable security",
  "Is a Basement Flat suitable security",
  "Can a purchase which is below the market value of the property be considered",
  "Is Bereavement Allowance income accepted",
  "Are BISF Construction properties acceptable security",
  "What percentage of regular bonuses can be considered and over what period",
  "What percentage of guaranteed bonuses can be considered and over what period",
  "Is there an ability to Borrow Back on the mortgage products",
  "Can a British National working abroad or offshore be considered",
  "Can Cash Incentive from a builder be accepted",
  "Can a Builder Gifted Deposit be used",
  "Are Builder Part Exchange transactions acceptable",
  "Is income from Bursaries acceptable",
  "Can Income be used from applicants that are a Flight Attendant or Cabin Crew",
  "Is the mortgage available to be taken out on a Capital and Interest Only Basis",
  "Can capital raising be considered when remortgaging",
  "Is capital raising allowed for the purpose of purchasing a Buy to Let Property",
  "Is capital raising for Business Purposes allowed",
  "Will capital raising be considered for Debt Consolidation",
  "Can capital raising to Purchase Land be considered",
  "Is Car Allowance an acceptable source of income",
  "Can a Carer's Allowance be considered as a source of income",
  "Is a Drawdown / Cash Reserve Facility available with any of the products",
  "What are the requirements for certifying documents",
  "Is Child Benefit an acceptable source of income",
  "Will Child Maintenance payments affect any form of affordability assessment",
  "Can Non Court ordered Child Maintence payments be used as an acceptable form of income",
  "Can Child Tax Credits be used as an acceptable form of income",
  "Will Childcare cost affect any form of affordability assessment",
  "Will Childcare Vouchers affect any form of affordability assessment",
  "Are CIS Contractors and income from this employment acceptable",
  "Is income from Commission or Profit Related pay acceptable, and if so what can be used",
  "Can applicants that receive income on a commission only basis be considered",
  "Can income from a Company or Occupational Pension be considered",
  "Can a Company Director who's position is based overseas be considered for a mortgage",
  "Can a transaction as under value (Concessionary Purchase, Deed of Gift or Inter-family Sale) be considered",
  "If there the potential to grant a Consent to Let on a residential property",
  "Can Constant Attendance Allowance be considered as an income source",
  "What Non Standard Construction types are acceptable",
  "Are applications classed as Consumer Buy to Let acceptable",
  "Are Contract Workers and income from this type of employment acceptable",
  "Can applicants who have a Conviction be considered",
  "Can applicants with unsatisfied / outstanding County Court Judgements (CCJs) be considered and what is acceptable",
  "Can applicants with Satisfied County Court Judgements (CCJs) be considered and what is acceptable",
  "Can Court ordered Child Maintenance payments be used as an acceptable form of income",
  "Are arrears or missed payments on Credit Cards / Store Cards / Mail Order catalogues considered.",
  "Will the credit card balance or monthly payment be used for assessing affordability",
  "Will credit commitments to be repaid be disregarded from affordability assessment and is so what is the policy",
  "Will a credit commitment with less that 12 months remaining be considered when assessing affordability",
  "Which credit reference agency is used",
  "Are products available to help applicants repair their credit profile",
  "Are applicants Credit Scored rather than manually underwritten",
  "Are applicants Manually Underwritten rather than credit scored",
  "Can an applicant with a criminal record be considered",
  "Can a remortgage for a property purchased or acquired in the last 6 months be considered",
  "Can an application be considered where there is an ongoing / current Debt Management Plan",
  "How long does a Debt Management plan have to be discharged / completed before it can be considered",
  "How long does a Debt Relief Order have to be discharged / completed before it can be considered",
  "Can an application be considered where there is an ongoing / current Debt Relief Order",
  "Can applicants with satisfied Defaults be considered and what is acceptable",
  "Can applicants with unsatisfied / outstanding Defaults be considered and what is acceptable",
  "Is a New Build property acceptable security and what is the definition of a New Build House",
  "Is a New Build property acceptable security and what is the definition of a New Build Flat",
  "Is the purchase / remortgage of a property for a Dependant Relative acceptable",
  "Can dependents be ignored for affordability purposes",
  "is it acceptable for the deposit to come from a Limited Company",
  "What sources of deposit are acceptable",
  "What sources of deposit will be unacceptable",
  "Can the date the direct debit payment is taken be chosen and does it have to come from the Applicants bank account",
  "Can income from Director's Dividends be considered when assessing affordability",
  "Can a Director's Loan be considered when assessing affordability",
  "Can Disability Living Allowance Benefit be considered when assessing affordability",
  "Can properties that are subject to a section 106 restrictive covenant or are a Discounted Market Sale be acceptable",
  "Can an application which has an element of a distressed sale be considered",
  "Can income from Dividends received in retirement be considered when assessing affordability",
  "Is Down Sizing an acceptable repayment vehicle when taking Interest Only as a repayment method",
  "Can the mortgage be redeemed within the product's initial benefit period without an early repayment charge",
  "Can payslips be used as evidence of employment and can anything else be used",
  "Can future pay rises be considered when assessing affordability and what evidence is required",
  "What percentage shareholding in a business / partnership is required to be classed as Self Employed",
  "Can income from an Employee Benefit Trust be considered when assessing income",
  "Is an employer's reference required for employed applicants",
  "Can income from Employment and Support Allowance be considered when assessing affordability",
  "If there a minimum time applicants have to be continuously employed",
  "If there a minimum time applicants have to be working in their current employment",
  "Is an Endowment an acceptable repayment vehicle when taking Interest Only as a repayment method",
  "Can Expatriates with adverse credit be considered",
  "Are Expatriates residing in British Colonies where a UK Company is registered acceptable",
  "Are applications from as Expatriates on a Consumer Buy To Let basis acceptable",
  "Are there restrictions on which Country Expatriates are from",
  "Can applications for Expatriates returning to the UK imminently be considered",
  "Can applications for Expatriates who are employed by a Multi National returning to the UK imminently be considered",
  "Are Expatriates First Time Buyers considered",
  "Is there a Maximum Term for Expatriates",
  "Is there a Minimum Term for Expatriates",
  "Is there a maximum period of time Expatriates can be out of the UK for",
  "Is there a minimum and maximum income for Expatriates",
  "Is there a minimum and maximum loan amount for Expatriates",
  "Is there a minimum and maximum age for Expatriates",
  "Is there a minimum and maximum property value for Expatriates",
  "Is a New Build Flat considered acceptable security for Expatriate applicants",
  "Is a New Build House considered acceptable security for Expatriate applicants",
  "Are Expatriate applicants with a PO Box address acceptable",
  "Ia s remortgage for Debt Consolidation acceptable for Expatriates",
  "Are applications from retired Expatriates acceptable",
  "Can applications from a retired expatriates be considered",
  "Is an Ex Council House / Flat acceptable security for an Expatriate",
  "Is it acceptable for existing borrowers to stay on the same criteria rather than any new policy like Interest Only, Porting, Additional Borrowing etc",
  "Is it acceptable to take out or have an existing second charge on the property",
  "Are Expatriates acceptable applicants",
  "Can expenses be considered as income when assessing affordability",
  "Can the Mortgage Offer be extended",
  "Are there specific products that allow family members to assist with the deposit for the mortgage",
  "Is a gifted deposit (NOT from a family member) acceptable",
  "Are family gifted deposits acceptable",
  "Are First Time Buyers acceptable and what is the definition of a FTB",
  "Is income from a Fixed Term contract acceptable",
  "Can a flat above or near commercial premises be considered suitable security and are there any restrictions",
  "Is a property with an element of Flying Freehold acceptable and what percentage can be considered",
  "Is lending available for a Forces Help To Buy (HTB) Scheme",
  "Are Forces Personnel and their income acceptable",
  "Can income received in a Foreign Currency be used for assessing affordability",
  "Are former Houses of Multiple Occupation (HMO) acceptable security and is so are there any restrictions",
  "Is income from Foster Caring acceptable for assessing affordability",
  "Are free legal offered as an incentive and what are the conditions for these",
  "Is a Freehold Flat / Maisonette acceptable security (NOT Leasehold with a share of the Freehold)",
  "Can an application be considered without there being Full Vacant Possession on completion",
  "Can Existing Borrowers apply for a Further Advance",
  "Can applicants with a history of Gambling or looking to capital raise to repay gambling debts be considered",
  "Can properties that have doubling ground rent be considered and are there any restrictions",
  "Can properties that have escalating ground rent be considered and are there any restrictions",
  "Can properties that have increasing ground rent based on the property value be considered and are there any restrictions",
  "Can properties that have ground rent linked to RPI be considered and are there any restrictions",
  "Can Guaranteed Income Payment for Forces be considered when assessing affordability",
  "Can Guarantors be accepted and are there any restrictions",
  "Is Guardian Allowance income acceptable for assessing affordability",
  "Is a Hard or soft Footprint recorded on the applicant's credit file when a DIP is carried out",
  "Are applications for a Help To Buy Equity Loan scheme acceptable and are there any restrictions",
  "Are remortgages to repay the Help To Buy Equity Share Loan in full or partially acceptable",
  "Is a Higher Lending Charge Payable and is so what are the conditions for applying this fee",
  "Can an application for a Holiday Home be considered and are there any restrictions",
  "Can Housing Benefit be used when assessing affordability",
  "Can income from a lodger be considered when assessing affordability",
  "Can income from Trust Funds be considered when assessing affordability",
  "What income multipliers are used when assessing affordability and the maximum loan available",
  "Can income support be considered for assessing affordability",
  "Can an application be considered where there is an ongoing / current Individual Voluntary Arrangement (IVA)",
  "How long does a Individual Voluntary Arrangement (IVA) have to be discharged / completed to be considered",
  "Can Industrial Injuries Disability Benefit be considered when assessing affordability",
  "Is Inheritance an acceptable repayment vehicle when taking Interest Only as a repayment method",
  "Is Interest Only an acceptable repayment method",
  "What are the acceptable Repayment vehicle and conditions when taking out an Interest Only Mortgage",
  "Are Investment Bonds an acceptable repayment vehicle when taking Interest Only as a Repayment Method",
  "Can Investment income be considered when assessing affordability",
  "Is an ISA an acceptable repayment Vehicle when taking Interest Only as a Repayment Method",
  "Are IT Contractors an acceptable employment type and how is the income calculated",
  "Are properties with Japanese Knotweed considered acceptable security and what are the restrictions",
  "Is Job seekers Allowance and acceptable source of income when assessing affordability",
  "Is an application for joint borrowers, where only one will be the legal owner be acceptable and under what circumstances",
  "Are applications on the Key Workers Scheme Acceptable",
  "Can an application be considered on a property next to one already owned by the applicant",
  "Can loans over £500,\n        000 be considered",
  "Is lending on properties in England acceptable",
  "Is lending on properties in Northern Ireland Acceptable",
  "Is lending on properties in Scotland acceptable",
  "Is lending on properties in Wales acceptable",
  "Is lending on properties in the Scottish Isles acceptable",
  "Are there limits to the maximum exposure in a development, block of flats or region",
  "Are applicants acceptable where the term of the mortgage takes them into retirement and if so what are the conditions",
  "Is it acceptable to lend to a Limited Company",
  "Is it acceptable to lend to Trustees",
  "Can applicant who have changed self employment status from a Limited Company to a Sole Trader be considered",
  "Are applicants in a Limited Liability Partnership acceptable",
  "Are with Live Work/Usage restrictions acceptable security",
  "Is a LTI cap applied when assessing affordability",
  "Is a purchase through the Local Authority Grant Scheme Acceptable",
  "is it permittable to Let a Room or take a Lodger in the property",
  "Is London Weighting or Large Town Allowance acceptable income when assessing affordability",
  "Can Maintenance received via a private arrangement be considered when assessing affordability",
  "Is an application for married applicants in a single name acceptable",
  "Can applicants on Maternity / Paternity Leave be considered and if so can their income be used for assessing affordability",
  "What is the acceptable Maximum and Minimum Age for applicants at application",
  "What is the acceptable Maximum Age for applicants at the end of the mortgage term",
  "What is the maximum Loan amount",
  "Is there a maximum LTV for a pound for pound remortgage",
  "Is a New Build House / Flat acceptable and is there a maximum LTV",
  "Are Ex Council Houses and Flats acceptable Security and would there be any restrictions",
  "Is a Right to Buy Purchase acceptable and what would the maximum LTV be",
  "Is a Shared Equity Purchase acceptable and what would the  maximum LTV be",
  "Is a Shared Ownership Purchase acceptable and what would the maximum LTV be",
  "Are Second Hand Flats acceptable security and are there any further restrictions",
  "Are Second Hand Houses acceptable security and are there any further restrictions",
  "Are remortgages for properties acquired through Right to Buy acceptable if they are still in the Pre Emption period.",
  "Is a remortgage of a property under the Shared Equity Scheme acceptable and what would the maximum LTV be",
  "Is a remortgage of a property under the Shared Ownership Scheme acceptable and what is the maximum LTV available",
  "Are Studio Flats acceptable security and are there any restrictions",
  "Are Expatriates acceptable on the same terms and LTV as other applicants",
  "Is it acceptable to capital raise against an unencumbered property",
  "Is capital raising on a property owned outright acceptable as a Remortgage",
  "Is capital raising for Debt Consolidation acceptable and are there any restrictions",
  "Is capital raising for Business Purposes acceptable and are there any restrictions",
  "Is capital raising for Home Improvements acceptable and are there any restrictions",
  "Is capital raising for the Purchase of Equity Share Acceptable and are there any restrictions",
  "Is capital raising acceptable to be used to Purchase a BTL property",
  "Is it acceptable for First Time Buyers (FTB) to borrow up to the standard maximum LTV",
  "What is the maximum LTV for an Interest Only Repayment method mortgage",
  "What is the maximum LTV for Large Loans",
  "What is the maximum LTV for a part and part / split repayment method mortgage",
  "Are more than 2 applicants acceptable and can all incomes be used for assessing affordability",
  "Is there a maximum and minimum property value and does it depend on the type of property",
  "What is the maximum term for a capital and interest repayment method",
  "Is there a minimum income for single / joint applications on a Capital and Interest repayment",
  "Is there a minimum income for single / joint applications on an Interest Only repayment",
  "Is there a minimum income for single / joint applications on a Part and Part / Split repayment",
  "Is there a minimum loan amount and does it vary depending on the borrower or product type",
  "Is there a minimum floor area in square meters that is acceptable for a Studio Flat",
  "What is the minimum mortgage term that is acceptable",
  "Can applicants with late or missed payments on credit commitment be accepted",
  "Can properties that have mixed use or have a Part Commercial element be considered suitable security",
  "Are applicants with a default or missed payments on a mobile or other communication contracts acceptable",
  "Can Mortgage Declarations and Direct Debits be accepted with an electronic Signature",
  "Is an extension to the mortgage offer acceptable for New Build properties and for how long",
  "How long is the mortgage offer valid for",
  "Can applicants with arrears on a mortgage / secured loan be considered and what are the restrictions",
  "Can a Mortgage Subsidy be considered as income when assessing affordability",
  "Can a mortgage term of Years and Months be considered",
  "Is a property construction of Mundic Block acceptable and what grade can be considered",
  "Is it acceptable to use the Net Profit as income for a Director of their Limited Company",
  "Is the LTV restricted where the property is a New Build Flat",
  "Is the LTV restricted where the property is a New Build House",
  "Can the purchase of a New Build Property off Plan be considered",
  "Are New Build Properties incentives acceptable and under what terms",
  "What warranties and warranty providers are acceptable for New Build Properties",
  "Are applications where there will be occupiers aged over 17 at the property acceptable",
  "Can applications from Non EU, EEA Non UK Foreign Nationals be accepted and under what conditions",
  "Is it acceptable to do a staggered and Non-Simultaneous sale and completion",
  "Can properties deemed as being on Non-Standard construction be considered as suitable security",
  "Are Non UK Tax Payers acceptable",
  "Are high rise blocks of flats acceptable and what is the maximum number of floors",
  "Can nursing bank staff income be considered for assessing affordability",
  "Do products has an Offset Mortgage option",
  "Can a mortgage be considered where an Overage Clause / Agreement is in place on the security address",
  "Do products have an overpayment facility and under what conditions",
  "Can Non Regular / Fluctuating Overtime be considered when assessing affordability",
  "Can Regular / Guaranteed Overtime be considered when assessing affordability",
  "Is income paid in Cash acceptable and what extra requirements will be needed",
  "What is the percentage acceptable per repayment method on Part and Part / Split",
  "Can applicants who have taken a Payday loan in the past be considered",
  "Are payment holidays allowed to be taken",
  "What are the requirements for Payslips as evidence of income",
  "Can a Pension be considered as an acceptable repayment Vehicle for an Interest Only Repayment Method",
  "Can Pension Contributions be ignored for assessing affordability",
  "Can Private Pension Credits be used for assessing affordability",
  "Are Period / Listed / Grade properties acceptable security",
  "Can Health Insurance be considered when assessing affordability",
  "Can Personal Finance provided into a Business be ignored when assessing affordability",
  "Can Personal Independent Payment Benefit be considered for assessing affordability",
  "Is Piece Work an acceptable employment type and income source",
  "Is a property acceptable that has Restrictive or Unusual Planning permissions",
  "Do any or all products allow them to be ported to another property",
  "Is lending in all Postcode Areas acceptable or are their restrictions",
  "Are Pre-Reinforced Concrete properties acceptable security and what certificates are required",
  "Is Price Work an acceptable employment type and income source",
  "Can a Private Sale Purchase with no Estate Agents be considered an acceptable transaction",
  "Are applicants on a Probation Period acceptable and under what conditions",
  "Can a product split on the same mortgage be considered",
  "Can a Product Transfer application via an intermediary be accepted for existing borrowers",
  "Can Professional Landlords be considered and are there any restrictions",
  "Can Professional Sports Persons / Athletes be considered and what requirements would be needed",
  "Is an application without Proof of Deposit acceptable",
  "Can applications without Proof of Personal ID be accepted",
  "Can applications without proof of address / residence be accepted",
  "Is an application to purchase a property at auction acceptable",
  "Can applications to purchase a property via an Investment / Property Club be considered",
  "Are properties with an Annex acceptable security",
  "Are properties with Outbuildings acceptable security",
  "Are properties with substantial land acceptable security and what is the maximum acreage",
  "Can the purchase of a property for a Family Member to reside in be considered",
  "Is a purchase from a Limited Company acceptable",
  "Is a Re-ispection fee for a New Build property payable",
  "Can Reduced Earning Allowance income be used for assessing affordability",
  "If income has been reduced for a specific period can this be used to assess affordability",
  "Are Regulated Buy to Let applications acceptable",
  "Can a remortgage to Transfer Equity / Divorce settlement be considered",
  "Is Capital Raising as part of a remortgage acceptable",
  "Can a remortgage be considered to repay a Bridging Loan",
  "Can applicants with Rent Arrears be considered",
  "Are applications for a purchase through the Rent to Buy Schemes acceptable",
  "Can Rental Income be considered when assessing affordability",
  "Can applicants who have had a property Repossessed / Voluntary Surrender be considered",
  "Can applicants who have been Resident in the UK less than 3 years be considered",
  "Can an application be considered when another Residential Mortgage will remain on completion",
  "For Self Employed applicants can Retained Profit in a business be used when assessing affordability",
  "What repayment vehicles / strategies are acceptable for Retirement Interest Only Lending",
  "Can Non Guaranteed Future income be used for assessing affordability for Retirement Interest Only",
  "Can enhanced terms for Retirement Interest Only mortgages be considered where there is a Last Power of Attorney",
  "Can Benefit Income be considered when assessing affordability for joint applicants - Retirement Interest Only",
  "Can Family Assistance Income be considered when assessing affordability for joint applicants - Retirement Interest Only",
  "Can Investment Income be considered when assessing affordability for joint applicants - Retirement Interest Only",
  "Can Life Cover projected proceeds be considered when assessing affordability for joint applicants - Retirement Interest Only",
  "Can Pension income be considered when assessing affordability for joint applicants - Retirement Interest Only",
  "Can Pension income be considered when assessing affordability for joint applicants - Retirement Interest Only",
  "What is the maximum age at application for Retirement Interest Only",
  "What is the maximum age at  the end of the mortgage term for Retirement Interest Only",
  "What is the maximum Loan amount for Retirement Interest Only",
  "What is the maximum LTV available for Retirement Interest Only",
  "Can a like for like remortgage be considered for Retirement Interest Only",
  "Can a remortgage with capital raising for Retirement Interest Only Lending be considered and what is the max LTV",
  "What is the maximum term for a Retirement Interest Only Mortgage",
  "What is the minimum age at application for Retirement Interest Only lending",
  "What is the minimum Loan amount for Retirement Interest Only lending",
  "What is the minimum equity required Retirement Interest Only lending",
  "is there a minimum acceptable income for Retirement Interest Only Lending",
  "What is the minimum property value that can be considered for Retirement Interest Only Lending",
  "Is there an acceptable minimum term for Retirement Interest Only Lending",
  "Can Retirement Interest Only Lending be considered on any New Business products",
  "Is a No Negative Equity Guarantee offered for Retirement Interest Only Lending",
  "Can a purchase through the Right to Buy Scheme be considered",
  "Can Salary Sacrifice deductions be ignored from the affordability assessment",
  "Can the sale of a Foreign Property or Asset be considered as a Repayment Vehicle for Interest Only",
  "Can the sale of the Residential property be considered as a Repayment Vehicle for Interest Only",
  "Can the sale of other Assets be considered as a Repayment Vehicle for Interest Only",
  "Can the sale of the Mortgaged Property be considered as a Repayment Vehicle for Interest Only",
  "Can the sale of an Investment / Buy to Let Property be considered as a Repayment Vehicle for Interest Only",
  "Can the Savings be considered as a repayment Vehicle for Interest Only",
  "Can the converting to a Capital and Interest / Repayment later on be considered as a repayment Vehicle for Interest Only",
  "Can seasonal employment and the income be considered when assessing affordability",
  "Will an application be considered if the second applicant has no income",
  "Will an application for a Second Home / Pied a Terre be considered and are there any restriction",
  "Can income from a second job be considered when assessing affordability",
  "Can application for a Self Build Property be considered",
  "Are Self Employed applicants with 1 Years Accounts acceptable",
  "Are Self Employed applicants with declining Profits acceptable",
  "Can Service Charges / Ground Rent and Maintenance cost be ignored when assessing affordability",
  "Are applications on the Shared Ownership Scheme acceptable",
  "Can a remortgage to staircase / purchase a bigger share of the property be considered",
  "Are products available that are Islamic / Sharia compliant available",
  "Can Shift Allowance income be considered when assessing affordability",
  "Can short term lending be considered",
  "Are applicants on a Short Term Renewable contract acceptable",
  "Are Self Employed Sole Trader Applicants acceptable",
  "Can a Sole Trader who has turned into a Limited Company be considered and what requirements need to be met",
  "Can Income for Special Duties be considered when assessing affordability",
  "Is there specific products or criteria for Applicants classed as Professionals",
  "Is it acceptable to allow the Title Deeds to be split on completion",
  "Can Spousal Maintenance be considered acceptable income when assessing affordability",
  "Is State Pension Benefit acceptable income when assessing affordability",
  "Are properties made of Steel Framed Construction acceptable",
  "Can Stocks and Shares be considered as a suitable repayment vehicle for Interest Only",
  "Can a Student Loan be ignored when assessing affordability",
  "Can an application that involves a Sub-Sales / Reassignment of Contracts be considered",
  "Are Subcontractors an acceptable type of employment and what requirements need to be met",
  "Is there specific criteria for assessing applicants that are Teachers",
  "Is Temporary Work / Employment acceptable and can it be used when assessing affordability",
  "Are Timber Framed properties an acceptable construction type and are there any restrictions",
  "Can applicants on a VISA be considered and what are the acceptable requirements",
  "Can unlimited Aggregate Borrowing with the Lender / Group be considered",
  "Can Self Employed Applicants with less that 2 years Trading be considered",
  "Can Travel Allowance income be considered when assessing affordability",
  "Can expenses for travel be ignored when assessing affordability",
  "Can a mortgage be considered in joint names with only 1 named on the Deeds",
  "Are properties with Cross Leases or Tyneside Flats acceptable",
  "Can Applicants with less than 3 years UK residency be considered.",
  "Are Applicants who are Self Employed under an Umbrella Company and their income acceptable",
  "Are Free legal available for an unencumber properties and is this treated as a Purchase or Remortgage",
  "Can Unit Trusts or OEIC be considered as a suitable repayment vehicle for Interest Only",
  "Can Universal Credit be considered as income when assessing affordability",
  "Can applicants who have a history of Unsecured Loan Arrears be considered",
  "Are applicants with missed or late payments on Utility Bills considered",
  "Are the last available accounts which are dated over 1 year ago acceptable",
  "Can a Homebuyers or Structural / Full Building Valuation Report be arranged via the lender",
  "Is a Vendor Gifted Deposit acceptable",
  "Can Vested Share Income be considered when assessing affordability",
  "Can a War Widow's Pension income be considered when assessing affordability",
  "Can a Widow's Pension income be considered when assessing affordability",
  "Can a Widowed Parent Allowance income be considered when assessing affordability",
  "Are Wimpey No Fines / Laing Easi Form concrete construction properties considered",
  "Can applicants employed in a family business be considered and what proof of income is required",
  "Can Working Tax Credit Benefits be considered when assessing affordability",
  "Are applicants on a Zero Hour Contact and the income received acceptable",
  "Can an application be considered without an Assured Shorthold Tenancy Agreement (AST)",
  "Can a Mortgage Offer be issues without the need for Coal Mining Report",
  "Can applications classed as Consumer Buy To Let acceptable",
  "Can properties to be let to a Company / Corporate basis be considered",
  "What is the definition of a Portfolio Landlord and can they be considered",
  "Can a property with an Energy Rating below E be considered",
  "Are Expatriates First Time Landlords acceptable",
  "Are applications from an Expatriate for a Holiday Let acceptable",
  "Are applications from an Expatriate for a House of Multiple Occupation acceptable",
  "Can Expatriates classed as Professional Portfolio Landlords acceptable",
  "Are Experienced Landlords considered and how are they defined",
  "Is a Farm or Small Holding acceptable security",
  "Where one applicant is a First Time Buyer and the other a Home Mover how is the application viewed",
  "Can applicants who are First Time Landlords be considered",
  "Are properties with Flat Roofs acceptable security",
  "Is a Converted Flat acceptable security",
  "Can a Flat with Deck Access be considered",
  "Are Freehold properties with Multiple Flats / Units acceptable security",
  "Can an application for a Holiday Let be accepted",
  "Are Houses of Multiple Occupation acceptable",
  "Can lending against just Land be considered",
  "Can an application be accepted where no Letting Agents will be involved and the property will be managed by the applicant",
  "Can a property be considered which requires Light Refurbishment",
  "What is the rental calculation for Limited Companies",
  "Can Limited Companies that are not registered as a Special Purpose Vehicle be considered",
  "Can a remortage with capital raising to purchase land be considered",
  "Are there any restrictions on the maximum number of bedrooms in the property",
  "Can properties with more than one kitchen be considered",
  "What is the maximum number of Tenants that can be considered on one Tenancy",
  "Can a property within a Freehold Block be considered as acceptable security",
  "Are Multiple Tenancy Agreements / Room By Room letting acceptable",
  "Can properties that are classed as Bedsits be accepted as suitable security",
  "Can a property that has Overhead Power Lines / cables be considered suitable security",
  "What is the maximum number of properties that can be considered for a Portfolio Landlord",
  "What rental assessment is carried out for the properties in the background",
  "Can 'Top Slicing' / use of personal income be used for Portfolio Landlords where there is a shortfall in rental income",
  "Can properties affected by Subsidence be considered",
  "Can properties built on or close to Landfill Sites be considered",
  "Are properties subject to the Government Green Deal Scheme acceptable",
  "Does the property need to be in a habitable / lettable condition on completion of the mortgage",
  "Can applications classed as a Regulated Buy to Let be considered",
  "Can a purchase that rent's the property back to the vendor be considered",
  "Is it acceptable to use applicant's income to make up any rental shortfall",
  "Can an application be considered where the current tenant will continue to reside in the property on completion",
  "Are properties let to Students acceptable",
  "Can properties let to DSS / Applicants on Benefits be considered",
  "Is there a minimum single / joint income requirement",
  "What are the rental income calculations",
  "What Rental Income Calculations (ICR) are used for a Higher Rate Tax payer Applicant",
  "How many years left on a lease are required for a Leasehold Property",
  "What is the minimum Percentage Share that can be purchased",
  "Can Shared Ownership be considered when not via a Housing Association",
  "Can Shared Ownership be considered when 100% Staircasing is not available",
  "Are Underpayments / Payment Holidays acceptable",
  "Can a Self Build Application be considered without the need for Detailed Project Costings",
  "Can release of funds be considered before receiving a Building Warrant",
  "Can an application be accepted without any restriction on the Building / Property use or class",
  "Can applications for a Custom Build be accepted",
  "Can an extension to the mortgage offer be considered for a Custom Build application",
  "Can applicant's request to when the stage payments are released be considered",
  "Are Guarantors accepted for a Custom Build Application",
  "Can Interest Only be the Repayment Method during the build.",
  "Can lending against the Purchase of Land be considered",
  "What is the maximum loan available for a Custom Build application",
  "What is the maximum Loan to Cost that can be considered",
  "Which Warranties are acceptable and do they need to be in place on completion of the Custom Build",
  "At what stages during the Custom Build are funds released",
  "Is one set of Solicors acting for the applicant and on behalf of the lender acceptable",
  "Is Site Insurance required during the build and what are the minimum requirements",
  "Will a Valuation / Re inspection be required at all stages of the build",
  "Are Landlocked properties with Easement / Rights of Way acceptable",
  "Is there a minimum number of Stage Payments and what are they",
  "Can an application for Self Build be considered without having Outlined Planning Permission.",
  "Can Stage Payments be made in Advance rather than in Arrears",
  "Can Stage Payments Be made in Arrears rather than in advance",
  "Can a request to specify at what stage the next tranche of funding is release be considered",
  "Can realse of tranche funds be released at each stage without the appropriate certificate",
  "Can the Arrangement Fee and / or Broker fee be added to the loan above the product maximum LTV",
  "Is there a maximum Debt to Income Ratio when assessing affordability",
  "Can an application be considered where the current lender will not grant a second charge",
  "Are properties that have a Restrictive Covenant / Usage acceptable security",
  "Are properties with Solar Panels acceptable security",
  "Can a Cash Reserve or Facility for Additional Funds be applied for",
  "Is an Inheritance Protection Guarantee offered",
  "Are Home Reversion Plans available",
  "Is an Early Vacancy Guarantee available with Home Reversion Plans",
  "Can applications be considered where Rent Payments can escalate on Home Reversion Plans",
  "Can applications be considered where Rent Payments are guaranteed to remain fixed on Home Reversion Plans",
  "Is there a maximum / Maximum Percentage Release on Home Reversion Plans",
  "Can applications for a Home Reversion Plan without Ongoing Vendor Obligations be considered",
  "Can applications where Rent Payments may cease on Home Reversion Plans be accepted",
  "Can applications where the rent payments maybe reduced on a Home Reversion Plan be considered",
  "For Home Reversion Plans what is acceptable on Rent Payable during the plan lifetime",
  "Can the option to re-purchase / buy back the property be considered on Home Reversion Plans",
  "Are Lifetime Mortgages available",
  "Is a higher borrowing available for applicants that have a medical condition which will shorten life expectancy",
  "Is a No Negative Equity Guarantee available",
  "Is it acceptable to make Optional Partial Repayments",
  "How often can withdrawals be made from a cash reserve facility",
  "Is Equity Release on a Buy to Let property acceptable",
  "Are Commonhold Tenure properties acceptable",
  "Can lending on a Commercial property be considered",
  "Can an application for Development Bridging be considered against a Commercial Property",
  "Can an application for a Conversion Development be considered",
  "What is an acceptable Percentage Cost to Profit Margin",
  "Can applicants who are First Time Developers be considered",
  "Can an application for a Ground up Development be considered",
  "Can a Joint Venture Development be considered",
  "Is there a maximum Loan to Cost for Bridging Development",
  "Is there a maximum Gross Development Value LTV based on the End Value for Bridging Development",
  "Is there a Maximum LTV on Acquisition for Bridging Development",
  "Is there a minimum and maximum term for Bridging Development",
  "Can applications for Part Completed Developments be considered",
  "Can a Second Charge be considered",
  "Can a property with Outline Planning Permission be considered",
  "Can the Exit Fee be calculated against the loan rather than the End Gross Development Value (GDV)",
  "Are the Bridging Development Exit Products available",
  "Can an application be secured against Land for Development Finance",
  "Can Regulated applications be considered",
  "Can a property that needs Heavy Refurbishment work carried out be considered",
  "Can the Interest charged be Retained from the Gross Loan amount",
  "Can the loan Repayments be Rolled up in the Gross Loan amount",
  "Can the loan repayments be Rolled up in the Gross Loan amount",
  "Can The Sale of Property be considered as the only Exit Route",
  "What Exit Strategy is acceptable",
  "Can an applicant with no experience in property Development be considered for a Development Loan",
  "Are specific product available for purchasing a property at Auction",
  "Can you consider a Bridging Loan to replace another Bridging Loan",
  "Can lending be considered where an change of property usage required",
  "Is Planning Permission required for a Bridging Loan to be considered",
  "It is OK for applicants to be electronically identified by the Intermediary instead of supplying Certified papers. ",
  "Can you tell me which accountants have suitable qualifications?",
  "Is accountant certificate required for Self Employed applicant?",
  "Is accountant's projection valid to be used for income?",
  "Will accountant's reference do instead of SA302s or Accountant's certificate?",
  "Is it possible to include the broker's advice fee in the loan amount?",
  "Is the product arrangement fee included to the loan amount?",
  "what are the applicable fees that can be added to the loan amount?",
  "what are the fees required to be added in the Loan amount?",
  "I want to know if additional borrowing can be added to the main loan? ",
  "Can I be granted new mortgage if I have an existing mortgage with the lender ",
  "Can adoption income be presented as source of income?",
  "Can an intermediary's own application be accepted?",
  "Can the Affordability calculator be used to assess maximum loan?",
  "Which proposals for affordable housing scheme are acceptable?",
  "How will applicants be evaluated if they retire before the mortgage term is up?",
  "Is money from Agency Work acceptable?",
  "Can we lend against properties with agricultural ties?",
  "Can the property be used for Air Bnb",
  "Can sole income from benefits be considered for applicants?",
  "Can completion of mortgage be considered for applicants who continue to live in tied accommodation?",
  "Can a person having Power of Attorney act on behalf of the applicant?",
  "Is it possible to consider an applicant who has diplomatic immunity?",
  "Is foreign currency income acceptable?",
  "Are applicants whose income come from Property Development be considered?",
  "Is it okay to use an Architect's Certificate instead of a Warranty for the property?",
  "Is an Assignable Contract for Purchasing acceptable?",
  "Is Attendance Allowance considered as source of income?",
  "Is a property owned by a vendor for less than 6 months be considered for purchase",
  "Is it allowed to use Overdraft facility?",
  "Is it necessary to submit a bank statement to evaluate an application?",
  "How long before a discharged bankrupt applicant be considered",
  "Is a Barn Conversion property acceptable security",
  "Is a Basement Flat acceptable security",
  "Can a purchase that is less than the property's market worth be considered?",
  "Is income from the Bereavement Allowance acceptable?",
  "Are BISF Construction properties suited security?",
  "What portion of regular bonuses can be included and for how long?",
  "What portion of guaranteed bonuses can be included and for how long?",
  "Capability to Borrow Back on mortgage products",
  "Will you consider a British national who works overseas or offshore?",
  "Is it possible to accept a cash incentive from a builder?",
  "Can a Builder Gifted Deposit be utilized",
  "Builder Part Exchange allowed?",
  "Are Bursaries income allowed",
  "Can Flight Attendant or Cabin Crew income be usable",
  "Mortgage for Capital and Interest Only Basis",
  "Capital raising for remortgaging",
  "Capital raising for a Buy To Let Property",
  "Capital raising for Business Purposes",
  "Capital raising for Debt Consolidation",
  "Capital raising for purchasing land",
  "Is Car Allowance an acceptable income?",
  "Is Carer's Allowance an acceptable income?",
  "Drawdown / Cash Reserve Facility available?",
  "Certifying documents requirements",
  "Is Child benefit considered as income?",
  "Effect of Child Maintenance payments on affordability assessment",
  "Is Non Court ordered Child Maintenance payments considered as income?",
  "Is Child Tax Credits considered income?",
  "Effect of Childcare cost on affordability assessment",
  "Effect of Childcare vouchers on affordability assessment",
  "Is CIS Contractors income allowed?",
  "Is Commission or Profit Related pay an acceptable income?",
  "Are applicants earning solely from commission accepted?",
  "Is Company or Occupational Pension considered income?",
  "Can a company director based overseas be approved for a mortgage",
  "Can an under value (Concessionary Purchase, Deed of Gift or Inter-family Sale) transaction be approved?",
  "Can Consent to Let be considered for a residential property",
  "Is Constant Attendance Allowance approved as income?",
  "Acceptable types of Non Standard Construction",
  "Are applications tagged as Consumer Buy to Let considered?",
  "Is income from Contract Workers acceptable?",
  "Can convicted applicants be approved to apply",
  "Can Unsatisfied / Outstanding County Court Judgements (CCJs) be an application concern?",
  "Can Satisfied County Court Judgements (CCJs) be acceptable?",
  "Is it possible to use Court ordered child maintenance payments as a source of income?",
  "Are Missed payments on Credit Cards / Store Cards / Mail Order allowed?",
  "Can we assess affordability based on credit card balance or monthly payment",
  "Are Credit commitments to be repaid removed from affordability assessment?",
  "Credit Commitment with less than 12 months allowed?",
  "Credit Reference Agency ",
  "Is there any product to use to help the applicant in repairing their credit profile?",
  "Do you Credit Score applicants?",
  "Do you manually underwrite applicants",
  "Are you allowing applicant with criminal record?",
  "If the applicant have a history of Unsecured Loan Arrears are still allowed to apply?",
  "Will they considered an applicant with late or missed Utility Bills payment?",
  "Will they considered an application when the only available account was outdated over a year ago?",
  "Will a Homebuyers or Structural / Full Building Valuation Report be set up via the lender",
  "Do they accept a Vendor Gifted Deposit",
  "Is it acceptable to use vested share income when assessing affordability?",
  "Is War Widow's pension income can be presented when assessing affordability?",
  "Is Widow's pension income can be presented when assessing affordability?",
  "Is Widowed Parent allowance income can be presented when assessing affordability?",
  "Do you allow Wimpey No Fines or Laing Easi Form concrete construction properties?",
  "What proof of income can be presented if the applicant is currently employed in their Family Business?",
  "Will a Working Tax Credit Benefits is allowed when assessing affordability",
  "Will they allowed an application of an Expatriation for a Holiday Let?",
  "Do a farm or a Small Holding is secured?",
  "Can loan repayments be rolled into the total loan amount?",
  "Sale of Property classified as Exit Route",
  "Acceptable Exit Strategy ",
  "Is there a chance to process Development Loan when you have no experience in Property Development",
  "Is there a product available for purchasing a property at Auction",
  "Is it allowed to replace Bridging Loan with another Bridging Loan?",
  "Can lending be allowed where a change of property usage required",
  "Is it require to have Planning Permission for a Bridging Loan to be approve?",
  "Is it acceptable to remortgage a property purchased less than 6 months ago",
  "Are debt Management Plans accepted",
  "Does a Debt Management Plan restrict an application to be approved?",
  "How long does it take for a completed Debt management plan to be accepted",
  "How much time does it take for a completed Debt Relief Order be approved?",
  "Can I apply if I have an ongoing Debt Relief Order?",
  "Are clients with satisfied defaults accepted?",
  "Are clients with unsatisfied defaults accepted?",
  "Can a New Build property be approved for security?",
  "Can a New Build Flat be approved for security?",
  "Can a dependant relative purchase or remortgage for a property be approved?",
  "Can dependents be disregarded for affordability purpose?",
  "Can a deposit from a Limited Company be approved",
  "Acceptable sources of deposits",
  "Unacceptable sources of deposit",
  "Can we choose the date to take for direct debit payment and does it have to be from the applicant's account?",
  "Is the Director's Dividends acceptable in assessing affordability?",
  "Is the Director's Loan acceptable in assessing affordability?",
  "Is disability living allowance benefits acceptable in assessing affordability?",
  "Can properties subjected to section 106 restrictive covenant or a discounted market be approved?",
  "Application with distressed sale acceptable?",
  "Is income from retirement Dividend acceptable?",
  "Down Sizing can be considered as way to get Interest Only as a repayment method",
  "Is Mortgage allowed to be redeemed within the product's initial benefit period without charge?",
  "Are payslips considered proof of employment?",
  "Can I include future pay rise as proof when assessing affordability?",
  "How much shareholding in a business / partnership is needed to be classified as self employed?",
  "Is Employee benefit Trust income acceptable when assessing income?",
  "Is it necessary to present Employer's reference for applicants?",
  "Is Employment and Support allowance accepted for affordability?",
  "Is there a minimum amount of time that candidates must be employed continuously?",
  "minimum time for applicants to be employed with current employer",
  "Can Endowment be considered as repayment vehicle when availing Interest Only as repayment method",
  "Expatriate with adverse credit acceptable?",
  "Are Expatriates in registered UK companies that reside in the British Colonies considered?",
  "Expatriates applying for Consumer Buy to Let basis considered?",
  "Is there a restriction to which countries expatriates can come from?",
  "Are applications from expatriates who will be returning to the UK soon be recognised?",
  "Are applications from expatriates employed by a Multi national company returning to the UK recognised?",
  "Are expatriates that are first-time buyers acceptable?",
  "Maximum term for Expatriates",
  "Minimum term for Expatriates",
  "Maximum period for Expatriates to be outside UK",
  "Minimum and maximum income for Expatriates",
  "Minimum and maximum loan amount for Expatriates",
  "Minimum and maximum age for Expatriates",
  "Minimum and maximum property value for Expatriates",
  "Can Expatriate applicants use a New Build Flat as acceptable security?",
  "Can Expatriate applicants use a New Build House as acceptable security?",
  "Can Expatriate applicants with PO Box address be considered?",
  "Are Expatriates allowed for remortgage for Debt Consolidation",
  "Are retired Expatriate applications acceptable?",
  "Are applications from retired expatriates processed?",
  "Can an Expatriate use an Ex Council House / Flat for security?",
  "Can an existing borrower continue on the same criteria rataher than getting a new policy?",
  "Is it allowed to have an existing second charge on property?",
  "Can Expats be applicants?",
  "Can expenses be classified as income when assessing affordability",
  "Can an offer for a mortgage be extended?",
  "List of specific products that allows family members to help with the mortgage deposit",
  "Can a gifted deposit (not from a family member) be considered?",
  "gifted deposit from family member considered?",
  "Do you accept First Time Buyers?",
  "First term contract income acceptable?",
  "Can a Flat near commercial premises be considered suitable security",
  "What percentage of a property with an element of Flying Freehold can be considered?",
  "Does a Forces Help To Buy (HTB) Scheme have loans?",
  "Are Forces Personnel and their income qualified?",
  "Is Foreign currency income acceptable to be presented for affordability?",
  "Is there any restriction for former Houses of Multiple occupation as security?",
  "Foster caring income acceptable?",
  "conditions for getting free legal",
  "Freehold Flat qualified security?",
  "Is an application valid without Full Vacant Posession on completion?",
  "Is Further Advance available for existing borrowers?",
  "Is an applicant with Gambling history eligible?",
  "Are there restrictions for properties that have doubling ground rent?",
  "Are there restrictions for properties that have escalating ground rent?",
  "Are there restrictions for properties that have increasing ground rent?",
  "Are there restrictions for properties that have ground rent linked to RPI?",
  "Is Income payment for Forces accepted for assessing affordability",
  "Are there any eligibility restrictions for Guarantors?",
  "Can Guardian Allowance income be used for assessing affordability?",
  "Will an applicant's credit file reflect a a hard or soft footprint when a dip is encountered?",
  "Are there any restrictions for an accepted application for a help to buy loan?",
  "Is it allowed to repay remortgages in partial or full for Help To Buy Equity Share Loan",
  "What are the conditions in paying for a Higher Lending Charge",
  "Is it possible to submit an application for a holiday home and are there any restrictions?",
  "Is Housing Benefit acceptable for assessing affordability",
  "Is Lodger income acceptable for assessing affordability",
  "Is Trust fund income acceptable for assessing affordability",
  "Can income multipliers be used when assessing affordability and the maximum allowable loan",
  "Is income support recognised when assessing affordability",
  "Can application be approved when the Individual Voluntary Arrangement (IVA) is still ongoing",
  "When can a completed Individual Voluntary Arrangement (IVA) be eligible?",
  "Is Industrial Injuries Disability Benefit accepted for assessing affordability",
  "Can inheritance be a repayment vehicle when applying for Interest Only as repayment method?",
  "Can Interest Only be considered as repayment method?",
  "Acceptable repayment methods and conditions in gettting Interest Only Mortgage",
  "Can Investment Bonds be a repayment vehicle when applying for Interest Only as repayment method?",
  "Is Investment Income accepted in assessing affordability",
  "Will ISA be a suitable repayment vehicle if the taken repayment method is interest only",
  "Do applications from IT contractors get approved and how are their income calculated?",
  "Are properties with Japanese Knotweed suitable security and are the any restrictions",
  "Can job seekers Allowance be cosidered as source of income",
  "Is it allowed to submit an application for joint borrowers if only one is the legal owner, and if so, under what circumstances?",
  "Do you accept application for Key worker scheme?",
  "Can an applicant apply to own a property that is next to his existing one",
  "Are loans over £500,\n        000 allowed?",
  "Can I lend a property located in England?",
  "Can I Lend a property located in Nothern Ireland?",
  "Can I Lend a property located in Scotland?",
  "Can I Lend a property located in Wales?",
  "Can I Lend a property located in Southern Isles?",
  "Is there any restriction to the maximum exposure in the development or area",
  "Is applying for mortgage until retirement still acceptable? If so, what are the conditions?",
  "Can a Limited company apply for a Loan",
  "Is Trustees eligible to get a Loan",
  "Is a self employed applicant from Ltd Company to a Sole Trader can be considered?",
  "Are applicants involved in Limited Liability partnership be considered?",
  "Live Work/Usage restrictions eligible?",
  "Is LTI cap applicable when assessing affordability? ",
  "Is sale through Local Authority Grant Scheme accepted?",
  "Is it allowed to rent a room or take in a lodger on the property?",
  "Is London Weighting or Large Town Allowance a valid source of income for assessing affordability?",
  "Can maintenance obtained through a private arrangement be taken into account when assessing affordability?",
  "Is it allowed for married candidates to apply under a single name?",
  "Are applicants on Maternity/Paternity Leave accepted, and if yes, can their income be used to assess affordability?",
  "What is the maximum and minimum age limit for applicants at the time of application?",
  "What is the maximum age permissible for applicants at the end of the mortgage term?",
  "Maximum Loan Amount acceptable",
  "Maximum LTV for a pound for pound Remortgage",
  "Maximum LTV for New Built Flat/House",
  "Are Ex Council Houses and Flats be considered security? If so, are there any restrictions",
  "Can a Right to Buy purchase be accepted? If so, what would be the maximum LTV",
  "Can a Shared Equity purchase be accepted? If so, what would be the maximum LTV",
  "Can a Shared Ownership Equity be accepted? If so, what would be the maximum LTV",
  "Are Second Hand Flats considered security? If so, are there any conditions?",
  "Are Second Hand Houses considered security? If so, are there any conditions?",
  "Are properties acquired through Right to Buy approved for remortgages if they are still in the Pre Emption period?",
  "Is it allowed to remortgage a house under the Shared Equity Scheme, and what is the maximum LTV?",
  "Is it allowed to remortgage a house under the Shared Ownership Scheme, and what is the maximum LTV?",
  "Are Studio Flats allowed to be used as security? if so, are there any conditions?",
  "Are Expatriates given the same Terms and LTV like the other applicants?",
  "Is it legal to raise capital against an unencumbered property?",
  "Can remortgage be applied for an outright owned property that is due for capital raising?",
  "Is capital raising allowed for Debt Consolidation, if so, are there any restrictions?",
  "Is capital raising allowed for Business Purposes, what are the conditions?",
  "Is capital raising allowed for Home Improvements? Are there any conditions?",
  "Is capital raising allowed for purchasing of Equity Share? Are there any conditions?",
  "Is capital raising allowed to for purchasing BTL property? Are there any conditions?",
  "Are First Time Buyers allowed to borrow up to the Standard Maximum LTV",
  "Maximum LTV applicable for Interest Only Repayment method mortgage",
  "Maximum LTV applicable for Large Loans",
  "Maximum LTV allowed for part and part / split repayment method mortgage",
  "Can more than 2 applicants be allowed and all their income be utilised for assessing affordability",
  "Is there any minimum or maximum property value and does it vary based on the type of the property?",
  "Capital and Interest repayment method and its Maximum term",
  "What is the minimum income of an applicant for Capital and Interest Repayment",
  "What is the minimum income of an applicant for Interest Only Repayment",
  "What is the minimum income of an applicant for Part and Part or Split repayment?",
  "What is the minimum Loan Amount and does it vary based on borrower or product type?",
  "Minimum floor area in square meters required for a Studio Flat",
  "Acceptable Minimum Mortgage Term",
  "Are applicants with history of missed payments on their credit commitment still be considered?",
  "Are Mixed use or partly commercial properties still be considered security?",
  "Are applicants with history of missed payments on mobile contracts still be considered?",
  "Is electronic Signature allowed for use in Mortgage Declarations and Direct Debits?",
  "For how long is an Extension of mortgage acceptable in New Build Properties?",
  "Until when is the mortgage offer Valid?",
  "Applicants with arrears on a mortage / secured loan allowed? Under what contidion?",
  "Is Mortgage Subsidy recognised as an Income for assessing affordability?",
  "Are mortgage terms of Years and Months accepted?",
  "Is Mundic Block construction eligible? At what grade?",
  "Can Net Profit be used as Income for Director of a Limited Company?",
  "Is the LTV regulated for New-Build Flats?",
  "Is the LTV regulated for a New-Build House?",
  "Is it permissible to buy a New build property off plan?",
  "Under what conditions are New Build Property incentives approved?",
  "What warranties and warranty providers are permitted for New Build Properties",
  "Are occupiers aged over 17 allowed in the property?",
  "Will applications from Non EU Foreign Nationals be considered? If so, what would be the conditions?",
  "Is a staggered and Non-Simultaneous sale and completion acceptable?",
  "Can Non-Standard construction properties be approved as suitable security?",
  "Non UK Tax Payers accepted?",
  "Maximum Number of Floors acceptable for Flats?",
  "Is nursing bank staff income accepted in assessing affordability",
  "Is there any product that has Offset Mortgage options",
  "Will a mortgage be approved on a security address with an overage clause / agreement?",
  "Are there products with Overpayment Facility? What are the conditions",
  "Can Non-Regular Income be accepted when assessing Affodability",
  "Can Regular / Guaranteed Overtime be accepted when assessing Affodability",
  "Are there extra requirements for income paid in cash?",
  "Percented allowed for Part and Part /split repayment method",
  "Can past payday loan availers be accepted?",
  "Are payment holidays permissible?",
  "What are the documents needed for Payslip to be recognised as income?",
  "Can Pension be used as repayment for an Interest Only Repayment?",
  "Are Pension Contributions recognised in assessing affordability?",
  "Is Private Pension Credits allowed for assessing affordability?",
  "Are Period Properties acceptable security?",
  "Is Health Insurance significant when assessing affordability?",
  "Can Personal Finance supplied into a Business be disregarded when assesing affordability?",
  "Is Personal Independent Income Benefit allowed when assessing affordability",
  "Is Piece Work considered an employment type and an acceptable source of income?",
  "Is it permissible to purchase a property with Restrictive or Unusual Planning Permits?",
  "Can any or all products support porting to another property?",
  "Are all Postcode Areas allowed to apply for loan?",
  "Are properties made of Pre-Reinforced Concrete permissible security? if so, what are the requirements",
  "Can Price Work be considered as a source of income.",
  "Is a Private Sale Purchase with no Estate Agents considered legitimate?",
  "Can a person under Probation Period be allowed to apply? If so, what are the requirements?",
  "Is Product split allowed in 1 mortgage?",
  "Can an intermediary be used by existing borrowers for a product transfer application?",
  "Are Professional Landlords considered?If so, what are the requirements?",
  "What are the requirements for professional sports persons / athletes?",
  "Can an application without proof of deposit be approved?",
  "Can an application without proof of personal ID be approved?",
  "Can an application without proof of address / residence be approved?",
  "Can an application to avail a property at an auction be considered?",
  "Can a property be purchased through Investment / Property Club?",
  "Can properties with an annex be approved for security?",
  "Do properties with outbuildings get considered as security?",
  "Can properties with substantial land be considered security? Is there a maximum acreage?",
  "Can a property be bought for a family member to reside in be acceptable?",
  "Can a Limited Company be allowed to purchase?",
  "Is it possible to pay for a re-inspection fee for a new build property?",
  "Can Reduced Earning Allowanec income be considered in assessing affordability?",
  "Can a reduced income for a specific period be used in assessing affordability?",
  "Can a regulated buy to let application be approved?",
  "Can a Transfer Equity / Divorce settlement be approved for a remortgage?",
  "Can a remortgage be approved if it has capital raising?",
  "Can a bridging loan be repaid with a remortgage?",
  "Can applicants be approved if they have rent arrears?",
  "Can a purchase through rent to buy schemes be approved?",
  "Can affordability assessment proceed for rental income?",
  "Do applicants with repossessed property / surrendered voluntarily be approved?",
  "Can a 3 years or less resident in the UK be approved?",
  "Can a residential mortgage that is pending completion affect another application?",
  "Can retained profit in a business be used by self-employed applicants when assessing affordability?",
  "Are there repayment vehicles / strategies for retirement interest only lending?",
  "Is non-guaranteed future income usable for assessing affordability for retirement interest only?",
  "Are enhanced terms for Retirement Interest Only mortgages acceptable when there is a Last Power of Attorney?",
  "Is Benefit Income acceptable when assessing affordability for joint applicants - Retirement Interest only",
  "Is Family Assistance Income acceptable when assessing affordability for joint applicants - Retirement Interest only",
  "Is Investment Income acceptable when assessing affordability for joint applicants - Retirement Interest only",
  "Are Life Cover projected proceeds acceptable when assessing affordability for joint applicants - Retirement Interest only",
  "Is Pension income acceptable when assessing affordability for joint applicants - Retirement Interest only",
  "Is Pension income acceptable when assessing affordability for joint applicants - Retirement Interest only",
  "Is there a maximum age at application for retirement interest only?",
  "Can there be a maximum age at the end of the mortgage term for Retirement Interest Only?",
  "Maximum Loan accepted for Retirement Interest Only",
  "Maximum LTV considered for Retirement Interest Only",
  "Is like for like remortgage acceptable for Retirement Interest Only?",
  "Is a remortgage with capital raising for Retirement Interest Only Lending acceptable and what is the max LTV",
  "Maximum allowed term for a retirement interest only mortgage",
  "Minimum acceptable age at application for Retirement Interest Only lending",
  "Minimum allowed loan amount for Retirement Interest Only lending",
  "Minimum permissible equity for Retirement Interest Only lending",
  "Minimum permissible income for Retirement Interest Only lending",
  "Acceptable minimum property value for Retirement Interest Only lending",
  "Permissible minimum term for Retirement Interest Only lending",
  "Is Retirement Interest Only lending acceptable for any new business products?",
  "Can a No Negative Equity Guarantee be proposed for Retirement Interest Only lending",
  "Are purchases through the Right to Buy Scheme acceptable?",
  "Can we not use Salary Sacrifice deductions for assessing affordability?",
  "Can a purchase of a Foreign Property or Asset be approved as a Repayment Vehicle for Interest Only",
  "Is the sale of a Residential property be approved as a Repayment Vehicle for Interest only?",
  "Is the sale of other Assets be approved as a Repayment Vehicle for Interest only?",
  "Is the sale of a Mortgaged Property be approved as a Repayment Vehicle for Interest only?",
  "Is the sale of an Investment / Buy to Let Property be approved as a Repayment Vehicle for Interest only?",
  "Can Savings be approved as a Repayment Vehicle for Interest only?",
  "Can a conversion to a Capital and Interest / Repayment later be approved as a repayment vehicle for interest only?",
  "Can seasonal employment income be allowed when assessing affordability?",
  "Can an application be approved if the second applicant is unemployed?",
  "Are the restrictions to an application for a Second Home / Pied a Terre?",
  "Can second job income be considered when assessing affordability?",
  "Can self build property applications be approved?",
  "Can Self Employed applicants with a 1 year account be approved?",
  "Can Self Employed applicants with declining profits be approved?",
  "Do Services Charges / Ground Rent and Maintenance costs matter when assessing affordability?",
  "Can Shared Ownership Scheme applications be approved?",
  "Is remortgage to staircase / buy a bigger share of a property be acceptable?",
  "Availability of products that are Islamic / Sharia compliant",
  "Is shift allowance income acceptable when assessing affordability?",
  "Are short term lending acceptable?",
  "Can applicants on a Short Term Renewable contract be considered?",
  "Can Self Employed Sole Trader applicants be considered?",
  "Can a Sole Trader that turned into a Limited Company be approved and are there restrictions?",
  "Can Income for Special Duties be approved when assessing affordability?",
  "Is there a set of products or requirements for Professional Applicants?",
  "Is it possible to permit the Title Deeds to be divided at the time of completion?",
  "Can Spousal Maintenance be considered acceptable source of income when assessing affordability?",
  "Is the State Pension Benefit an acceptable source of income when assessing affordability?",
  "Are properties built with steel Framed Construction acceptable?",
  "Can stocks and shares be used as a repayment vehicle for Interest-Only loans?",
  "Will a student loan be disregarded when assessing affordability?",
  "Will an application that includes Sub-Sales / Contract Reassignment be considered?",
  "Can Subcontractors apply? What are the requirements?",
  "Is there a set of criteria for evaluating Teacher applicants?",
  "Is temporary work/employment permissible? May it be used when assessing affordability?",
  "Is timber-framed construction a suitable building type, and are there any restrictions?",
  "Can applicants on a VISA be approved and what are the requirements?",
  "Is unlimited Aggregate Borrowing with the Lender / Group allowed?",
  "Are Self Employed Applicants with less that 2 years Trading allowed?",
  "Is it possible to consider Travel Allowance income when assessing affordability?",
  "Can travel expenditures be disregarded when assessing affordability?",
  "Can a mortgage be approved in joint names with only one person named on the Deeds?",
  "Can properties with Cross Leases or Tyneside Flats be approved?",
  "Are applicants with less than 3 years UK residency permitted",
  "Can Self Employed Applicants under an Umbrella Company be considered?",
  "Are free legal services available for unencumbered houses, and is this classified as purchase or remortgage?",
  "Can Unit Trusts or OEICs be approved as a repayment vehicle for Interest Only",
  "Is Universal Credit recognised income for the purposes of assessing affordability?",
  "Can applicants on a Zero Hour Contract be considered?",
  "Do applications that have no Assured Shorthold Tenancy Agreement get accepted?",
  "Is Coal Mining Report needed for a mortgage offer to be issued?",
  "Do Buy To Let applications get approved?",
  "Do properties about to be let to a Company / Corporation get considered?",
  "Can a Portfolio Landlord get approval?",
  "Do properties with below E for energy rating be considered?",
  "Can Expatriates that are First Time Landlords be considered?",
  "Can an application for a House be considered for an Expatriate of Multiple Occupation?",
  "Can Expatriates who are classified as Professional Portfolio Landlords be accepted?",
  "Are Experienced Landlords acknowledged, and how are they defined?",
  "How is an application considered when one applicant is a First Time Buyer and the other is a Home Mover?",
  "Can first-time landlord applications be considered?",
  "Are properties with flat roofs considered suitable security?",
  "Is a Converted Flat acceptable",
  "Can a Flat with Deck Access be approved?",
  "Can Freehold properties with multiple flats or unit be considered for security?",
  "Are Holiday Let applications approved?",
  "Are they allowing multiple occupants in a House?",
  "Can Lending Solely Against Land be allowed?",
  "Do you process applications when No Letting is involved?",
  "Can a property with Light Refurbishment requirement be considered?",
  "Can you provide me the rental calculation for a Limited Company?",
  "Can limited companies unregistered as special purpose vehicle be considered?",
  "Do you allow remortgage with capital raising to be used in purchasing Land?",
  "What is the Maximum number of Bedroom in a property?",
  "Can properties that have more than 1 kitchen be considered?",
  "Maximum no. of Occupants that can still be considered in one Tenancy",
  "Can properties within a Freehold Block be approved as a type of security?",
  "Do Multiple Tenancy Agreements / Room By Room letting get approved?",
  "Are Bedsits acceptable security?",
  "Are properties with Overhead Cables allowed?",
  "Maximum number of properties that a Portfolio Landlord can own?",
  "What kind of rental assessment is done for the properties",
  "When there is a gap in rental income, will Portfolio Landlords be allowed to use their personal income to fill in?",
  "Is it possible to consider properties that have been affected by subsidence?",
  "Are properties close to Landfills advisable?",
  "Is it permissible to buy a property that is part of the government's Green Deal scheme?",
  "Is it necessary for the property to be livable or rentable after the mortgage is paid off?",
  "Can applications for Regulated Buy-to-Let properties be considered?",
  "Is it possible to consider a purchase that rents the property back to the vendor?",
  "Is it okay to use the applicant's income to cover any rental shortfall?",
  "Can an application be accepted if the present tenant will remain in the property once it is completed?",
  "Is it permissible to let properties to students?",
  "Can properties rented to DSS / Applicants on Benefits be regarded?",
  "What is the minimum requirement for a single or joint income?",
  "What is the formula for calculating rental income?",
  "What Rental Income Calculations (ICR) are utilised for a Higher Rate Taxpayer Applicant?",
  "What is the minimum number of years left on a lease for a leasehold property?",
  "What is the smallest percentage share that can be bought?",
  "Is it possible to contemplate Shared Ownership if you don't want to go through a Housing Association?",
  "When 100% staircasing is not possible, might shared ownership be considered?",
  "Is it allowed to make underpayments or take Payment Holidays?",
  "Is it possible to assess a self-build application without detailed project costings?",
  "Is it possible to consider releasing of funds before acquiring a Building Warrant?",
  "Is it permissible to approve an application without any restrictions on the building's or property's use or class?",
  "Is it allowed to submit an application for a Custom Build?",
  "Is it possible to accept a mortgage offer extension for a Custom Build application?",
  "Is it possible to accept an applicant's request for when the stage payments will be released?",
  "Is it advisable to get a guarantor for a Custom Build Application?",
  "Can interest only be used as a repayment method during construction?",
  "Can a loan against the purchase of land be considered?",
  "What is the highest loan amount allowed for a Custom Build application?",
  "What is the highest Loan to Cost ratio that can be considered?",
  "Which warranties are acceptable, and do they need to be in place when the Custom Build is completed?",
  "When are funds released during the Custom Build?",
  "Is it permissible to have a single set of Solicors functioning for both the applicant and the lender?",
  "Is construction site insurance necessary, and what are the basic requirements?",
  "Will a valuation / re-inspection be necessary at every step of the build?",
  "Is it allowed to have a landlocked property with easements or rights of way?",
  "Is there a certain number of Stage Payments required, and what are they?",
  "Can a Self Build application be considered without Outlined Planning Permission?",
  "Is it OK to arrange payment in Advance rather than paying in Arrears",
  "Is it OK to arrange Stage payments in Arrears rather than in advance?",
  "Can a request to identify when the next tranche of funding will be released be considered?",
  "Can tranche funding be distributed at each level without the necessary certificate?",
  "Can the Arrangement Fee and/or Broker Fee be applied to the loan if the loan exceeds the product's maximum LTV?",
  "Is there a maximum Debt to Income Ratio to consider when assessing affordability?",
  "Can an application be accepted if the existing lender refuses to provide a second charge?",
  "Are properties with a Restrictive Covenant / Usage a suitable security?",
  "Are solar-paneled homes appropriate for security?",
  "Is it Ok to apply for a cash reserve or a facility for additional funds?",
  "Is there an Inheritance Protection Guarantee available?",
  "Are there any plans for home Reversion?",
  "Is there an Early Vacancy Guarantee with Home Reversion Plans?",
  "Can applications be approved in cases where Rent Payments can escalate on Home Reversion Plans?",
  "Is there an Early Vacancy Guarantee with Home Reversion Plans?",
  "Can applications be approved in cases where Rent Payments can escalate on Home Reversion Plans?",
  "Can applications be accepted if rent payments on home reversion plans are guaranteed to stay fixed?",
  "Is there a cap / maximum percentage of release on home reversion plans?",
  "Can applications for a Home Reversion Plan be accepted if there are no ongoing vendor obligations?",
  "Can applications for Home Reversion Plans where rent payments may cease be accepted?",
  "Can applications on a Home Revesion Plan where the rent payments may be reduced be approved?",
  "What is acceptable on rent payable during the plan's lifetime for home reversion plans?",
  "Can the option of re-purchasing / purchasing the property be acceptable on Home Reversion Plans?",
  "Do you offer Lifetime Mortgages?",
  "Is there a greater borrowing limit accessible for applicants that have a medical condition that would reduce their life expectancy?",
  "Is a No Negative Equity Guarantee accessible?",
  "Is making Optional Partial Repayments permissible?",
  "What is the withdrawal frequency allowed from a cash reserve facility?",
  "Is lending a commercial building allowed?",
  "Are properties of commonhold tenure acceptable?",
  "Are commercial properties loanable?",
  "Can a Development Bridging application be considered against a Commercial Property",
  "Is it allowed to submit an application for a Conversion Development?",
  "What is an adequate Cost to Profit Margin Percentage?",
  "Can first-time developers be considered?",
  "Can ground-up development applications be considered?",
  "Can a Joint Venture Development be possible?",
  "Maximum allowable loan to cost for bridging development",
  "What is the maximum Gross Development Value LTD based on the End Value for Bridging Development",
  "Is there a maximum LTV for an acquisition on bridging development?",
  'Is there a minimum and maximum term for "Bridging Development"?',
  "Can applications for part completed developments be approved?",
  "Is a Second charge allowed?",
  "Is applying for a property with Outline planning permission allowed?",
  "Is it possible to compute the Exit Fee against the loan instead of the End Gross Development Value (GDV)",
  "Can Bridging Development Exit Products be accessible?",
  "Can a Land for Development Finance application be secured?",
  "Can regulated applications be approved?",
  "Can a property that requires extensive renovations be considered?",
  "Is it possible to deduct the interest incurred from the total loan amount?",
  "Instead of providing Certified papers, it is possible for applicants to be electronically identified by the intermediary.",
  "Which qualifications are suitable for an accountant?",
  "Do self employed applicants need an accountant's certificate?",
  "Can accountant projections be declared as income?",
  "Will an Accountant's reference be sufficient instead of SA302s or an Accountant's certificate?",
  "Is it OK to add the broker's advice fee to the loan amount?",
  "Can the product arrangement fee be charged to the loan amount?",
  "Which fees can be included in the loan amount?",
  "Required fees to be included in the loan amount",
  "Possibility for additional borrowing to the main loan to be considered",
  "Can an existing mortgage remaining with the lender impact the application to a new one?",
  "Can adoption allowance be considered as a source of income?",
  "Are applications from Intermediaries acceptable?",
  "Can the maximum loan be assessed using an affordability calculator?",
  "What are the acceptable schemes for Affordable Housing?",
  "Can applicants pass the assessment if one retires before the end of the mortgage term?",
  "Is compensation from Agency Work acceptable?",
  "Can properties with agricultural ties be suitable security to lend against?",
  "Is the property allowed for Air BnB use?",
  "Will applicants whose sole source of income come from benefits be approved?",
  "Are applicants that continue to stay in linked housing after the mortgage is finished be considered?",
  "Can a Power of Attorney allow a different person to act on the applicant's behalf?",
  "Could an applicant with Diplomatic Immunity be approved?",
  "Can foreign currency income be approved?",
  "Are applicants whose sole income are from property development be approved?",
  "Can an Architect's Certificate be used in place of a Property Warranty?",
  "Can a purchase using an assignable contract be acceptable?",
  "Is an Attendance Allowance recognised as a source of income?",
  "Will a purchase be considered if the vendor has only owned the property for less than 6 months?",
  "Will it be okay to use an Overdraft facility?",
  "Is it important to present a bank statement in evaluating an application?",
  "When will a discharged bankrupt applicant be considered for application processing?",
  "Can a Barn Conversion property be suited for security",
  "Can a Basement Flat be suited for security?",
  "Property below market value allowed?",
  "Can income from Bereavement Allowance be considered?",
  "Can BISF Construction properties be acceptable security?",
  "Over what period can percentage of regular bonuses be considered?",
  "Over what period can percentage of guaranteed bonuses be considered?",
  "Is borrow back on mortgage products supported?",
  "Are British nationals working outside the country allowed?",
  "Are cash incentives from builders acceptable?",
  "Can a Builder Gifted Deposit be allowed?",
  "Can Builder Part Exchange transactions be approved?",
  "Can income from Bursaries be allowed?",
  "Can Flight Attendants or Cabin Crews apply using their income?",
  "Can mortgage be taken out on a Capital and Interest Only Basis?",
  "Is capital raising OK when remortgaging?",
  "Can a Buy to Let Property be purchased using capital raising?",
  "Can capital raising be used for Business Purposes?",
  "Can capital raising be used for Debt Consolidation?",
  "Will capital raising be considered for purchasing land",
  "Can Car Allowance be used as source of income?",
  "Do you allow Carer's Allowance as a source of income?",
  "Is Drawdown / Cash Reserve Facility included with any of the products?",
  "What is required for Certifying Documents?",
  "Is Child Benefit accepted as a source of income?",
  "Do Child Maintenance payments have an impact on affordability assessment",
  "Can Non Court ordered Child Maintenance payments be accepted as a source of income?",
  "Is Child Tax Credits an acceptable income?",
  "Will Childcare cost have an impact in assessing affordability?",
  "Will Childcare Vouchers have an impact in assessing affordability?",
  "Do income from CIS contractors get approved?",
  "Does income from Commission or Profit Related pay get accepted?",
  "Can commission be acceptable as an only source of income?",
  "Can salary from a Company or Occupational Pension be accepted?",
  "Can an overseas based company director apply for a mortgage?",
  "Is under valued Concessionary Purchase, Deed of Gift or Inter-family Sale allowed?",
  "Can Consent to Let be granted on a residential property?",
  "Is Constant Attendance Allowance an acceptable income?",
  "What are the types of Non Standard Construction allowed?",
  "Do you accept Consumer Buy to Let applications?",
  "Can applications from contract workers be considered?",
  "Can convicted applicants be considered?",
  "Do applicants with Unsatisfied County Court Judgements (CCJs) be considered?",
  "Are Satisfied County Court Judgements (CCJs) acceptable?",
  "Will it be acceptable to use Court ordered child maintenance payments as a form of income?",
  "Are missed payments on Credit Cards / Store Cards / Mail Order catalogues a concern?",
  "Will Credit card balance or monthly payment impact affordability assessments?",
  "Policy for disregarding credit commitments to be repaid when assessing affordability",
  "Will Credit Commitment that is less than 12 months remaining be acceptable when assessing affordability?",
  "Are you using Credit Reference Agency",
  "Is there a product that can be used to assist the applicant in repairing their credit profile?",
  "Are applicants Credit Scored or are they manually underwritten?",
  "Are applicants Manually Underwritten or are they Credit Scored?",
  "Do you consider applicants with criminal record?",
  "Are they still allowing applicants with a history of Unsecured Loan Arrears to apply",
  "Can applicants with missed or late utility bills payments be considered?",
  "Will an account dated over a year ago be considered?",
  "Can a Homebuyers or Structural / Full Building Valuation Report be coursed through the lender?",
  "Is Vendor Gifted Deposit allowed?",
  "Can Vested Share Income be used when assessing affordability?",
  "Can a War Widow's Pension income be used when assessing affordability?",
  "Can a Widow's Pension income be used when assessing affordability?",
  "Can a Widowed Parent Allowance income be used when assessing affordability?",
  "Can Wimpey No Fines / Laing Easi Form construction properties be approved?",
  "What proof of income is needed if an applicant is currently employed in their Family Business?",
  "Can Working Tax Credit Benefits be used when assessing affordability?",
  "Are they processing applications of Expatriates for a Holiday Let?",
  "Is a Farm of Small Holding suitable for security?",
  "Are loan Repayments allowed to be rolled up in the Gross Loan amount?",
  "Is Sale of Property considered the only Exit Route?",
  "Allowed Exit Strategy",
  "Does a newbie in Property Development have a chance in applying for a Development Loan?",
  "Do you have specific products available for buying a property in an Auction",
  "Will it be okay if you get another Bridging Loan to replace an existing Bridging Loan?",
  "Is lending permissible if a change in property usage is required?",
  "Is it necessary to have Planning Permision in order to be considered for a Bridging Loan?",
  "Is it legal to refinance a property acquired less than six months ago?",
  "Can an application be approved if there is an ongoing / current Debit Management Plan?",
  "How long does it take for a Debt management plan to be completed and approved?",
  "How long does it take to process and accept a debt relief order?",
  "Do applications with an ongoing / current Debt Relief Order get considered?",
  "Can clients with a satisfied defaults be considered?",
  "Can clients with an unsatisfied defaults be considered?",
  "What is new Build House?",
  "What is the new Build Flat?",
  "Can a dependent relative buy or refinance the property?",
  "Can dependents be excluded from the affordability calculation?",
  "Is a deposit from a Limited Company considered?",
  "What are the acceptable sources of deposit?",
  "Deposit sources that are unacceptable",
  "Could there be a choice when direct debit payment could be taken?",
  "Is it permissible to consider the Director's Dividends when determining affordability?",
  "Is the Director's Loan suitable in terms of determining affordability?",
  "Is it appropriate to use disability living allowance benefits in determining affordability?",
  "Are properties subjected to a section 106 restricted covenant or to a Discounted Market get considered?",
  "Are applications accepted if it has an element of distressed sale?",
  "Can dividends income received in retirement be considered when assessing affordability?",
  "Can downsizing be a strategy to secure approval for Interest Only as a repayment option.",
  "Is it possible to redeem a mortgage without incurring penalty if it is still within the product's initial benefit period?",
  "Can payslips be considered as evidence of employment?",
  "Are future pay rises considered when assessing affordability?",
  "What percentage of my shares qualifies me as self-employed?",
  "Is revenue from Employee Benefit Trusts admissible for calculating income?",
  "Are applicants required to submit an employer's reference?",
  "Are earnings from Employment and Support Allowance acceptable when assessing affordability?",
  "Is employment continuity important for applications to be approved?",
  "Are applicants required to meet a minimum time in working with their current employer for the application to be approved?",
  "Is Endowment an approved repayment vehicle when taking Interest Only as a repayment method?",
  "Is it acceptable to take an expatriate with a bad credit history?",
  "Can expatriates working for registered UK firms in British colonies be considered?",
  "Expatriates applying for a Consumer Buy to Let basis are approved?",
  "Is there any restriction on the nations from which expatriates can come from?",
  "Are applications from expatriates who plan to return to the UK shortly get accepted?",
  "Are applications from expatriates working for a multinational firm who want to return to the UK accepted?",
  "are expats who are first-time buyers allowed to apply?",
  "Max Term for expats",
  "Min Term for expats",
  "Max period an expat is allowed to be outside UK",
  "Min and Max income for expats",
  "Maximum and minimum loan for expat",
  "Maximum and minimum age for expat",
  "Minimum and maximum value of a property for expatriates",
  "Can a New Build Flat be acceptable security for expatriates?",
  "Are New Build Houses considered acceptable security for expats?",
  "Can an expat with a PO Box address be considered?",
  "Can Expatriates Remortgage for Debt Consolidation?",
  "Is it possible for a retired Expat to apply and get approved?",
  "Can applications from retired expats be approved?",
  "Can an Ex Council House / Flat be considered security for an expat?",
  "Will it be OK for a current borrower to continue using the same criteria rather than obtaining a new policy?",
  "Is it legal to have an existing second charge on real estate?",
  "Are expats suitable applicants?",
  "Can expenses be considered income in affordability assessments?",
  "Is it possible to extend the mortgage offer?",
  "What is the list of specific products that enable a family member to contribute to the mortgage deposit?",
  "Will a gift deposit from a non-family member be considered?",
  "Can a gift deposit from a family member be considered?",
  "Do you welcome first-time buyers?",
  "Can income from a Fixed Term contract be approved?",
  "Can a flat near a business establishment be considered fit for security?",
  "Can a property with an element of Flying Freehold be approved?",
  "Can lending be available for a Forces Help To Buy Scheme?",
  "Can income from Forces Personnel be considered?",
  "Is it acceptable to present foreign currency income for assessing affordability?",
  "Are former Houses of Multiple Occupation (HMO) suitable security and if so are there any restrictions?",
  "Is a foster care income acceptable?",
  "requirements for obtaining free legal services",
  "Can a Freehold Flat / Maisonette be considered security?",
  "Is an application eligible if it does not have Full Vacant Possession?",
  "Can Further Advance be availed by Existing Borrowers?",
  "Is it possible to consider an applicant that has a history of gambling?",
  "Are there any restrictions on homes with doubling ground rent?",
  "Are there any regulations on homes with escalating ground rent?",
  "Are there restrictions on properties that have increasing ground rent based on the property value?",
  "Are there any regulations for a property with RPI-linked ground rent?",
  "Can Guaranteed Income Payment for Forces be approved when assessing affordability?",
  "Can Guarantors be approved and are there any restrictions?",
  "Guardian Allowance income accepted for affordability assessment?",
  "Will a Hard or soft Footprint be recorded on the applicant's credit file in the event of a DIP?",
  "Do applications for a Help to Buy Equity Loan scheme get approved and are there restrictions?",
  "Is it acceptable to use remortgages to repay the Help To Buy Equity Share Loan in partial or in full?",
  "Conditions for applying Higher Lending Charge",
  "What are the requirements in having Holiday Home applications approved?",
  "Is it okay to use Housing Benefit in determining affordability?",
  "Is lodger income acceptable for the purpose of determining affordability?",
  "Is income from trust funds suitable for evaluating affordability?",
  "Is it possible to use an income multiplier when determining affordability, and what is the maximum loan amount available?",
  "Is income assistance be taken into consideration when determining affordability?",
  "Will an application be processed if there is an ongoing Individual Voluntary Arrangement",
  "How long must the Individual Voluntary Arrangement be discharged before it may be accepted?",
  "Is the Industrial Injuries Disability Benefit considered when determining affordability?",
  "Is it possible to use an inheritance as proof when using interest only as a repayment option?",
  "Can Interest Only be used as a means of repayment?",
  "Acceptable repayment options and eligibility requirements for acquiring an Interest Only Mortgage.",
  "Do Investment Bonds get approved as repayment vehicle when taking Interest Only as a Repayment Method?",
  "Is Investment income taken into consideration in assessing affordability?",
  "Can an ISA be used as a repayment vehicle when taking an interest-only repayment method?",
  "Does employment under IT contractor type get considered and how is the income calculated?",
  "Do properties with Japanese Knotweed get considered as acceptable security and are there restrictions?",
  "Is Job Seekers Allowance considered a reliable income when assessing affordability?",
  "Is it permissible to submit a joint borrower application when only one person is the legal owner, and if so, under what circumstances?",
  "Do applications on the Key Workers Scheme get approved?",
  "Is it permissible for an applicant to acquire land adjacent to his or her own?",
  "Are loans in excess of £500,000 permitted?",
  "Can I lend on a home in England?",
  "Can I lend on a house in Northern Ireland?",
  "Can I lend on a home in Scotland?",
  "Can I lend on a home in Wales?",
  "Can I lend on a house in the Scottish Isles?",
  "Is there a maximum exposure limit in the development or area?",
  "Can applications be approved if the term of the mortgage takes the applicant into retirement? What are the conditions?",
  "Is it possible for a limited company to obtain a loan?",
  "Are Trustees Eligible for Loans?",
  "Is it possible for a self-employed candidate from a Limited Company that has changed to a Sole Trader, be considered?",
  "Is it possible to consider an application from a limited liability partnership?",
  "Are Live Work/Usage restrictions suitable security?",
  "Is LTI cap applied in the assessment of affordability?",
  "Does a purchase from Local Authority Grant Scheme get approved?",
  "Can we rent a room or take a lodger in the property?",
  "Can Londong Weighting or Large Town Allowance be considered as valid income when assessing affordability?",
  "Can maintenance acquired through a private arrangement be considered into the affordability calculation?",
  "Is it permissible for married applicants to apply under a single name?",
  "Are Maternity/Paternity Leave applicants approved, and if so, may their income be utilised to determine affordability?",
  "How old must candidates be at the time of application?",
  "What is the maximum age that applicants are permitted to be at at the end of the mortgage term?",
  "Maximum Loan-to-Value Ratio for a Like-for-Like Remortgage",
  "Maximum LTV for newly constructed flat/house",
  "Are Ex Council Houses and Flats suitable security? Are there restrictions?",
  "Will a Right to Buy Purchase be acceptable? What would be the maximum LTV?",
  "Will a Shared Equity Purchase be acceptable? What would be the maximum LTV?",
  "Will a Shared Ownership Purchase be acceptable? What would be the maximum LTV?",
  "Do Second Hand Flats count as security? Are there restrictions?",
  "Do Second Hand Houses count as security? Are there restrictions?",
  "Remortgages on Right to Buy properties are permissible while they remain in the Pre-Emption phase.",
  "Will a remortgage of a property under the Shared Equity Scheme be accepted? What would be the maximum LTV?",
  "Will a remortgage of a property under the Shared Ownership Scheme be accepted? What would be the maximum LTV?",
  "Are studio apartments permitted?If that is the case, are there any restrictions?",
  "Are Expats permitted to apply on the same terms and with the same LTV as other applicants?",
  "Is it permissible to borrow money against an unencumbered property?",
  "Is raising capital on owned home allowed as a remortgage",
  "Is debt consolidation capital raising permitted, and if yes, what are the restrictions?",
  "Is capital raising for business purposes permitted, and if so, under what conditions?",
  "Is it permissible to raise funds for home improvements? Are there any prerequisites?",
  "Is capital raising permitted for the purpose of acquiring equity shares? Are there any prerequisites?",
  "Is capital raising permissible for the purpose of acquiring BTL property? Are there any prerequisites?",
  "Is it permissible for first-time buyers to borrow up to the Standard Maximum LTV?",
  "Maximum loan-to-value ratio applicable to interest-only mortgages",
  "Maximum Loan-to-Value Ratio applicable to Large Loans",
  "Maximum LTV permitted for a part and part",
  "Are two applicants accepted, and their whole income can be used to determine affordability?",
  "Is there a minimum or maximum property valuation, and does it vary by property type?",
  "Method of repayment of capital and interest, as well as the maximum period",
  "What is the minimum income requirement for a Capital and Interest Repayment applicant?",
  "What is the minimum income requirement for an Interest-Only Repayment applicant?",
  "What is the minimum income requirement for applicants who wish to repay in part or in full?",
  "How much is the minimum loan amount and will it change depending on the applicant or product type?",
  "Minimum floor size required for a Studio Flat in square metres",
  "Required Minimum Mortgage Term",
  "Are applicants with a history of late credit card payments still considered?",
  "Is it still possible to contemplate mixed-use or partially commercial property?",
  "Are applicants with a history of missing mobile payments still considered?",
  "Is it permissible to utilise electronic signatures on Mortgage Declarations and Direct Debits?",
  "How long is a mortgage extension permitted in new construction properties?",
  "When does the mortgage offer valid?",
  "Are applicants who have a secured loan eligible?Under what circumstances?",
  "Is Mortgage Subsidy considered income for the purpose of determining affordability?",
  "Instead of providing Certified papers, it is possible for applicants to be electronically identified by the intermediary.",
  "Which qualifications are suitable for an accountant?",
  "Do self employed applicants need an accountant's certificate?",
  "Can accountant projections be declared as income?",
  "Will an Accountant's reference be sufficient instead of SA302s or an Accountant's certificate?",
  "Is it OK to add the broker's advice fee to the loan amount?",
  "Can the product arrangement fee be charged to the loan amount?",
  "Which fees can be included in the loan amount?",
  "Required fees to be included in the loan amount",
  "Possibility for additional borrowing to the main loan to be considered",
  "Can an existing mortgage remaining with the lender impact the application to a new one?",
  "Can adoption allowance be considered as a source of income?",
  "Are applications from Intermediaries acceptable?",
  "Can the maximum loan be assessed using an affordability calculator?",
  "What are the acceptable schemes for Affordable Housing?",
  "Can applicants pass the assessment if one retires before the end of the mortgage term?",
  "Is compensation from Agency Work acceptable?",
  "Can properties with agricultural ties be suitable security to lend against?",
  "Is the property allowed for Air BnB use?",
  "Will applicants whose sole source of income come from benefits be approved?",
  "Are applicants that continue to stay in linked housing after the mortgage is finished be considered?",
  "Can a Power of Attorney allow a different person to act on the applicant's behalf?",
  "Could an applicant with Diplomatic Immunity be approved?",
  "Can foreign currency income be approved?",
  "Are applicants whose sole income are from property development be approved?",
  "Can an Architect's Certificate be used in place of a Property Warranty?",
  "Can a purchase using an assignable contract be acceptable?",
  "Is an Attendance Allowance recognised as a source of income?'",
  "Will a purchase be considered if the vendor has only owned the property for less than 6 months?",
  "Will it be okay to use an Overdraft facility?",
  "Is it important to present a bank statement in evaluating an application?",
  "When will a discharged bankrupt applicant be considered for application processing?",
  "Can a Barn Conversion property be suited for security",
  "Can a Basement Flat be suited for security?",
  "Property below market value allowed?",
  "Can income from Bereavement Allowance be considered?",
  "Can BISF Construction properties be acceptable security?",
  "Over what period can percentage of regular bonuses be considered?",
  "Over what period can percentage of guaranteed bonuses be considered?",
  "Is borrow back on mortgage products supported?",
  "Are British nationals working outside the country allowed?",
  "Are cash incentives from builders acceptable?",
  "Can a Builder Gifted Deposit be allowed?",
  "Can Builder Part Exchange transactions be approved?",
  "Can income from Bursaries be allowed?",
  "Can Flight Attendants or Cabin Crews apply using their income?",
  "Can mortgage be taken out on a Capital and Interest Only Basis?",
  "Is capital raising OK when remortgaging?",
  "Can a Buy to Let Property be purchased using capital raising?",
  "Can capital raising be used for Business Purposes?",
  "Can capital raising be used for Debt Consolidation?",
  "Will capital raising be considered for purchasing land",
  "Can Car Allowance be used as source of income?",
  "Do you allow Carer's Allowance as a source of income?",
  "Is Drawdown / Cash Reserve Facility included with any of the products?",
  "What is required for Certifying Documents?",
  "Is Child Benefit accepted as a source of income?",
  "Do Child Maintenance payments have an impact on affordability assessment",
  "Can Non Court ordered Child Maintenance payments be accepted as a source of income?",
  "Is Child Tax Credits an acceptable income?",
  "Will Childcare cost have an impact in assessing affordability?",
  "Will Childcare Vouchers have an impact in assessing affordability?",
  "Do income from CIS contractors get approved?",
  "Does income from Commission or Profit Related pay get accepted?",
  "Can commission be acceptable as an only source of income?",
  "Can salary from a Company or Occupational Pension be accepted?",
  "Can an overseas based company director apply for a mortgage?",
  "Is under valued Concessionary Purchase, Deed of Gift or Inter-family Sale allowed?",
  "Can Consent to Let be granted on a residential property?",
  "Is Constant Attendance Allowance an acceptable income?",
  "What are the types of Non Standard Construction allowed?",
  "Do you accept Consumer Buy to Let applications?",
  "Can applications from contract workers be considered?",
  "Can convicted applicants be considered?",
  "Do applicants with Unsatisfied County Court Judgements (CCJs) be considered?",
  "Are Satisfied County Court Judgements (CCJs) acceptable?",
  "Will it be acceptable to use Court ordered child maintenance payments as a form of income?",
  "Are missed payments on Credit Cards / Store Cards / Mail Order catalogues a concern?",
  "Will Credit card balance or monthly payment impact affordability assessments?",
  "Policy for disregarding credit commitments to be repaid when assessing affordability",
  "Will Credit Commitment that is less than 12 months remaining be acceptable when assessing affordability?",
  "Are you using Credit Reference Agency",
  "Is there a product that can be used to assist the applicant in repairing their credit profile?",
  "Are applicants Credit Scored or are they manually underwritten?",
  "Are applicants Manually Underwritten or are they Credit Scored?",
  "Do you consider applicants with criminal record?",
  "Are they still allowing applicants with a history of Unsecured Loan Arrears to apply",
  "Can applicants with missed or late utility bills payments be considered?",
  "Will an account dated over a year ago be considered?",
  "Can a Homebuyers or Structural / Full Building Valuation Report be coursed through the lender?",
  "Is Vendor Gifted Deposit allowed?",
  "Can Vested Share Income be used when assessing affordability?",
  "Can a War Widow's Pension income be used when assessing affordability?",
  "Can a Widow's Pension income be used when assessing affordability?",
  "Can a Widowed Parent Allowance income be used when assessing affordability?",
  "Can Wimpey No Fines / Laing Easi Form construction properties be approved?",
  "What proof of income is needed if an applicant is currently employed in their Family Business?",
  "Can Working Tax Credit Benefits be used when assessing affordability?",
  "Are they processing applications of Expatriates for a Holiday Let?",
  "Is a Farm of Small Holding suitable for security?",
  "Are loan Repayments allowed to be rolled up in the Gross Loan amount?",
  "Is Sale of Property considered the only Exit Route?",
  "Allowed Exit Strategy",
  "Does a newbie in Property Development have a chance in applying for a Development Loan?",
  "Do you have specific products available for buying a property in an Auction",
  "Will it be okay if you get another Bridging Loan to replace an existing Bridging Loan?",
  "Is lending permissible if a change in property usage is required?",
  "Is it necessary to have Planning Permision in order to be considered for a Bridging Loan?",
  "Is it legal to refinance a property acquired less than six months ago?",
  "Can an application be approved if there is an ongoing / current Debit Management Plan?",
  "How long does it take for a Debt management plan to be completed and approved?",
  "How long does it take to process and accept a debt relief order?",
  "Do applications with an ongoing / current Debt Relief Order get considered?",
  "Can clients with a satisfied defaults be considered?",
  "Can clients with an unsatisfied defaults be considered?",
  "What is new Build House?",
  "What is the new Build Flat?",
  "Can a dependent relative buy or refinance the property?",
  "Can dependents be excluded from the affordability calculation?",
  "Is a deposit from a Limited Company considered?",
  "What are the acceptable sources of deposit?",
  "Deposit sources that are unacceptable",
  "Could there be a choice when direct debit payment could be taken?",
  "Is it permissible to consider the Director's Dividends when determining affordability?",
  "Is the Director's Loan suitable in terms of determining affordability?",
  "Is it appropriate to use disability living allowance benefits in determining affordability?",
  "Are properties subjected to a section 106 restricted covenant or to a Discounted Market get considered?",
  "Are applications accepted if it has an element of distressed sale?",
  "Can dividends income received in retirement be considered when assessing affordability?",
  "Can downsizing be a strategy to secure approval for Interest Only as a repayment option.",
  "Is it possible to redeem a mortgage without incurring penalty if it is still within the product's initial benefit period?",
  "Can payslips be considered as evidence of employment?",
  "Are future pay rises considered when assessing affordability?",
  "What percentage of my shares qualifies me as self-employed?",
  "Is revenue from Employee Benefit Trusts admissible for calculating income?",
  "Are applicants required to submit an employer's reference?",
  "Are earnings from Employment and Support Allowance acceptable when assessing affordability?",
  "Is employment continuity important for applications to be approved?",
  "Are applicants required to meet a minimum time in working with their current employer for the application to be approved?",
  "Is Endowment an approved repayment vehicle when taking Interest Only as a repayment method?",
  "Is it acceptable to take an expatriate with a bad credit history?",
  "Can expatriates working for registered UK firms in British colonies be considered?",
  "Expatriates applying for a Consumer Buy to Let basis are approved?",
  "Is there any restriction on the nations from which expatriates can come from?",
  "Are applications from expatriates who plan to return to the UK shortly get accepted? '",
  "Are applications from expatriates working for a multinational firm who want to return to the UK accepted?",
  "are expats who are first-time buyers allowed to apply?",
  "Max Term for expats",
  "Min Term for expats",
  "Max period an expat is allowed to be outside UK",
  "Min and Max income for expats",
  "Maximum and minimum loan for expat",
  "Maximum and minimum age for expat",
  "Minimum and maximum value of a property for expatriates",
  "Can a New Build Flat be acceptable security for expatriates?",
  "Are New Build Houses considered acceptable security for expats?",
  "Can an expat with a PO Box address be considered?",
  "Can Expatriates Remortgage for Debt Consolidation?",
  "Is it possible for a retired Expat to apply and get approved?",
  "Can applications from retired expats be approved?",
  "Can an Ex Council House / Flat be considered security for an expat?",
  "Will it be OK for a current borrower to continue using the same criteria rather than obtaining a new policy?",
  "Is it legal to have an existing second charge on real estate?",
  "Are expats suitable applicants?",
  "Can expenses be considered income in affordability assessments?",
  "Is it possible to extend the mortgage offer?",
  "What is the list of specific products that enable a family member to contribute to the mortgage deposit?",
  "Will a gift deposit from a non-family member be considered?",
  "Can a gift deposit from a family member be considered?",
  "Do you welcome first-time buyers?",
  "Can income from a Fixed Term contract be approved?",
  "Can a flat near a business establishment be considered fit for security?",
  "Can a property with an element of Flying Freehold be approved?",
  "Can lending be available for a Forces Help To Buy Scheme?",
  "Can income from Forces Personnel be considered?",
  "Is it acceptable to present foreign currency income for assessing affordability?",
  "Are former Houses of Multiple Occupation (HMO) suitable security and if so are there any restrictions?",
  "Is a foster care income acceptable?",
  "requirements for obtaining free legal services",
  "Can a Freehold Flat / Maisonette be considered security?",
  "Is an application eligible if it does not have Full Vacant Possession?",
  "Can Further Advance be availed by Existing Borrowers?",
  "Is it possible to consider an applicant that has a history of gambling?",
  "Are there any restrictions on homes with doubling ground rent?",
  "Are there any regulations on homes with escalating ground rent?",
  "Are there restrictions on properties that have increasing ground rent based on the property value?",
  "Are there any regulations for a property with RPI-linked ground rent?",
  "Can Guaranteed Income Payment for Forces be approved when assessing affordability?",
  "Can Guarantors be approved and are there any restrictions?",
  "Guardian Allowance income accepted for affordability assessment?",
  "Will a Hard or soft Footprint be recorded on the applicant's credit file in the event of a DIP?",
  "Do applications for a Help to Buy Equity Loan scheme get approved and are there restrictions?",
  "Is it acceptable to use remortgages to repay the Help To Buy Equity Share Loan in partial or in full?",
  "Conditions for applying Higher Lending Charge",
  "What are the requirements in having Holiday Home applications approved?",
  "Is it okay to use Housing Benefit in determining affordability?",
  "Is lodger income acceptable for the purpose of determining affordability?",
  "Is income from trust funds suitable for evaluating affordability?",
  "Is it possible to use an income multiplier when determining affordability, and what is the maximum loan amount available?",
  "Is income assistance be taken into consideration when determining affordability?",
  "Will an application be processed if there is an ongoing Individual Voluntary Arrangement",
  "How long must the Individual Voluntary Arrangement be discharged before it may be accepted?",
  "Is the Industrial Injuries Disability Benefit considered when determining affordability?",
  "Is it possible to use an inheritance as proof when using interest only as a repayment option?",
  "Can Interest Only be used as a means of repayment?",
  "Acceptable repayment options and eligibility requirements for acquiring an Interest Only Mortgage.",
  "Do Investment Bonds get approved as repayment vehicle when taking Interest Only as a Repayment Method?",
  "Is Investment income taken into consideration in assessing affordability?",
  "Can an ISA be used as a repayment vehicle when taking an interest-only repayment method?",
  "Does employment under IT contractor type get considered and how is the income calculated?",
  "Do properties with Japanese Knotweed get considered as acceptable security and are there restrictions?",
  "Is Job Seekers Allowance considered a reliable income when assessing affordability?",
  "Is it permissible to submit a joint borrower application when only one person is the legal owner, and if so, under what circumstances?",
  "Do applications on the Key Workers Scheme get approved?",
  "Is it permissible for an applicant to acquire land adjacent to his or her own?",
  "Are loans in excess of £500,000 permitted?",
  "Can I lend on a home in England?",
  "Can I lend on a house in Northern Ireland?",
  "Can I lend on a home in Scotland?",
  "Can I lend on a home in Wales?",
  "Can I lend on a house in the Scottish Isles?",
  "Is there a maximum exposure limit in the development or area?",
  "Can applications be approved if the term of the mortgage takes the applicant into retirement? What are the conditions?",
  "Is it possible for a limited company to obtain a loan?",
  "Are Trustees Eligible for Loans?",
  "Is it possible for a self-employed candidate from a Limited Company that has changed to a Sole Trader, be considered?",
  "Is it possible to consider an application from a limited liability partnership?",
  "Are Live Work/Usage restrictions suitable security?",
  "Is LTI cap applied in the assessment of affordability?",
  "Does a purchase from Local Authority Grant Scheme get approved?",
  "Can we rent a room or take a lodger in the property?",
  "Can Londong Weighting or Large Town Allowance be considered as valid income when assessing affordability?",
  "Can maintenance acquired through a private arrangement be considered into the affordability calculation?",
  "Is it permissible for married applicants to apply under a single name?",
  "Are Maternity/Paternity Leave applicants approved, and if so, may their income be utilised to determine affordability?",
  "How old must candidates be at the time of application?",
  "What is the maximum age that applicants are permitted to be at at the end of the mortgage term?",
  "Acceptable Maximum Loan Amount",
  "Maximum Loan-to-Value Ratio for a Like-for-Like Remortgage",
  "Maximum LTV for newly constructed flat/house",
  "Are Ex Council Houses and Flats suitable security? Are there restrictions?",
  "Will a Right to Buy Purchase be acceptable? What would be the maximum LTV?",
  "Will a Shared Equity Purchase be acceptable? What would be the maximum LTV?",
  "Will a Shared Ownership Purchase be acceptable? What would be the maximum LTV?",
  "Do Second Hand Flats count as security? Are there restrictions?",
  "Do Second Hand Houses count as security? Are there restrictions?",
  "Remortgages on Right to Buy properties are permissible while they remain in the Pre-Emption phase.",
  "Will a remortgage of a property under the Shared Equity Scheme be accepted? What would be the maximum LTV?",
  "Will a remortgage of a property under the Shared Ownership Scheme be accepted? What would be the maximum LTV?",
  "Do studio flats count as security and are there restrictions?",
  "Are Expats permitted to apply on the same terms and with the same LTV as other applicants?",
  "Is it permissible to borrow money against an unencumbered property?",
  "Is raising capital for a home that is owned outright allowed as a remortgage",
  "Will raising capital for Debt Consolidation be accepted and are there restrictions?",
  "Will raising capital for Business Purposes be accepted and are there restrictions?",
  "Will raising capital for Home Improvements be accepted and are there any restrictions?",
  "Will raising capital for the Purchase of Equity Share be accepted and are there any restrictions?",
  "Is capital raising permissible for the purpose of acquiring BTL property? Are there any prerequisites?",
  "Is it permissible for first-time buyers to borrow up to the Standard Maximum LTV?",
  "Maximum loan-to-value ratio applicable for interest-only mortgages",
  "Maximum Loan-to-Value Ratio applicable to Large Loans",
  "Maximum LTV permitted for a part and part or split repayment method mortgage",
  "Are two applicants accepted and can both their income can be used in determining affordability?",
  "Is there a minimum or maximum property valuation, and does it vary by property type?",
  "Is there a maximum term for a capital and interest repayment method",
  "What is the minimum income requirement for a Capital and Interest Repayment applicant?",
  "What is the minimum income requirement for an Interest-Only Repayment applicant?",
  "What is the minimum income requirement for applicants who wish to repay in part or in full?",
  "How much is the minimum loan amount and will it change depending on the applicant or product type?",
  "Minimum floor area required for a Studio Flat in square meters",
  "Required Minimum Mortgage Term",
  "Are applicants with a history of late credit card payments still considered?",
  "Can properties that have mixed use or have a part commercial element be counted on as security?",
  "Do applicants that miss payments on communication contracts still get accepted?",
  "Can Mortgage Declarations and Direct Debits be electronically signed?",
  "How long can an extension to the mortgage offer be valid for New Build properties?",
  "Does a mortgage offer expire?",
  "Do applicants with arrears on a mortgage or secured loan get consideration and are there restrictions?",
  "Will a Mortgage Subsidy be considered income when assessing affordability?",
  "Will a mortgage term of Years and Months be approved?",
  "Can a property construction of Mundic Block be accepted and at what grade?",
  "Can a Limited Company Director use Net Profit as income?",
  "Will LTV be restricted if the property is a New Build Flat?",
  "Will LTV be restricted if the property is a New Build House?",
  "Is it acceptable to purchase a newly constructed property off the plan?",
  "What requirements apply for the usage of New Build Property incentives?",
  "Which warranties and providers of warranties are permissible for new construction properties?",
  "Are applications allowed if the property will have occupants beyond the age of 17",
  "Can non-EU, non-EEA, non-UK foreign nationals apply and under what conditions?",
  "Is a staggered and non-simultaneous sale and completion acceptable?'",
  "Can properties declared as being under non-standard construction be approved as suitable security?",
  "Are non-UK taxpaying citizens acceptable?'",
  "Are high-rise apartment blocks allowed, and what is the maximum floor count',",
  "Can the pay of nursing bank employees be used when determining affordability',",
  "Do products have an option for offset mortgages',",
  "Can a mortgage be considered if the security address has an Overage Clause / Agreement in place?'",
  "Is there an overpayment facility for products and if so, under what conditions?'",
  "Can irregular/fluctuating overtime be taken into account when determining affordability',",
  "Can Regular / Guaranteed Overtime be approved when assessing affordability?",
  "Can income paid in cash be acceptable? Are there any additional requirements to fill?",
  "What can be the acceptable percentage per repayment method on Part and Part / Split?",
  "Can applicants that availed a Payday loan in the past be accepted?",
  "Is it possible to take a payment holiday?",
  "What documents are required for a Payslip to be considered an income source?",
  "Is it possible to use pension to pay off an interest-only debt?",
  "Are pension contributions ignored when assessing affordability?",
  "Is it acceptable to use Private Pension Credits in evaluating affordability?",
  "Are Period / Listed / Grade properties considered security?",
  "Can Health Insurance be used for addordability assessment?",
  "Is it prohibited to use personal finance in business for affordability assessment?",
  "Can Personal Independent Payment Benefit be used for affordability assessment?",
  "Is piece work an acceptable form of job and a source of income?",
  "Is it allowed to purchase a property that has Restrictive or Unusual Planning Permits?",
  "Can the products be moved to a new property?",
  "Is it possible to apply for a loan from any postcode area?",
  "Can Pre-Reinforced Concrete properties be suitable security? Are there certificates required?",
  "Can Price Work be an acceptable employment type and income source?",
  "Can a Private Sale Purchase be acceptable without the assistance of Estate Agents",
  "Can an applicant be considered if under probation?",
  "Will a product split on the same mortgage be approved?",
  "Do Product Transfer applications using an intermediary get approval for existing borrowers?",
  "Is it possible to consider the application of a Professional Landlord?  If so, what are the requirements?",
  "What requirements are needed for professional sports persons / athletes to be considered?",
  "Is it possible to apply without submitting a proof of deposit?",
  "Will applications missing Proof of Personal ID be approved?",
  "Will applications with missing proof of address / residence be acceptable?",
  "Can an application for purchasing a property at an auction be considered?",
  "Do applications for purchasing a property using an Investment or Property Club be considered?",
  "Do properties with Annex get considered for security?",
  "Are properties with Outbuildings suitable for security?",
  "What is the maximum acreage for properties with substantial land and are these suitable for security?",
  "Is it acceptable to purchase a property for a Family Member to live in?",
  "Does a Limited Company have a right to purchase?",
  "How much does it cost for a New Build property to be re-inspected?",
  "Is Reduced Earning Allowance income supported for affordability assessment?",
  "Will affordability assessment proceed if income to be used has been reduced for a specific period?",
  "Are applications for regulated buy-to-let properties acceptable?",
  "Can a remortgage be used for transfer of equity or for divorce settlement?",
  "Can capital raising as part of a remortgage be approved?",
  "Can a remortgage be used to repay a bridging loan?",
  "Do applicants with rent arrears get approved?",
  "Does the Rent-to-Buy Scheme support purchase applications?",
  "Can rental income be considered in assessing affordability?",
  "Can applicants who have had their property repossessed or surrendered be considered?",
  "Can applicants that have lived in the UK for less than 3 years be considered?",
  "Is it acceptable to get an application if another Residential mortgage would remain open upon completion?",
  "Can self-employed applicants' retained profit be used to evaluate affordability?",
  "What types of repayment vehicles and strategies are authorised for Retirement Interest Only Lending?",
  "Can Non Guaranteed Future income be considered for assessing affordability for Retirement Interest Only?",
  "Can better terms for Retirement Interest Only mortgages be considered if Last Power of Attorney is available?",
  "Can Benefit Income be approved when assessing affordability for joint applicants - Retirement Interest Only?",
  "Is Family Assistance Income accepted in assessing affordability for joint applicants - Retirement Interest Only",
  "Is Investment Income acceptable in assessing affordability for joint applicants - Retirement Interest Only",
  "Is Life Cover acceptable in judging affordability for joint applicants - Retirement Interest Only",
  "Is Pension Income considered in assessing affordability for joint applicants - Retirement Interest Only",
  "Is Pension Income considered in assessing affordability for joint applicants - Retirement Interest Only",
  "Age limit for Retirement Interest Only applicants",
  "What is the maximum age at the end of the term of the Retirement Interest Only mortgage?",
  "What is the maximum retirement interest-only loan amount?",
  "What is the maximum loan-to-value (LTV) for retirement interest-only loans?",
  "Is a like-for-like remortgage acceptable for Retirement Interest Only?",
  "Will a remortgage with capital raising allow for Retirement Interest Only, and what is the maximum LTV?",
  "What is the maximum term available for a Retirement Interest Only Mortgage?",
  "What is the minimum age allowed for applying Retirement Interest-Only Loans?",
  "What is the smallest loan amount possible for Retirement Interest Only lending?",
  "What is the minimum amount of equity required for Retirement Interest Only lending?",
  "Is there a minimum income necessary for Retirement Interest Only Lending?",
  "What is the minimum property value approved for RIOL?",
  "Is there a minimum acceptable term for Retirement Interest Only Lending?",
  "Is it possible to accept RIO lending for any new business products?",
  "Is a No Negative Equity Guarantee available for Retirement Interest Only Lending?",
  "Is it acceptable to accept a purchase made under the Right to Buy Scheme?",
  "Is it possible to ignore Salary Sacrifice deductions from the assessment of affordability?",
  "Would a sale of a Foreign Property or Asset be seen as a Repayment Vehicle for Interest only?",
  "Can a purchase of a Residential property be considered as a Repayment Vehicle for Interest Only",
  "Can the sale of other assets be utilised as an interest-only repayment vehicle?",
  "Can the sale of the mortgaged property be utilised as an interest-only repayment vehicle?",
  "Is it possible to use the money from the sale of an investment or buy-to-let property as an interest-only repayment method.",
  "Can the savings be used as a repayment vehicle for interest only?",
  "Can moving to a Capital and Interest / Repayment afterwards be considered as an Interest Only Repayment Vehicle?",
  "Can seasonal employment and income be included when considering affordability?",
  "Will an application be examined if the second candidate does not have a source of income?",
  "Can an application for a Second Home / Pied a Terre be recognised, and what criteria will apply?",
  "Will income from a second job be considered in affordability assessment?",
  "Is it allowed to apply for a self-build property?",
  "Are self-employed candidates with one year's worth of accounts allowed?",
  "Are applicants who are self-employed and experiencing a decline in profits permitted?",
  "Will service charges and maintenance fees be disregarded when assessing affordability?",
  "Are applications for Shared Ownership Scheme accepted?",
  "Is it possible to explore a remortgage to staircase / acquire a larger stake of the property?",
  "Is it feasible to find Sharia-compliant Islamic products?",
  "Can Shift Allowance income be taken into account when assessing affordability?",
  "Are short-term loans offered?",
  "Do you accept applicants who are in a short-term renewable contract?",
  "Do you accept Self-Employed Sole Traders as applicants?",
  "Is it possible for a Sole Trader who has changed to a Limited Company be recognised, and if so, under what criteria?",
  "Is it possible to include Special Duties income when determining affordability?",
  "Are there specific products or criteria for Applicants classified as Professionals?",
  "Is it possible to allow for the division of Title Deeds at the time of completion?",
  "Is Spousal Maintenance an acceptable income in assessing affordability?",
  "Is State Pension Benefit an acceptable income in assessing affordability?",
  "Can steel-framed properties be acceptable?",
  "Is it permissible to use stocks and shares as a repayment mechanism for interest-only loans?",
  "Will a student debt be taken into account when determining affordability?",
  "Will a Sub-Sales / Contract Reassignment application be taken into account?",
  "Will subcontractors be allowed to apply? What are the requirements?",
  "Is there a specific set of criteria for assessing Teachers?",
  "Is temporary employment acceptable, and may it be used in assessing affordability?",
  "Can TImber Framed properties be approved for construction type and are there restrictions?",
  "Do applicants on a Visa get considered? What are the acceptable requirements?",
  "Are unlimited Aggregate Borrowing with the Lender / Group approved?",
  "Will applicants who are self-employed and have less than two years of trading experience be considered?",
  "Is there consideration for Travel Allowance income when determining affordability?",
  "Are travel expenses excluded from the affordability calculation?",
  "Can a mortgage be acceptable in joint names with only 1 named on the Deeds?",
  "Are properties with Cross Leases or Tyneside Flats approved?",
  "Are there considerations for applicants with less than 3 years UK residency?",
  "Are self employed applicants under an Umbrella Company accepted?",
  "Is Free legal offered for an unencumbered property? Will it be treated as a Purchase or Remortgage?",
  "Are Unit Trusts or OEIC considered suitable repayment vehicle for Interest Only?",
  "Is Universal Credit considered income under affordability assessment?",
  "Do Zero Hour Contract applicants get approved?",
  "Are applications considered if it does not have Assured Shorthold Tenancy Agreement?",
  "Is it acceptable to have accounts that are more than a year old eligible?",
  "Is it possible for the lender to provide for a Homebuyers' or Structural/Full Building Valuation Report?",
  "I vendor-gifted deposit allowed?",
  "Does the use of vested share income for evaluation of the affordability?",
  "When determining affordability, can the income of a war widow be taken into consideration?",
  "Can expatriates who are first-time landlords be approved?",
  "Will an application for a house be approved if the applicant is an Expatriate with Multiple Occupations?",
  "Will Expatriates classed as Professional Portfolio Landlords be considered?",
  "How are experienced landlords defined and are they given consideration?",
  "How is the application reviewed if one of the applicants is a First-Time Buyer and the other one is a Home Mover?",
  "Can first-time landlords apply?",
  "Do properties with Flat Roofs get consideration as security?",
  "Is a converted flat be acceptable as security",
  "Do Flats with Deck Access get considered?",
  "Will a Freehold property be acceptable security if it has Multiple Flats?",
  "Are applications for holiday let allowed?",
  "Is it acceptable to have multiple occupants in the same house?",
  "Could it be possible to allow lending solely against land?",
  "Do companies process applications if no letting agents are involved?",
  "Can a property that requires minor renovations be considered?",
  "Rental computation for Limited Companies",
  "Is it possible to accept limited companies that are not registered as special purpose vehicles?",
  "Will a remortgage with capital raising be considered when purchasing land?",
  "Are there rules on the number of bedrooms allowed in a property?",
  "Will properties that have more than one kitchen be considered?",
  "Max number of tenants allowed in one tenancy",
  "Can properties that are in a Freehold Block be classified as a type of security?",
  "Will Multiple Tenancy Agreements / Room By Room letting be allowed?",
  "Can bedsits be classified as security",
  "Will a property that has Overhead cables be suitable for security?",
  "Is there a maximum number of properties that a Portfolio Landlord can own?",
  "How are rental assessments performed for properties in the background?",
  "Will Portfolio Landlords be permitted to utilise their own income to make up for rental income gaps?",
  "Will properties that have been impacted by subsidence or landslides be assesed?",
  "Will a property near a landfill site be considered?",
  "Does purchasing a property that is a part of the government's Green Deal scheme allowed?",
  "Must the property be in a habitable / lettable condition upon completion of mortgage?",
  "Do you accept applications for regulated buy to let properties?",
  "Will a purchase be allowed if it would rent the property back to the vendor?",
  "Will payment from applicant's income be acceptable to cover rental gaps?",
  "Will an application be acceptable if the current tenant would be allowed to reside in the property upon completion?",
  "Are students allowed to rent a property?",
  "Do properties let to DSS / Applicants on Benefits get approved?",
  "Minimum income requirement for single or joint applications",
  "How is rental income calculated?",
  "What ICR are used for a Higher Rate Taxpayer Applicant?",
  "Is there a minimum lease period required for a Leasehold Property?",
  "Is there a minimum percentage share when purchasing a property?",
  "Will Shared Ownership be approved if not requested through a Housing Association?",
  "Will Shared Ownership be accepted if 100% Staircasing is unavailable?",
  "Can Underpayments or Payment Holidays be approved?",
  "Will they consider self build application without detailed project costing?",
  "Can funds be released before a Building Warrant is received?",
  "Can an application be approved without restriction on the Building or Property use or class?",
  "Are custom Build applications acceptable?",
  "Are extensions to the mortgage offer allowed for Custom build applications?",
  "Will an applicant's request to determine when stage payments are released be considered?",
  "Can Guarantors be approved for Custom Build Applications?",
  "Is it permissible to pay for construction using interest only?",
  "Will lending be considered when it is against the purchase of land?",
  "Is there a maximum loan applied for a Custom Build application?",
  "Is the maximum Loan to Cost being considered?",
  "Are there acceptable warranties and should it be in place upon completion of the Custom Build?",
  "When do funds become available during a Custom Build?",
  "Does one set of Solicitors representing both the applicant and lender be acceptable?",
  "Will Site Insurance be needed for the build and are there minimum requirements?",
  "Is Valuation / Re inspection needed at all stages of the build?",
  "Can Landlocked properties with Easement / Rights of Way be considered?",
  "Minimum applicable stage payments",
  "Will Self Build applications be approved without having Outlined Planning Permission?",
  "Can Stage Payments be made in Advance instead of in arrears?",
  "Can stage Payments be made in Arrears instead of in advance?",
  "Can a request to know when the next schedule of funding will be released be granted?",
  "Can tranche funding be given out at each level without the valid certificate?",
  "Should the Arrangement Fee or Broker Fee be added to the loan on top of the product's maximum LTV?",
  "Is there a maximum Debt to Income Ratio that should be considered when calculating affordability?",
  "If the current lender doesn't want to charge a second fee, can an application still be approved?",
  "Will properties with Restrictive Covenant / Usage be acceptable security?",
  "Will properties with Solar Panels be suitable security?",
  "Are getting cash reserves or a facility for additional funds allowed?",
  "Is there accessible Inheritance Protection Guarantee?",
  "Are there accessible plans for home renovation?",
  "Do Home Reversion Plans come with Early Vacancy Guarantee?",
  "Are applications accepted in cases where Rent Payments can escalate on Home Reversion Plans?",
  "Are applications accepted in cases where Rent Payments are guaranteed to remain fixed on Home Reversion Plans?",
  "Will there be a maximum percentage release on Home Reversion Plans?",
  "Will applications for Home Reversion Plans be approved when there are no Ongoing Vendor Obligations?",
  "Will applications where Rent payments may stop on Home Revesion Plans be approved?",
  "Will applications where the rent payments may be reduced on a Home Reversion Plan be accepted?",
  "Acceptable rent payable within a plan's lifetime for Home Reversion Plans",
  "Will a property buy back be considered on Home Reversion Plans?",
  "Are there any Lifetime Mortgages available?",
  "Will a higher borrowing be available for applicants with medical condition that shortens life expectancy?",
  "Is there an available No Negative Equity Guarantee?",
  "Is Optional Partial Repayments allowed?",
  "Is there a withdrawal limit from a cash reserve facility?",
  "Can Equity Release on a Buy to Let property be approved?",
  "Can Commonhold Tenure properties be approved?",
  "Is lending allowed on a Commercial property?",
  "Will an application for Development Bridging be approved if it is against a Commercial Property?",
  "Is it permissible to apply for Conversion development?",
  "Is there an accepted Percentage Cost to Profit Margin?",
  "Are First Time Developers allowed to apply?",
  "Are applications from ground-up developments considered?",
  "Are Joint Venture Developments allowed?",
  "Max Loan to Cost for Bridging Development",
  "Max Gross Development Value LTV based on the End Value for Bridging Development",
  "Max allowable LTV on Acquisition for Bridging Development",
  "Minimum and Maximum term for Bridging Development",
  "Do applications for Part Completed Developments get accepted?",
  "Can a Second Charge be approved?",
  "Do you allow applications for properties with Outline planning permission",
  "Can the Exit Fee be computed against the loan instead of the End Gross Development Value?",
  "Will bridging Development Exit products be offered?",
  "Will applications be secured for Land for Development Finance?",
  "Can regulated applications be accepted?",
  "Will a property that needs to undergo extreme renovation can be considered?",
  "Can the Interest charged be maintained from the Gross Loan amount?",
  "Do you accept Electronic ID instead of Certified Documents?",
  "Are there any list of qualification of an eligible accountant?",
  "Do you require Accountant's Certificate for self employed applicant?",
  "Can I present an Accountant's projection as income?",
  "Do you accept Accountant's reference instead of SA302s?",
  "Is broker's advise fee can be added to the loan amount?",
  "Product arrangement fee",
  "what are the fees included in the loan amount?",
  "I want to know which fees I must include in the Loan Amount",
  "Do you allowed to have additional loan to the main loan?",
  "Existing mortgage of lender still acceptable after completion of new mortgage?",
  "Do you accept Adoption Allowance as income?",
  "Do you know if an Intermediary's own application can be presented?",
  "Is an Affordability Calculator used to figure out how much you can borrow?",
  "What kind of affordable housing programmes are acceptable?",
  "How will applicants be assessed if they retire before the mortgage term finishes?",
  "Is income from Agency Work allowed?",
  "Agricultural tied properties suitable to lend against?",
  "can the property be for rent like Air Bnb?",
  "Are applicants whose sole source of income is from benefits will be eligible?",
  "Will applicants who will continue to reside in tied accommodation once the mortgage is settled be considered?",
  "Can a person having Power of Attorney honored to act on the applicant's behalf?",
  "will an applicant with diplomatic immunity be accepted?",
  "is foreign currency allowed?",
  "Will it be okay if the applicant main source of income came from Property Development?",
  "Can an Architect's Certificate be used in substitute of a Warranty for the property",
  "Is it okay to purchase using Assignable contract?",
  "Do you know if an Attendance allowance can be used as proof of income?",
  "Is it legible to purchase a property that has been owned by the vendor for less than 6 months?",
  "Do you allow to use Overdraft facility?",
  "Will you required a bank statement when evaluating an application?",
  "Do you accept discharged bankrupt applicants?",
  "Do you think Barn Conversion property is secure?",
  "Is a Basement Flat safe and secure?",
  "Do you accept property below its market value?",
  "Bereavement Allowance as income allowed?",
  "Is BISF Construction properties supported?",
  "Information about Regular bonuses",
  "Information about Guaranteed bonuses",
  "Do you support Borrow Back on your mortgages?",
  "Is British national working overseas acceptable?",
  "Do you allow accepting cash incentive from builders?",
  "Are you accepting Builder Gifted Deposit?",
  "Do you accept Builder Part Exchange?",
  "Can i use the income from Bursaries?",
  "Can I use income if I'm a Flight Attendant or Cabin Crew",
  "Take out a Capital and Interest Only Basis Mortgage",
  "Is Capital Raising allowed while remortgaging?",
  "Is Capital Raising allowed when buying Buy To Let Property",
  "Is Capital Raising allowed for Business Purposes",
  "Is Capital Raising allowed for Debt Consolidation",
  "Is Capital Raising allowed when purchasing land",
  "Are you accepting Car Allowance",
  "Are you allowing Carer's Allowance",
  "Do you offer Drawdown / Cash Reserve Facility",
  "What is needed when certifying documents?",
  "Is income of Child Benefit allowed?",
  "Relationship between Child Maintenance payments and Affordability assessment",
  "Do you accept Non Court ordered Child Maintenance paymentsas income?",
  "Do you accept Child Tax Credits as income?",
  "Relationship between Childcare cost and Affordability assessment",
  "Relationship between Childcare vouchers and Affordability assessment",
  "Is income from CIS Contractors acceptable?",
  "Is Commission or Profit Related an acceptable income?",
  "Are you accepting commission only basis income?",
  "Are you accepting Company or Occupational Pension",
  "Mortgages for Overseas company director allowed?",
  "Are you accepting under valued Concessionary Purchase, Deed of Gift or Inter-family Sale?",
  "Do you grant Consent to Let residential property",
  "Do you accept Constant Attendance Allowance as income?",
  "What kinds of Non-Standard Buildings are permitted?",
  "Is Consumer Buy to Let application allowed?",
  "Income from Contract Workers allowed?",
  "Are they processing the application of an applicant that has Conviction?",
  "Are you accepting Unsatisfied County Court Judgements (CCJ)?",
  "Are you accepting Satisfied County Court Judgements (CCJ)?",
  "Do you accept income from Court ordered Child Maintenance payments?",
  "Missed payments on Credit Cards / Store Cards / Mail Order okay?",
  "Can credit card balance or monthly payment assess my affordability",
  "Repaid Credit Commitments be disregarded from Affordability",
  "Are you accepting Credit Commitment with less than 12 months?",
  "Is Credit Reference Agency being used?",
  "Do you offer product that can help the applicant repair their credit profile?",
  "Manually underwritten applicants",
  "Credit scored or manually underwritten applicants?",
  "Is applicant with criminal record allowed to apply?",
  "Do you consider applicants who have a history of Unsecured Loan Arrears?",
  "Will they consider an applicant who has a history of late or missing utility bill payments?",
  "Will they take the application into consideration if the only account you have access to is over a year old?",
  "Can the lender arrange a Homebuyers or Structural / Full Building Valuation Report?",
  "Can I use Vendor Gifted Deposit",
  "Is it allowed to use vested share income when assessing affordability?",
  "War Widow's pension will be use in assessing for affordability",
  "Widow's pension will be use in assessing for affordability",
  "Widowed Parent allowance will be use in assessing for affordability",
  "Do you allow properties built with Wimpey No Fines or Laing Easi Form concrete?",
  "Required document to be presented as proof of income when employed in my Family Business",
  "Do you consider Working Tax Credit Benefits while assessing affordability?",
  "Will they accept an Expatriation for a Holiday Let application?",
  "Do you accept Farm or Small Holding as a security?",
  "Can you roll up loan repayments to gross loan amount?",
  "Do you think a Sale Property can be presented for Exit Route?",
  "What are the allowed Exit Strategy?",
  "Is Development Loan possible if the applicant have no experience in Property Development?",
  "Are there any products for acquiring a property at auction?",
  "Am I allowed to replace Bridging Loan with another Bridging Loan?",
  "Can loans be authorized where a change in property usage is required?",
  "Do I have to present Planning Permission when applying Bridging Loan?",
  "Is remortgaging property purchased last 6 months acceptable?",
  "Do you allow application if there's a current Debt Management Plan",
  "How long does the Debt management plan ended in order to be considered?",
  "How long does the Debt relief plan ended in order to be considered?",
  "Do you accept application with ongoing Debt Relief Order",
  "Is it OK to accept customers with satisfied defaults?",
  "Is it acceptable to accept customers with dissatisfied defaults?",
  "Define new Build House",
  "Define New Build Flat",
  "Is remortgage of property for Dependant Relative permissible?",
  "Does the dependant cannot be included for affodability?",
  "Are deposits from LTD company acceptable?",
  "Reconized sources of deposits",
  "Sources of deposit that are unacceptable",
  "Is direct debit that must be from Applicants account neccesary to be accepted?",
  "Are income from director's dIvidends be classified when calculating affordability?",
  "Are Director's Loan included when determining affordability",
  "Can I inclued Disability Living allowance for evaluation of affordability?",
  "Can Discounted Market Sale properties be acceptable?",
  "Do you consider applications which has signs of a distressed sale?",
  "Can income form retirement dividends be included when evaluating affordability?",
  "Is Down sizing can be considered as repayment method for Interest Only?",
  "Can we redeem the mortgage without early repayment charge while on initial benefit period?",
  "Can I use payslip as proof of employment?",
  "Can you consider future payrise when evaluating affordability?",
  "What percentage of shareholding will I be considered as self employed?",
  "Are Employee benefit trust income can be classified when evaluation affordabilty?",
  "Do you obliged the Employed applicant to present empoyer reference?",
  "Are employment Income and Support allowance can be honored when evaluating affordability?",
  "Are there minimum time that an applicant should be under employement before considering ?",
  "Are applicant required to be employed in their company for a certain period before considering?",
  "Are you allowing Endowent method to be used as repayment  vehicle for Interest Only?",
  "Does Expats with negative credits be considered?",
  "Does the Expats who resides in a British colonies where the UK company located can still be accepted?",
  "Are the Expats applying for a Consumer Buy to Let Basis can still be considered?",
  "Are there any restrictions on the country of origin of expats?",
  "Do you consider applications from expats returning to the UK?",
  "Do you consider applications from expats employed by Multi National and returning to the UK?",
  "Do you consider expats who are first time buyers?",
  "What is the maximum terms given for the Expats?",
  "What is the minimum terms given for the Expats?",
  "What is the maximum period that an Expats allowed to be outside UK?",
  "What is the minimum and maximum required income for the Expats",
  "What is the minimum and maximum loan amount can an Expat avail?",
  "What is the minimum and maximum age limit for an Expat?",
  "What is the minimum and maximum value of property that an Expat can purchase?",
  "Are expats allowed to secure a  New Build flat?",
  "Are expats allowed to secure a New build House?",
  "Are expats with Po box address accepted?",
  "Are expats who wished avail remortgage for Debt cosolidation be considered?",
  "Are retired expats allowed to submit applications?",
  "Are retired expats who submit application can still be considered?",
  "Are expat allowed to secure an Ex Council House?",
  "Do you allowed existing borrowers to stay on the same criteria than getting a new policy?",
  "Do you allow taking out while having an existing second charge on the property?",
  "Do you accept expat applicants?",
  "Is expense be classified as income when calculating affordability?",
  "Does a Mortgage offer can be extended?",
  "Is there any product that my family allowed to assist in mortgage deposit?",
  "Are gifted deposit from non relative be accepted?",
  "Are deposits from a relaitive or a family can be permitted?",
  "What is FTB are they allowed to submit an application?",
  "Do you recognised Income from a Fixed term Contract?",
  "Can a flat located nearby and above a commercial property can be secured?",
  "On what percentage does a property with an element of Flying Freehold be accepted?",
  "Is renting accesible for a Forces Help to Buy Scheme",
  "Are income of a Military Personnel accepted?",
  "Are Foreign Currency Income be considered in evaluating affordability",
  "Are there any restriction in accepting HMO?",
  "Do you consider income from Foster Caring when evaluating Affordability?",
  "What are the conditions for availing free legal consultation",
  "Do you accept Freehold Flat as a security?",
  "Do you accept applications without Full Vacant Possession?",
  "Can an existing borrower allowed to borrow again for a Further advance",
  "Do you allowed applicants with history of gambling?",
  "Any considerations and restrictions for properties that have doubling ground rent?",
  "Are properties with raising ground rent be considered?what are the restrictions?",
  "Are marked up ground rent that based on property value allowed? under what conditions?",
  "Are properties associated ground rent to RPI allowed? Are there any restrictions?",
  "Are you considering Guaranteed Income Payment for Forces when you assess affordability?",
  "Are Backers allowed? What are the condition?",
  "Does the Guardian Income can be presented in evaluating affordability?",
  "What are the restriction in applying Help to Buy Equity Loan scheme, is it even allowed?",
  "Do you allow repayments to Help to Buy Equity Share Loan Scheme in Full or Partially?",
  "Conditions for applying Higher Lending Charge Payable",
  "Restrictions for Holiday Home applications",
  "Is Housing Benefit allowed to be presented in assesing affordability",
  "Can an income from a lodger be presented when evaluating affordability?",
  "Can Trust fund income allowed to include in assesing affordability?",
  "What income multipliers are available when you assess affordability and maximum loan available?",
  "Is income support allowed when assessing affordability",
  "Do you accept application when there is an ongoing Individual Voluntary Arrangement (IVA)",
  "Time required for Individual Voluntary Arrangement to be discharged",
  "Are you considering Industrial Injuries Disability Benefit when assessing affordability?",
  "Do you accept inheritance as repayment when taking Interest Only as repayment method.",
  "Can Interest Only be recognised as repayment method?",
  "Are Income from an Investment can be included in evaluating affordability",
  "Is ISA can be recognised as repayment",
  "Do you accept IT Contractors as part of employment and how is it calculated?",
  "Is Japanese Knotweed an acceptable security and any restrictions?",
  "Do you consider Job seekers allowance an acceptable source of income when assessing affordability?",
  "Do you accept applications under Key Workers Scheme?",
  "Do you accept applications from an applicant who owned the property next door?",
  "Are loans that exceeds on £500,000",
  "Is renting properties in England allowed?",
  "Is renting properties in Northern Ireland allowed?",
  "Is renting properties in Scotand allowed?",
  "Is renting properties in Wales allowed?",
  "Is renting properties in Scottish Isles allowed?",
  "What conditions does the applicant allowed mortgage until their retirement?",
  "Do you allow Limited Company to borrow?",
  "Do you allow Trustees to borrow?",
  "Do you consider allowing applications from a Limited Liability Partnership?",
  "Is Live Work Usage restrictions accepted as security?",
  "Are LTI limits applied when evaluating affordability?",
  "Is buying via Local Authority Grant Scheme Accepted?",
  "Do you allowed renting a Room or Take a Lodger property?",
  "Is application from a married applicant that decides to use their maiden name allowed?",
  "What age allowed to apply?",
  "Ceiling Loan amount",
  "Maximum cap LTV for a pound for pound remortgage",
  "Is there a maximum LTV for a new Flat property?",
  "What is the maximum LTV in Right to Buy Purchase?",
  "What is the maximum LTV in Shared Equity Purchase?",
  "What is the maximum LTV in Shared Ownership Purchase?",
  "Is purchasing secondhand flats acceptable are there any terms that must be considered?",
  "Are Second-Hand Homes Acceptable as Security, and Are There Any Other Restrictions?",
  "Are remortgages for properties bought through the Right to Buy programme acceptable if they are still in the Pre-Emption period?",
  "Are Shared Equity Remortgages acceptable, and what is the maximum LTV allowed?",
  "Does Shared Ownership allow remortgaging and what is the maximum LTV?'",
  "Are Studio Flats an acceptable options for security, and are there any rules?",
  "Are expats accepted under the same terms and LTV as other applicants?",
  "Is it acceptable to take loans against an unencumbered property?",
  "Is it acceptable to use a Loan to get funds out of a property that you already own?",
  "Is it acceptable to raise fund for debt consolidation, and are there any restrictions?",
  "Is raising cash for business purposes okay, and are there any rules?",
  "Is it acceptable to raise money for home improvements, and are there any restrictions?",
  "Is it acceptable to raise money to buy equity shares, and are there any restrictions?",
  "Is it acceptable to raise fund to buy a buy-to-let (BTL) property?",
  "Is it acceptable for First-Time Buyers (FTB) to borrow up to the standard maximum LTV?",
  "What is the highest LTV for a mortgage with interest-only payments and a repayment plan?",
  "What is the most LTV that can be used for a big loan?",
  "What is the maximum LTV for a mortgage with a part-and-part or split payment option?",
  "Are more than two applicants okay, and can all their incomes be used to assess affordability?",
  "Does the property value will depends on the type of the property?What will be it's maximum and minimum value?",
  "Is there a maximum and minimum property value?Will it depends on the type of property?",
  "What is the limit period for a capital and interest repayment scheme',",
  "Is there a basic income for single / joint applications on a Capital and Interest repayment?",
  "Is there a basic wage for single / joint applications on a Capital and Interest repayment?",
  "Is there a minimum credit amount? Does it change based on the creditor or the type of product?",
  "Is there a minimum floor space requirement for a Studio Flat?'",
  "What is the acceptable minimum mortgage term",
  "Can applicants with past due credit commitments be accepted?",
  "Can mixed use or commercial properties be considered in security?",
  "Electronic signatures for mortgage declarations and direct debits be accepted?",
  "Is a mortgage offer extension acceptable for new builds and for how long?",
  "How long does the mortgage offer valid?",
  "Can applicants with mortgage/secured loan arrears be considered?'",
  "Can a Mortgage Subsidy be considered income when evaluating affordability?'",
  "can a mortgage term of 'months / years' be deemed",
  "What grade does the construction by Mundic Block permitted?",
  "A company's net profit can be used as income for a director, is it acceptable?",
  "Are there any restrictions on the LTV of a New Build Flat?",
  "Are there any restrictions on the LTV of a New Build Home?",
  "Can a New Build Property be acquired off-plan?",
  "Are New Build Property Incentives acceptable?What are the conditions?",
  "New Build Property Warranties and Providers",
  "Are Applications from non-EU, EEA, and non-UK nationals will be accepted, but only under certain conditions.",
  "Is it acceptable to conduct a staggered and non-Simultaneous sale and completion",
  "Can properties regarded to be on Non-Standard construction be considered as suitable security?",
  "Do you accept Non UK Tax Payers acceptable",
  "Are high-rise apartment buildings permissible, and what is the maximum number of floors that can be allowed?",
  "Can nursing bank staff income be considered when determining affordability?'",
  "Are there any products that have an Offset Mortgage option?",
  "If there is an Overage Clause or Agreement in effect on the security address, can a mortgage be considered?'",
  "Is there an overpayment facility for products, and if so, what are the terms?',",
  "Can non-regular or fluctuating overtime be taken into consideration when determining affordability',",
  "Can regular or guaranteed overtime be viewed when evaluating affordability?",
  "Is income received in cash allowed, and if so, what are the necessary restrictions?",
  "Is there a percentage that is allowed for each repayment option on Part and Part / Split', and if so, what is it?",
  "Will applicants with prior Payday Loans be considered?",
  "Are payment holidays allowed?",
  "What documents are needed for Payslip to be considered as income?",
  "Can a pension be used in repaying for an Interest Only?",
  "Do pension contribution cannot be classified in determining affordability?",
  "Can I use Private Pension Credits to evaluate affordability?",
  "Grade properties acceptable?",
  "Can health insurance be included in evaluation affordability?",
  "Can Personal Financing to a Business be Ignored When Assessing Affordability?",
  "Can Personal Independent Payment Benefit be included when in evaluating affordability?",
  "Is Piece Work as employment can be classified as source of income?",
  "Is property with restrictive or unusual planning permissions",
  "Are products allowed to be relocate to another property?",
  "Do you accept loan in all Postcode Areas under what condition?",
  "Do you accept Pre-Reinforced Concrete properties for security and are certificate required?",
  "Can Price Work be classified source of income and employment?",
  "Am I allowed to purchase a property thru Private Sale without an estate agent?",
  "Do you accept an applicant who are under probation?",
  "Is it allowed to have a product split on the same mortgage?",
  "Are existing borrowers can still be approved when they submit a Product Transfer application thru intermediary?",
  "Is a Professional Landlord allowed to submit an application?  Are there requirements?",
  "What criteria must professional athletes meet?",
  "Do you allow submitting application without proof of personal deposit?",
  "Do you allow submitting application without proof of personal identification",
  "Do you allow submitting application without proof of residency",
  "Do you accept application for buying propertries in auction?",
  "Do you accept application for buying propertries via investment",
  "Do you consider property with Annex in security",
  "Are properties that have outbuildings considered security?",
  "Is it allowed to use  properties that have a substantial amount of land as security, and what would be the maximum acreage?",
  "Is buying a property that will be occupied by other family member be allowed?",
  "Is it acceptable to make a purchase from a Limited Company?",
  "Is it necessary to pay a re-inspection charge for a newly constructed home?",
  "Can income from the Reduced Earnings Allowance be used when assessing affordability?",
  "Can I still use the reduced income for a specific period be considered when assessing affordability?",
  "Are applications for Buy to Let that are regulated acceptable?",
  "Is remortgage to transfer equity or Divorce settlement allowed?",
  "Is it acceptable to do capital raising as part of the remortgaging process?",
  "Is it allowed to use remortgage as repayment to a Bridging Loan?",
  "Are the applicants who have rent arrears can still be considered?",
  "Do you accept applications in purchasing an items through the Rent to Buy Schemes?",
  "Are Income from a rental be classified when assessing affordability?",
  "Are the applicants that has repossessed or voluntarily surrendered their properties can still be considered?",
  "Are the applicants with less than 3 years residing in the UK can be considered?",
  "Do you accept an application when another residential mortgage are still remains under completion?",
  "are self employed applicants allowed to use the retained profit in a business when assessing affordability?",
  "What are the repayment vehicles or strategies that can be acceptable for a Retirement Interest Only Lending?",
  "Are future income be considered when assessing affordability for retirement interest only?",
  "Do you considered enhancing terms for Retirement Interest Only mortgages when the Last Power of Attorney is presented?",
  "Do you accept income from benefits to be classified when assessing affordability of a Retirement Interest Only Joint applicantion?",
  "Are considering Family assistance Income when assessing affordability of a Retirement Interest Only Joint application?",
  "Are considering Investment Income when assessing affordability of a Retirement Interest Only Joint application?",
  "Do considering a Life cover projected proceeds when assessing affordability for a Retirement Interest Only Joint application?",
  "Are Income form a pension be considered when assessing affordability for a Retirement Interest Only Joint application?",
  "Do you considered a Pension Income when assessing affordability for a Retirement Interest Only Joint application?",
  "What is the maximum age limit for an applicant for Retirement Interest Only?",
  "What is the maximum age limit should a mortgage term end for Retirement Interest Only?",
  "What is the maximum amount that can be Loan for a Retirement Interest Only?",
  "What is the available maximum Limit for LTV for a Retirement Interest Only?",
  "Do you considered remortgage for Retirement Interest Only which is a like for like?",
  "Can a remortgage with capital raising for Retirement Interest Only lending be considered, and what is the maximum LTV that can be offered?",
  "What is the maximum number of terms for a Retirement Interest Only Mortgage?",
  "What is the Lowest age requirement for submitting an application for a retirement interest only loan?",
  "What is the minimum amount that can be loan in Retirement Interest Only?",
  "What is the minimum amount of equity that must be present for Retirement Interest Only?",
  "Minimum acceptable income for Retirement Interest only Lending?",
  "What is Minimum value of property to be considered in Retirement Interest Only",
  "What is the acceptable minimum term for Retirement Interest Only?",
  "Are Retirement Interest Only Lending be considered on any New business products?",
  "Do you guarantee that No negative Equity Guarantee will be offered for RIO lending?",
  "Are buying via RTB scheme allowed?",
  "Are Salary Sacrifice deductions will not be included when the affordability assessed?",
  "Are they allowing foreign properties sales or Asset classed as a Repayment Vehicle for Interest Only?",
  "Are they allowing Residential properties sales to be classed as a Repayment Vehicle for Interest Only?",
  "Do you consider Other Assets sales to be classified as a Repayment Vehicle for Interest Only?",
  "Do you consider Mortgage Property sales to be classified as a Repayment Vehicle for Interest Only?",
  "Do you consider Buy to Let Property / Investment sales to be classified as a Repayment Vehicle for Interest Only?",
  "Do you consider savings as a Repayment Vehicle for Interest Only?",
  "Do you consider Converting to a Capital and Interest to be classified as a Repayment Vehicle for Interest Only?",
  "Do you consider Seasonal employment income in affordability assessment?",
  "Do you still accept an application when the second applicant has no income?",
  "Are there any restriction when applying for a Second Home or Pied a Terre?",
  "Are income from a second Job can be classed when assessing affordability",
  "Do you allow application for a self build property?",
  "Do you accept applicant which an account is only a Year?",
  "Do you accept applicant with a declining Profits?",
  "Are service charges or Ground rent and Maintenance cost will be disregard in affordability assessment?",
  "Will you considered an application for a Shared ownership scheme?",
  "Will you consider allowing remortgage to a staircase or purchase a bigger share of the property?",
  "Are Sharia or Islamic product available?",
  "Will a Shift allowance income classed in affordability assesment?",
  "Will a short term lending allowed?",
  "Will an applicant for a short term renewable contract accepted?",
  "Will you accept Self Employed Sole Trader applicants?",
  "What would be the requirements if an application came from a former Sole trader turned to Limited Company?",
  "Are Special DUties Income can be classed in affordability assessment?",
  "Are there any specific product offered for a Professional applicant?",
  "Do you allow spliting title deeds upon completion?",
  "Does Spousal Maintenance will be classed as acceptable income in affordability assessment?",
  "Does State Pension benefit will be classed as acceptable Income in affordability assessment?",
  "Does a Steel Framed Construction accepted?",
  "Do you allow Stocks and shares to be presented as repayment vehicle for interest only?",
  "Will you ignored student Loan in affordability assessment?",
  "Do you allowed application that involves Sub-sales or Reassignment contracts?",
  "Does subcontractors classified as an acceptable type of employment, what would be the requirements?",
  "Are there specific criteria for assessing teacher's application?",
  "Are Temporary employment acceptable when assessing affordability?",
  "Do you accept Timber framed properties can be acceptable? what are the requirements",
  "Are applicants under a certain visa can be considered?What are their requirement?",
  "Do you consider the applicantion of Unlimited Aggregate Borrowing with the Lender or Group?",
  "Can applicants who are self-employed that had less than two years of trading experience be considered?",
  "Do you accept Travel allowance income in affordability assessment?",
  "Will you disregard Travel Expenses in affordability assessment?",
  "Can a mortgage be classified as joint with only 1 name on the Deeds?",
  "Do you accept properties that have Cross Leases or Tyneside Flats?",
  "Do you accept applicants who only resides in UK for less than 3 years?",
  "Are the income of a self employed applicant under Umbrella Company enough to be considered?",
  "Do you offer Free legal advice for an unencumber properties and will it treated as Purchase or Remortgage?",
  "Do you recognised unit trust or OEIC as a suitable repayment vehicle or method for Interest Only?",
  "Do you recognised Universal Credit as income when assessing affordability",
  "Are candidates who have a zero-hour contract eligible, and is the income they get acceptable?",
  "Do you accept application without AST?",
  "Do you offer Mortgage without the Coal Mining Report presented?",
  "Are Consumer Buy to Let application can be accepted?",
  'What exactly is the meaning of the term "Portfolio Landlord," and can they be considered?',
  "Will an Energy Rating below E property be considered?",
  "Are Expat First Time Landlord can be accepted?",
  "Do you accept application from an Expat for House of multiple occupation",
  "Can Expat with Professional Portfolio Landlords be accepted",
  "WIll you allowed Experienced Landlords?",
  "How does the application from a First Time Buyer and a Home mover be review?",
  "Are First Time Landlord applicants allowed?",
  "Are properties with Flat Roof allowed?",
  "Are Converted Flats considered as acceptable security?",
  "Are Flats with Deck be allowed?",
  "WIll a Freehold properties with Multiple Units be considered as security?",
  "Will a Holiday Let application be acceptable?",
  "Those Houses of multiple Occupation be considered?",
  "Is Lending just Against Land be allowed?",
  "Are you allowed to process applications when No Letting Agent is involved?",
  "WIll a property undergone a light renovation still be considered?",
  "Rental. Calculation for LTD companies",
  "WIll a LTD company which is not registered as a special purpose vehicle be regarded?",
  "Is capital raising for a land be considered when applying for remortgage?",
  "What is the Maximum Limit in terms of number of bedrooms in the property? What would be the restriction?",
  "Do you allowed properties that has more than one Kitchen?",
  "Maximum no. of Tenants that can be allowed in one Tenancy",
  "Does the property inside a Freehold Block be considered as acceptable security",
  "Do you accept Multiple Tenancy agreements or Room by Room Let?",
  "Are Bedsits properties be regarded as security?",
  "Are Properties which have Overhead power cables be considered?",
  "How much is the Highest number of properties to be considered for a Portfolio LandLord",
  "What are the rental assessment that will be go through for the properties background",
  "Are Portfolio Landlords allowed to use their own income in filling up rental income insuficiency",
  "Can you accept properties affected by Subsidence?",
  "Can you consider properties near Landfill Sites?",
  "Are you accepting properties subject to Government Green Deal Scheme?",
  "Do I need to make the property habitable on completion of the mortgage?",
  "Are applications for Regulated Buy to Let acceptable?",
  "Do you allow purchases the rents back the property to the vendor?",
  "Is applicant's income allowed to make up rental shortfall?",
  "Do you accept applications where the tenant will continue to reside in the property on completion?",
  "Are you accepting let to Students properties?",
  "Do you consider proerties let to DSS / Applicants on Benefits?",
  "Do you have a requirement for single / joint income?",
  "Define Rental Income Calculations",
  "What are the Rental Income Calculations used by Higher Rate Tax payer applicants?",
  "How many years remaining are required for a Leasehold Property?",
  "Minimum amount of Percentage Share that can be bought?",
  "Is Shared Ownership allowed when not with a Housing Association?",
  "Are you considering Shared Ownership when 100% Staircasing is not available?",
  "Are you accepting Underpayments / Payment Holidays",
  "Do you consider a Self Build Application without needing Detailed Project Costings?",
  "Do you consider the release of funds before receiving a Building Warrant?",
  "Do you accept applications without restrictions on Building / Property use or class?",
  "Are you accepting Custom Build applications?",
  "Do you consider mortgage extension for Custom Build application?",
  "Do you consider applicant's request for the release of Stage Payments?",
  "Does Custom Build Application accept guarantors?",
  "Do you allow Interest Only as Repayment Method during build?",
  "Do you allow lending against Purchase of Land?",
  "How much is the maximum loan for a Custom Build application?",
  "How much is the maximum Loan to Cost you are considering?",
  "What warranties are needed to be in place on completion of Custom Build to be acceptable?",
  "What stages are the funds released on a Custom Build?",
  "Are you accepting one set of Solicitors acting for the applicant and for the lender?",
  "What are the minimum requirements of Site Insurance?",
  "Do you require valuation or reinspection at all stages of the build?",
  "Do you accept Landlocked properties with Easement / Rights of Way?",
  "What are the minimum number of Stage Payments?",
  "Do you consider applications for Self Build without Outlined Planning Permission?",
  "Do you allow Stage Payments be paid in advance?",
  "Do you allow Stage Payments be paid in arrears?",
  "Are you considering requests specifying next stage of tranche of funding is released?",
  "Are Arrangement Fees and Broker Fees added on top of Product Maximum LTV?",
  "Do you consider maximum Devt to Income Ratio when assessing affordability?",
  "Are you accepting applications where the current lender will not grant a second charge?",
  "Are Restrictive Covenant properties acceptable securities?",
  "Do you accept properties with Solar Panels?",
  "Can I apply for Cash Reserve?",
  "Are you offering Inheritance Protection Guarantee?",
  "Are Home Reversion Plans being offered?",
  "Do you offer Early Vacancy Guarantee with Home Reversion Plans?",
  "Can you consider applications where Rent Payment can escalate on Home Reversion Plans?",
  "Are you accepting applications where Rent Payments are fixed on Home Reversion Plan?",
  "Is there a maximum Percentage Release on Home Reversion Plans?",
  "Are applications without Ongoing Vendor Obligations for Home Reversion Plan allowed?",
  "Are you considering applications where rent payments may cease on Home Reversion Plan?",
  "Are you considering applications where rent payments can be reduced on Home Reversion Plan?",
  "What is acceptable on Rent Payable during the plan lifetime for Home Reversion Plans?",
  "Are Home Reversion Plans offering the option to re-purchase / buy back the property?",
  "Are you offering Lifetime Mortgages?",
  "Are applicants with medical condition allowed for higher borrowing?",
  "Do you offer No Negative Equity Guarantee?",
  "Are you accepting Optional Partial Repayments?",
  "How often do you allow withdrawals from cash reserve facility?",
  "Do you accept Equity Release on a Buy to Let property?",
  "Are you accepting Commonhold Tenure properties?",
  "Are you considering lending on a Commercial Property?",
  "Do you consider applications for Development Bridging against a Commercial Property?",
  "Are you considering applications for Conversion Development",
  "Can you tell me the acceptable Percentage Cost to Profit Margin?",
  "Are First Time Developers considered?",
  "Do you consider applications for a Ground up Development?",
  "Bridging Development Maximum Loan To Cost",
  "Maximum Loan to Cost for Bridging Development",
  "Maximum GDV for the End Value of Bridging Development",
  "Maximum LTV when acquiring Bridging Development",
  "Terms for Bridging Development",
  "Part Completed Developments allowed?",
  "Second Charge allowed or not?",
  "Do you consider a property with Outline Planning Permission?",
  "Is Exit Fee calculated using End Gross Development Value (GDV) or loan?",
  "Are there available Bridging Development Exit Products?",
  "Are you securing applications against Land for Development Finance?",
  "Are you considering Regulated applications?",
  "Do you consider work for a property that needs Heavy Refurbishment?",
  "Do you retain the interest from Gross Loan amount?",
];

export default criterias;
